import { useState, useCallback, useRef } from 'react';

export const useMicrophoneHandling = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const audioChunksRef = useRef([]);
  const audioPromiseRef = useRef(null);

  const startRecording = useCallback(() => {
    console.log("Starting recording...");
    audioChunksRef.current = []; // Clear previous audio chunks
  
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("getUserMedia not supported on your browser!");
      return;
    }
  
    if (typeof MediaRecorder === 'undefined') {
      console.error("MediaRecorder not supported on your browser!");
      return;
    }
  
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(s => {
        setStream(s);
        const mediaRecorder = new MediaRecorder(s);
        mediaRecorder.start();
        console.log("Recording started");
  
        mediaRecorder.addEventListener("dataavailable", event => {
          audioChunksRef.current.push(event.data);
        });
  
        audioPromiseRef.current = new Promise(resolve => {
          mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
            console.log("Recording stopped, audio blob created");
            resolve(audioBlob);
          });
        });

        setIsRecording(true);
      })
      .catch(error => {
        console.error('Error accessing the microphone:', error);
      });
  }, []);

  const stopRecording = useCallback(() => {
    console.log("Stopping recording...");
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
    setIsRecording(false);
  }, [stream]);

  const toggleRecording = useCallback(() => {
    if (!isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [isRecording, startRecording, stopRecording]);

  const sendAudioToServer = useCallback(() => {
    if (!audioPromiseRef.current) {
      console.error("No audio promise available");
      return Promise.reject("No audio recorded");
    }
    
    return audioPromiseRef.current.then(audioBlob => {
      console.log("Sending audio to server...");
      const formData = new FormData();
      formData.append("file", audioBlob, "audio.wav");
    
      return fetch('https://www.chatassistant.online/api/transcribe', {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          console.log("Received transcription:", data.transcription);
          return data.transcription;
        });
    }).catch(error => {
      console.error('Error:', error);
      throw error;
    });
  }, []);

  return {
    isRecording,
    toggleRecording,
    sendAudioToServer
  };
};