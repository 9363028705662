import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import ChatbotDrawer from 'components/Drawer/ChatbotDrawer';
import Card from 'components/Card';
import { Tab } from '@headlessui/react';
import { UserCircleIcon, EnvelopeIcon, PhoneIcon, BriefcaseIcon, MapPinIcon, AcademicCapIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const infoTypes = [
  { value: 'all', label: 'All Information', color: 'bg-indigo-100 text-indigo-800' },
  { value: 'email', label: 'Email', icon: EnvelopeIcon, color: 'bg-blue-100 text-blue-800' },
  { value: 'phone', label: 'Phone', icon: PhoneIcon, color: 'bg-green-100 text-green-800' },
  { value: 'job_title', label: 'Job Title', icon: BriefcaseIcon, color: 'bg-yellow-100 text-yellow-800' },
  { value: 'country', label: 'Country', icon: MapPinIcon, color: 'bg-red-100 text-red-800' },
  { value: 'education', label: 'Education', icon: AcademicCapIcon, color: 'bg-purple-100 text-purple-800' },
];

function Visitors({ chatId }) {
  const [usersInfo, setUsersInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [infoType, setInfoType] = useState(infoTypes[0]);

  useEffect(() => {
    const fetchUsersInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/users-info?assistant_id=${chatId}`);
        if (response.data.message === "No user information found") {
          setUsersInfo({});
        } else {
          setUsersInfo(response.data);
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch user information');
        setLoading(false);
      }
    };

    fetchUsersInfo();
  }, [chatId]);

  const handleViewThread = (threadId, messageId) => {
    setSelectedThread(threadId);
    setSelectedMessage(messageId);
    setDrawerOpen(true);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 font-semibold">{error}</div>
    );
  }

  const filteredUsersInfo = Object.entries(usersInfo).reduce((acc, [fingerprintId, userInfo]) => {
    if (infoType.value === 'all' || userInfo[infoType.value]) {
      acc[fingerprintId] = userInfo;
    }
    return acc;
  }, {});

  const renderUserCard = (fingerprintId, userInfo) => {
    const primaryName = userInfo.name?.[0]?.value || 'Unknown User';
    return (
      <div key={fingerprintId} className="bg-white shadow-sm rounded-lg overflow-hidden border border-gray-200 hover:shadow-md transition-shadow duration-300">
        <div className="p-6">
          <div className="flex items-center mb-4">
            <div className="bg-indigo-100 rounded-full p-3">
              <UserCircleIcon className="h-8 w-8 text-indigo-600" />
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-semibold text-gray-900">{primaryName}</h2>
              <p className="text-sm text-gray-500">ID: {fingerprintId.slice(0, 8)}</p>
            </div>
          </div>
          <div className="space-y-3">
            {Object.entries(userInfo).map(([key, values]) => {
              if (infoType.value !== 'all' && key !== infoType.value) return null;
              const Icon = infoTypes.find(t => t.value === key)?.icon || UserCircleIcon;
              return (
                <div key={key} className="flex items-start">
                  <Icon className="h-5 w-5 text-gray-400 mt-0.5 mr-2" />
                  <div>
                    <p className="text-sm font-medium text-gray-700 capitalize">{key.replace('_', ' ')}</p>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {values.slice(0, 3).map((value, index) => (
                        <button
                          key={index}
                          className="bg-gray-100 hover:bg-gray-200 text-gray-800 text-xs font-medium rounded-full px-2 py-1 transition-colors duration-200"
                          onClick={() => handleViewThread(value.thread_id, value.message_id)}
                        >
                          {value.value}
                        </button>
                      ))}
                      {values.length > 3 && (
                        <span className="text-xs text-gray-500 self-center">+{values.length - 3} more</span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card>
      <div className="space-y-8">
        <h3 className='text-3xl font-bold text-gray-900 text-center mb-8'>
          Visitor Information
        </h3>

        <div className="flex flex-col items-center space-y-6 mb-8">
          <Tab.Group onChange={(index) => setInfoType(infoTypes[index])}>
            <Tab.List className="flex flex-wrap justify-center max-w-4xl mx-auto">
              {infoTypes.map((type) => (
                <Tab
                  key={type.value}
                  className={({ selected }) =>
                    classNames(
                      'flex items-center px-3 py-2 m-1 text-sm font-medium rounded-full',
                      'focus:outline-none transition-all duration-200 ease-in-out',
                      selected
                        ? `${type.color} shadow-sm`
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    )
                  }
                >
                  {({ selected }) => (
                    <motion.div
                      className="flex items-center space-x-2"
                      initial={false}
                      animate={{ 
                        scale: selected ? 1.05 : 1,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      {type.icon && <type.icon className="h-5 w-5" />}
                      <span>{type.label}</span>
                    </motion.div>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>

        <AnimatePresence>
          <motion.div
            key={infoType.value}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            {Object.entries(filteredUsersInfo).map(([fingerprintId, userInfo]) => 
              renderUserCard(fingerprintId, userInfo)
            )}
          </motion.div>
        </AnimatePresence>

        <ChatbotDrawer 
          open={drawerOpen} 
          setOpen={setDrawerOpen} 
          chatbotDrawerId={chatId} 
          threadId={selectedThread} 
          messageId={selectedMessage}
        />
      </div>
    </Card>
  );
}

export default Visitors;