import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import { XMarkIcon, ArrowPathIcon, EllipsisVerticalIcon, CheckIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddDataSources = ({ open, setOpen, existingSources, chatId, reloadDataSources }) => {
  const navigate = useNavigate();
  const [selectedDataSources, setSelectedDataSources] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [filteredDataSources, setFilteredDataSources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchChatData();
  }, []);

  useEffect(() => {
    const existingSourceIds = existingSources.map((source) => source.id);
    setSelectedDataSources(existingSourceIds);
  }, [existingSources]);

  useEffect(() => {
    setFilteredDataSources(dataSources);
  }, [dataSources]);

  const fetchChatData = async () => {
    try {
      const response = await fetch('https://www.chatassistant.online/api/data_sources');
      const data = await response.json();
      setDataSources(data['docs']);
    } catch (error) {
      console.log('Error fetching chat data:', error);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    if (term) {
      const filtered = dataSources.filter(source => 
        source.name.toLowerCase().includes(term)
      );
      setFilteredDataSources(filtered);
    } else {
      setFilteredDataSources(dataSources);
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredDataSources(dataSources);
  };

  const handleDataSourceSelection = useCallback((sourceId) => {
    setSelectedDataSources(prev => 
      prev.includes(sourceId) 
        ? prev.filter(id => id !== sourceId)
        : [...prev, sourceId]
    );
  }, []);

  const handleAddToChat = async () => {
    setIsSaving(true);
    try {
      const formData = new FormData();
      formData.append('center_id', chatId);
      formData.append('associated_ids', selectedDataSources.join(','));
      formData.append('center_type', 'chat');

      const response = await fetch('https://www.chatassistant.online/api/edit_associations', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setOpen(false);
        reloadDataSources();
        toast.success('Data sources added successfully', {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        throw new Error('Error: ' + response.status);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to add data sources', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const formatNumber = (number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    } else if (number >= 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    } else {
      return number.toString();
    }
  };

  const renderDataSourceRow = useCallback((source) => {
    const isSelected = selectedDataSources.includes(source.id);
    return (
      <tr 
        key={source.id} 
        className={`border-b border-gray-100 ${isSelected ? 'bg-indigo-50' : ''} cursor-pointer`}
        onClick={() => handleDataSourceSelection(source.id)}
      >
        <td className='w-0 px-1 py-5'>
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            checked={isSelected}
            onChange={() => handleDataSourceSelection(source.id)}
            onClick={(e) => e.stopPropagation()}
          />
        </td>
        <td className='px-1 py-5'>
          <div className='flex items-center'>
            <img 
              className="w-11 h-11 flex-none rounded-full bg-gray-50"
              src="/images/file-icon.png"
              alt={source.name}
            />
            <div className="min-w-0 ml-4">
              <span className="font-semibold text-base leading-5 text-gray-900">
                {source.name}
              </span>
              <div className="mt-1 flex items-center gap-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  Added on {source.date || 'N/A'}
                </p>
              </div>
            </div>
          </div>
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          <span className="border-green-200 bg-green-50 text-green-700 border inline-flex items-center rounded-md px-2 py-1 text-xs leading-4 font-medium">
            {source.data_type}
          </span>
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          {source.file_size && source.file_size > 0 ? (
            source.file_size < 1024 ? (
              `${source.file_size} bytes`
            ) : source.file_size < 1024 * 1024 ? (
              `${(source.file_size / 1024).toFixed(2)} KB`
            ) : (
              `${(source.file_size / (1024 * 1024)).toFixed(2)} MB`
            )
          ) : (
            "-"
          )}
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          {formatNumber(source.n_characters)}
        </td>
        <td className='text-sm text-gray-500 px-1 py-5'>
          <div className='flex justify-end relative'>
            <Menu as='div' className='relative'>
              <Menu.Button className='block text-gray-500'>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-95"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute mt-2 right-0 z-10 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg border border-gray-300">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => navigate(`/data-source/${source.id}`)}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block px-4 py-2 text-sm text-gray-700 cursor-pointer`}
                      >
                        View Details
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </td>
      </tr>
    );
  }, [selectedDataSources, handleDataSourceSelection, navigate]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-4xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6 py-6 bg-gray-50 border-b border-gray-200">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Add Data Sources
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 overflow-y-auto px-4 sm:px-6 py-6">
                    <div className="mb-6">
                      <h2 className="text-lg font-medium text-gray-900">Available Data Sources</h2>
                      <p className="mt-1 text-sm text-gray-500">Select the data sources you want to add to this chat.</p>
                    </div>
                    <div className='flex flex-col lg:flex-row justify-between items-center mb-6'>
                      <div className='flex items-center w-full lg:w-auto mb-4 lg:mb-0'>
                        <input
                          type="text"
                          placeholder='Search data sources...'
                          className="lg:mb-0 w-full lg:w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-3"
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                        {searchTerm && (
                          <button
                            onClick={clearSearch}
                            className="text-gray-500 hover:text-gray-700 mr-3"
                          >
                            <XMarkIcon className="h-5 w-5" />
                          </button>
                        )}
                      </div>
                      <button
                        onClick={handleAddToChat}
                        disabled={isSaving || !!searchTerm}
                        className={`inline-flex justify-center items-center rounded-md px-3 py-2 text-sm leading-5 font-semibold text-white transition-colors duration-200 ${
                          isSaving || searchTerm
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        }`}
                      >
                        {isSaving ? (
                          <ArrowPathIcon className="animate-spin h-5 w-5 mr-2" />
                        ) : (
                          <CheckIcon className="h-5 w-5 mr-2" />
                        )}
                        {isSaving ? 'Adding...' : 'Add Selected Sources'}
                      </button>
                    </div>
                    {searchTerm && (
                      <div className="mb-4 text-sm text-yellow-600 bg-yellow-100 p-3 rounded-md">
                        Please clear the search to apply changes. This ensures all selections are accounted for.
                      </div>
                    )}
                    <div className="overflow-x-auto">
                      <div className="align-middle inline-block min-w-full">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th scope="col" className="w-0 px-1 py-3"></th>
                                <th scope="col" className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data Source</th>
                                <th scope="col" className="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Data Type</th>
                                <th scope="col" className="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">File Size</th>
                                <th scope="col" className="px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Characters</th>
                                <th scope="col" className="px-1 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {filteredDataSources.map(renderDataSourceRow)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddDataSources;