import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, RadioGroup, Transition } from '@headlessui/react';
import Card from 'components/Card';
import DataTable from 'components/DataTable';
import ChatbotDrawer from 'components/Drawer/ChatbotDrawer';
import CreateChatDrawer from 'components/Drawer/CreateChat';
import DeleteModal from 'components/DeleteModal';
import ChatbotCard from 'components/ChatbotCard';
import ListIcon from 'assets/icons/menu.png';
import LayoutIcon from 'assets/icons/dashboard.png';

const modes = [
  { value: 'card', label: "Card", priceSuffix: '/card', icon: LayoutIcon },
  { value: 'list', label: "List", priceSuffix: '/list', icon: ListIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Chatbots = () => {
  const navigate = useNavigate();
  const [mode, setMode] = useState(modes[0]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCreateChatbot, setOpenCreateChatbot] = useState(false);
  const [chatbotDrawerId, setChatbotDrawerId] = useState(null);
  const [docIds, setDocIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredRows, setFilteredRows] = useState([]);
  const rowsPerPage = 10;
  const cols = [
    { label: 'Chatbot Name' },
    { label: 'Statistics' },
    { label: 'Data Sets' },
    { label: 'Status' },
    { label: 'Actions', align: 'right' }
  ];

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchChatData();
  }, []);

  useEffect(() => {
    const results = rows.filter(row =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRows(results);
    setCurrentPage(1);
  }, [searchTerm, rows]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatNumber = (number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    } else if (number >= 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    } else {
      return number.toString();
    }
  };

  const fetchChatData = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://www.chatassistant.online/api/chats");
      const data = await response.json();
      setRows(data);
    } catch (error) {
      console.log("Error fetching chat data:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderRow = (row) => {
    return <tr className='border-b border-gray-100'>
      <td className='w-0 px-1 py-5'>
        <input type="checkbox" className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
      </td>
      <td className='px-1 py-5'>
        <div className='flex items-center'>
          <img className="w-11 h-11 flex-none rounded-full bg-gray-50"
            src={row.img !== '' ? row.img : "/images/chat-icon.jpg"}

            alt={row.name} />
          <div className="min-w-0 ml-4">
            <span
              onClick={() => navigate(`/chatbot/${row.id}/settings`)}
              className="font-semibold text-base leading-5 text-gray-900 cursor-pointer"
            >
              {row.name}
            </span>
            <div className="mt-1 flex items-center gap-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                Created on {row.date}
              </p>
            </div>
          </div>
        </div>
      </td>

      <td className='text-xs text-gray-500 px-1 py-5'>
        {row.n_chats_started} / Chats started<br />
        {row.n_messages_asked} / Questions asked
      </td>
      <td className='text-xs text-gray-500 px-1 py-5'>
        {row.number_of_streams} / Data Sets<br />{formatNumber(row.total_chat_characters)} / Characters
      </td>

      <td className='text-sm text-gray-500 px-1 py-5'>
        <span className={`${row.status === 1 ? `border-green-200 bg-green-50 text-green-700` : `border-amber-200 bg-amber-50 text-amber-500`} border inline-flex items-center rounded-md px-2 py-1 text-xs leading-4 font-medium`}>
          {row.status === 1 ? 'Active' : 'Inactive'}
        </span>
      </td>

      <td className='text-sm text-gray-500 px-1 py-5'>
        <div className="flex justify-end relative" data-headlessui-state="open">
          <Menu as="div" className="relative">
            <Menu.Button className="block text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"></path>
              </svg>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute mt-2 right-0 z-10 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg border border-gray-300">
                <Menu.Item>
                  <a
                    onClick={() => {
                      setOpen(true);
                      setChatbotDrawerId(row.id);
                      setDocIds(row.doc_ids);
                    }}
                    className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    {/* <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-1" /> */}
                    Open Chatbot
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    onClick={() => navigate(`/chatbot/${row.id}/share`)}
                    className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    {/* <ShareIcon className="h-5 w-5 mr-1" /> */}
                    Embed/Share
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    onClick={() => navigate(`/chatbot/${row.id}/settings`)}
                    className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                   >
                    {/* <ShareIcon className="h-5 w-5 mr-1" /> */}
                    Edit Chatbot
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a
                    onClick={() => {
                      setChatbotDrawerId(row.id);
                      setOpenDeleteModal(true);
                    }}
                    className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    Delete Chatbot
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/search" className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50">
                    View Desktop Chatbot
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/bot" className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50">
                    View Mobile Chatbot
                  </Link>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </td>
    </tr>
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return <>
    <div className="flex flex-col lg:flex-row justify-between items-center mb-4">
      <h3 className='text-2xl leading-5 font-semibold text-gray-900 text-center lg:text-left'>
        My Chatbots
      </h3>
      <div className='w-full lg:w-auto flex flex-col lg:flex-row'>
        <input
          type="text"
          placeholder='Search...'
          className="lg:mb-0 w-full lg:w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-3"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className='w-auto hidden lg:block'>
          <RadioGroup
            value={mode}
            onChange={setMode}
            className="bg-white grid grid-cols-2 gap-x-1 rounded-[5px] p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            {modes.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                    'inline-flex items-center justify-center cursor-pointer rounded-[5px] px-2.5 py-1'
                  )
                }
              >
                <img src={option.icon} className={`w-4 ${mode.value !== option.value ? `opacity-50` : `invert`}`} />
                <span className='inline-block ml-1 text-sm'>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      </div>
    </div>
    {
      mode.value === 'list' ? <Card>
        <div className='overflow-auto'>
          <div className='min-w-[720px]'>
            <DataTable cols={cols} rows={currentRows} renderRow={renderRow} />
          </div>
        </div>
        {/* Pagination */}
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastRow >= filteredRows.length}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{indexOfFirstRow + 1}</span> to <span className="font-medium">{Math.min(indexOfLastRow, filteredRows.length)}</span> of <span className="font-medium">{filteredRows.length}</span> results
              </p>
            </div>
            <div>
              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                  </svg>
                </button>
                {Array.from({ length: Math.ceil(filteredRows.length / rowsPerPage) }, (_, i) => i + 1).map(number => (
                  <button
                    key={number}
                    onClick={() => paginate(number)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === number ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}`}
                  >
                    {number}
                  </button>
                ))}
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={indexOfLastRow >= filteredRows.length}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </Card>
      : <div className='grid grid-cols-1 xl:grid-cols-3 gap-4'>
        {filteredRows.map((row) => (
          <div key={row.id}>
            <ChatbotCard {...row} onOpenChatbot={() => {
              setOpen(true);
              setChatbotDrawerId(row.id);
              setDocIds(row.doc_ids);
            }} />
          </div>
        ))}
      </div>
    }
    <div className='flex justify-center mt-10'>
      <button
        type="button"
        onClick={() => setOpenCreateChatbot(true)}
        className="flex-shrink-0 inline-flex justify-center items-center rounded-md bg-indigo-600 px-3 py-2 text-sm leading-5 font-semibold text-white"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="-ml-0.5 mr-1.5 w-5 h-5">
          <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
        </svg>
        Create Chatbot
      </button>
    </div>
    <ChatbotDrawer open={open} setOpen={setOpen} chatbotDrawerId={chatbotDrawerId} docIds={docIds} />

    <CreateChatDrawer
      open={openCreateChatbot}
      setOpen={setOpenCreateChatbot}
    />
    <DeleteModal open={openDeleteModal} setOpen={setOpenDeleteModal} chatId={chatbotDrawerId} itemType={'chat'}/>
    {/* <DeleteModal open={open} setOpen={setOpen} itemId={id} itemType={'chat'}/> */}
  </>
}

export default Chatbots;