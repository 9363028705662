import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Tab = ({ tabs, active, onClick }) => {
  return <nav className="flex justify-center lg:space-x-3 mb-2" aria-label="Tabs">
    {tabs.map((tab, tabIdx) => (
      <a
        key={tab.name}
        onClick={() => onClick(tab.id)}
        className={classNames(
          active === tab.id ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
          'flex rounded-md  py-2 text-xs lg:text-sm font-medium cursor-pointer px-3'
        )}
        aria-current={active === tab.id ? 'page' : undefined}
      >
        <UserIcon
          className={classNames(
            active === tab.id ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
            'mr-1.5 h-4 w-4 hidden lg:flex'
          )}
          aria-hidden="true"
        />
        <span>{tab.label}</span>
        {tab.badge && <span className={`${active === tab.id ? `bg-indigo-100 text-indigo-600` : `bg-gray-100 text-gray-600`} ml-3 rounded-full py5 px-2.5 text-xs leading-5 font-medium inline-block`}>
          {tab.badge}
        </span>}
      </a>
    ))}
  </nav>
}

export default Tab;