import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Empty from './Drawer/Empty';
import Modal from './Modal';

const FileList = ({ files }) => {
  return (
    <div className="mt-2">
      {files.map((file, index) => (
        <p key={index} className="text-gray-700">
          {file.name}
        </p>
      ))}
    </div>
  );
};

const Dropzone = () => {
  const [open, setOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleFileUpload = (event) => {
    const fileList = event.target.files;
    const selectedFiles = Array.from(fileList);
    
    if (selectedFiles.length > 0) {
      const filesByType = {
        word_files: [],
        ppt_files: [],
        pdf_files: [],
        csv_files: [],
        video_files: []  // New array for video files
      };
    
      selectedFiles.forEach((file) => {
        const extension = file.name.split('.').pop().toLowerCase();
        if (extension === 'docx') {
          filesByType.word_files.push(file);
        } else if (extension === 'pptx') {
          filesByType.ppt_files.push(file);
        } else if (extension === 'pdf') {
          filesByType.pdf_files.push(file);
        } else if (extension === 'csv' || extension === 'xls' || extension === 'xlsx') {
          filesByType.csv_files.push(file);
        } else if (['mp4', 'avi', 'mov', 'wmv'].includes(extension)) {  // Add common video extensions
          filesByType.video_files.push(file);
        }
      });
    
      setUploadedFiles(filesByType);
    }
  };

  const handleFileSubmit = () => {
    setIsModalOpen(true);
  };

  const handleConfirmUpload = () => {
    setIsModalOpen(false);
    setIsLoading(true);
    const formData = new FormData();

    // Add PDF files
    if (uploadedFiles.pdf_files.length > 0) {
      uploadedFiles.pdf_files.forEach((file) => {
        formData.append('pdf_files', file);
      });
    }
    
    // Add Word files
    if (uploadedFiles.word_files.length > 0) {
      uploadedFiles.word_files.forEach((file) => {
        formData.append('word_files', file);
      });
    }
    
    // Add PowerPoint files
    if (uploadedFiles.ppt_files.length > 0) {
      uploadedFiles.ppt_files.forEach((file) => {
        formData.append('ppt_files', file);
      });
    }
    
    // Add CSV files
    if (uploadedFiles.csv_files.length > 0) {
      uploadedFiles.csv_files.forEach((file) => {
        formData.append('csv_files', file);
      });
    }
    
    // Add Video files
    if (uploadedFiles.video_files.length > 0) {
      uploadedFiles.video_files.forEach((file) => {
        formData.append('video_files', file);
      });
    }
    
    // Display file names
    for (let pair of formData.entries()) {
      console.log('File Name:', pair[1].name);
    }
    
    fetch('https://www.chatassistant.online/api/upload_data_new', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error: ' + response.status);
      })
      .then((data) => {
        // Handle successful response
        console.log(data);
        navigate('/data-source');
      })
      .catch((error) => {
        // Handle error
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
        setUploadedFiles(null);
      });
  };

  return (
    <>
      <div className="py-6 px-4 bg-white flex flex-col items-center border border-gray-200 rounded-md">
        <svg
          className="w-12 h-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          ></path>
        </svg>
        <h3 className="mt-2 font-semibold text-sm leading-5 text-gray-900">
          Drop file(s) here to use as Data Source
        </h3>
        <p className="mt-1 text-sm leading-5 text-gray-500 text-center">PDF, Word, Excel, CSV, Video (MP4, AVI, MOV, WMV)</p>
        <div className="flex flex-col lg:flex-row items-center mt-6">
          <label className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm leading-5 font-semibold text-gray-700 shadow cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="-ml-0.5 mr-1.5 w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M3 4a1 1 0 00-1 1v9a1 1 0 001 1h14a1 1 0 001-1V5a1 1 0 00-1-1H3zm0 2h14v9H3V6zm4 2a1 1 0 00-2 0v1H4a1 1 0 100 2h1v1a1 1 0 102 0V9h1a1 1 0 100-2H7V6zm3 3a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0V9h-1a1 1 0 110-2h1V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>Select Files</span>
            <input
              type="file"
              className="hidden"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.csv,.mp4,.avi,.mov,.wmv"
              multiple
              onChange={handleFileUpload}
            />
          </label>
        </div>
        {uploadedFiles && (
          <>
            <FileList files={uploadedFiles.pdf_files} />
            <FileList files={uploadedFiles.word_files} />
            <FileList files={uploadedFiles.ppt_files} />
            <FileList files={uploadedFiles.csv_files} />
            <FileList files={uploadedFiles.video_files} />
          </>
        )}
        <button
          type="button"
          className="inline-flex items-center rounded-md mt-4 bg-indigo-600 px-3 py-2 text-sm leading-5 font-semibold text-white shadow"
          onClick={handleFileSubmit}
          disabled={isLoading || !uploadedFiles}
        >
          {isLoading ? 'Uploading...' : 'Submit Uploaded Files'}
        </button>
      </div>
      <Empty open={open} setOpen={setOpen} />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmUpload}
        title="Confirm Upload"
        message="Are you sure you want to upload these files?"
        confirmText="Upload"
        cancelText="Cancel"
      />
    </>
  );
};

export default Dropzone;