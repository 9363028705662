import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Dropzone from 'components/Dropzone';
import Tab from 'components/Tab';

const UploadDataDrawer = ({ open, setOpen }) => {
  const tabs = [
    { id: 1, label: "Files" },
    { id: 2, label: "Text" },
    { id: 3, label: "Video" },
  ];
  const [selectedTab, setSelectedTab] = useState(1);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                  <CloseButton setOpen={setOpen} />
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <Header />
                      <div className='w-full flex flex-col items-center relative'>
                        <div className="relative z-10 w-full mb-8">
                          <TabNavigation tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                          <TabContent selectedTab={selectedTab} />
                        </div>
                        <CreateChatbotButton />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const CloseButton = ({ setOpen }) => (
  <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
    <button
      type="button"
      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
      onClick={() => setOpen(false)}
    >
      <span className="sr-only">Close panel</span>
      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  </div>
);

const Header = () => (
  <div className='max-w-2xl flex flex-col items-center'>
    <p className='text-center font-bold text-2xl text-gray-800 mb-4'>
      Start adding Data Sets
    </p>
    <p className='text-sm text-center text-gray-600 mb-5'>
      Easily add Data Source to your Chatbot: Files (PDF, Excel, Word, CSV), Videos and Custom Text.
    </p>
  </div>
);

const TabNavigation = ({ tabs, selectedTab, setSelectedTab }) => (
  <div className='flex justify-center border-b border-b-gray-200 mb-2'>
    <Tab
      tabs={tabs}
      active={selectedTab}
      onClick={(e) => setSelectedTab(e)}
    />
  </div>
);

const TabContent = ({ selectedTab }) => (
  <div className='w-full'>
    {selectedTab === 1 && <Dropzone />}
    {selectedTab === 2 && <TextInput />}
    {selectedTab === 3 && <VideoInput />}
  </div>
);

const TextInput = () => (
  <div className='flex flex-col items-center'>
    <p className="block text-sm font-medium leading-6 text-gray-700 mb-1">
      Add custom text to your chatbot
    </p>
    <div className="w-full mb-3">
      <textarea placeholder='Add Custom Text here...' className="h-24 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
    </div>
    <AddButton text="Add Text to Chatbot" />
  </div>
);

const VideoInput = () => (
  <div className='flex flex-col items-center'>
    <p className="block text-sm font-medium leading-6 text-gray-700 mb-1">
      Add a video to your chatbot
    </p>
    <div className="w-full mb-3">
      <input type="text" placeholder='Add Video URL here' className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
    </div>
    <div className="w-full mb-3">
      <textarea placeholder='Add Video Description here' className="h-24 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
    </div>
    <AddButton text="Add Video to Chatbot" />
  </div>
);

const AddButton = ({ text }) => (
  <button type="button" className="flex-shrink-0 inline-flex items-center rounded-md !bg-[#54B87E] px-3 py-2 text-sm leading-5 font-semibold text-white shadow">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="-ml-0.5 mr-1.5 w-5 h-5">
      <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
    </svg>
    {text}
  </button>
);

const CreateChatbotButton = () => (
  <div className="relative z-10 inline-flex justify-center">
    <button type="button" className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm leading-5 font-semibold text-white shadow">
      Create Chatbot now 
    </button>
  </div>
);

export default UploadDataDrawer;