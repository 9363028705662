import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from 'components/Card';
import ChatbotDrawer from 'components/Drawer/ChatbotDrawer';
import { motion, AnimatePresence } from 'framer-motion';
import { Switch, Menu, Transition, Fragment, Tab, Dialog } from '@headlessui/react';
import { EllipsisVerticalIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon, UserCircleIcon, EnvelopeIcon, PhoneIcon, BriefcaseIcon, MapPinIcon, AcademicCapIcon, CheckIcon, ArchiveBoxIcon, TrashIcon, ArrowUturnLeftIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { format, parseISO, isToday, isYesterday, isThisWeek, isThisMonth, isThisYear } from 'date-fns';
import { 
  ChatBubbleLeftRightIcon, 
  QuestionMarkCircleIcon,   
  ExclamationTriangleIcon, 
  FaceSmileIcon, 
  ArrowTrendingUpIcon, 
  ChatBubbleBottomCenterTextIcon,
  LightBulbIcon,
  ChartBarIcon,
  ChatBubbleLeftEllipsisIcon,
  FaceFrownIcon,
  CheckCircleIcon,
  CurrencyDollarIcon,
  ListBulletIcon,
  ClockIcon,
  DocumentTextIcon,
  UsersIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import Modal from 'components/Modal';
import { useThreadsLogic } from './ThreadsLogic';
import { UserInfoModal } from 'components/UserInfoModal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const modes = [
  { value: 'faq', label: 'FAQs', icon: QuestionMarkCircleIcon, color: 'bg-blue-100 text-blue-800' },
  { value: 'knowledge_gap', label: 'Knowledge Gaps', icon: LightBulbIcon, color: 'bg-yellow-100 text-yellow-800' },
  { value: 'issue_resolution', label: 'Issue Resolution', icon: ExclamationTriangleIcon, color: 'bg-red-100 text-red-800' },
  { value: 'customer_sentiment', label: 'Customer Sentiment', icon: FaceSmileIcon, color: 'bg-green-100 text-green-800' },
  { value: 'escalation', label: 'Escalations', icon: ArrowTrendingUpIcon, color: 'bg-purple-100 text-purple-800' },
  { value: 'product_feedback', label: 'Product Feedback', icon: ChatBubbleBottomCenterTextIcon, color: 'bg-indigo-100 text-indigo-800' },
  { value: 'feature_request', label: 'Feature Requests', icon: LightBulbIcon, color: 'bg-pink-100 text-pink-800' },
  { value: 'feature_usage', label: 'Feature Usage', icon: ChartBarIcon, color: 'bg-cyan-100 text-cyan-800' },
  { value: 'user_feedback', label: 'User Feedback', icon: ChatBubbleLeftEllipsisIcon, color: 'bg-teal-100 text-teal-800' },
  { value: 'user_frustration', label: 'User Frustration', icon: FaceFrownIcon, color: 'bg-orange-100 text-orange-800' },
  { value: 'successful_resolution', label: 'Successful Resolutions', icon: CheckCircleIcon, color: 'bg-emerald-100 text-emerald-800' },
  { value: 'upsell_opportunity', label: 'Upsell Opportunities', icon: CurrencyDollarIcon, color: 'bg-lime-100 text-lime-800' },
  { value: 'threads', label: 'Threads', icon: ListBulletIcon, color: 'bg-gray-100 text-gray-800' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ToggleSwitch = ({ enabled, setEnabled }) => (
  <Switch.Group as="div" className="flex items-center">
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={`${
        enabled ? 'bg-indigo-600' : 'bg-gray-200'
      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
    >
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
      />
    </Switch>
    <Switch.Label className="ml-4 text-sm font-medium text-gray-900">
      {enabled ? "Cluster View" : "Table View"}
    </Switch.Label>
  </Switch.Group>
);

const Cluster = ({ cluster, onViewThread, mode }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderAdditionalInfo = (mode, additionalData) => {
    switch (mode) {
      case 'issue_resolution':
        return (
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${additionalData.resolved ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            {additionalData.resolved ? 'Resolved' : 'Unresolved'}
          </span>
        );
      case 'customer_sentiment':
        return (
          <span className="text-sm text-gray-500">
            Sentiment: {additionalData.sentiment}
          </span>
        );
      case 'product_feedback':
        return (
          <span className="text-sm text-gray-500">
            Product: {additionalData.product}
          </span>
        );
      case 'user_frustration':
        return (
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${additionalData.frustration_level > 5 ? 'bg-red-100 text-red-800' : additionalData.frustration_level > 3 ? 'bg-yellow-100 text-yellow-800' : 'bg-orange-100 text-orange-800'}`}>
            Frustration Level: {additionalData.frustration_level}
          </span>
        );
      case 'successful_resolution':
        return (
          <span className="text-sm text-gray-500">
            Time Taken: {additionalData.time_taken}s
          </span>
        );
      case 'upsell_opportunity':
        return (
          <span className="text-sm text-gray-500">
            Relevance: {additionalData.relevance_score.toFixed(2)}
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mb-6">
      <div className="bg-white shadow sm:rounded-lg">
        <div 
          className="px-4 py-5 sm:px-6 flex justify-between items-center cursor-pointer hover:bg-gray-50"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
            {cluster.label}
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
              {cluster.count} Items
            </span>
          </h3>
          {isExpanded ? (
            <ChevronUpIcon className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
          )}
        </div>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >  
              <div className="border-t border-gray-200">
                <dl>
                  {cluster.data.map((item, index) => (
                    <div key={index} className={index % 2 === 0 ? 'bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6' : 'bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'}>
                      <dt className="text-sm font-medium text-gray-500">Content</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex justify-between items-start">
                          <div>
                            <p>{item.cluster_content}</p>
                            {renderAdditionalInfo(mode, item.additional_data)}
                          </div>
                          <Menu as="div" className="relative inline-block text-left">
                            <div>
                              <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        onClick={() => onViewThread(item.thread_id,item.message_id)}
                                        className={classNames(
                                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          'block px-4 py-2 text-sm cursor-pointer'
                                        )}
                                      >
                                        View Thread
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

function Threads() {
  const { chatId } = useParams();
  const [mode, setMode] = useState(modes[0]);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('auto');
  const [open, setOpen] = useState(false);
  const [chatbotDrawerId, setChatbotDrawerId] = useState(null);
  const [chatbotDrawerThreadId, setChatbotDrawerThreadId] = useState(null);
  const [chatbotDrawerMessageId, setChatbotDrawerMessageId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const threadsPerPage = 10;
  const [insightSearchKeyword, setInsightSearchKeyword] = useState('');
  const [insightCurrentPage, setInsightCurrentPage] = useState(1);
  const insightsPerPage = 10;
  
  const {
    threads,
    insights,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    filterKeyword,
    setFilterKeyword,
    groupBy,
    setGroupBy,
    isClusterView,
    setIsClusterView,
    handleViewThread,
    renderThreadItem,
    sortedAndFilteredThreads,
    getSummary,
    renderSummaryItem,
    renderInsightRow,
    renderAdditionalInfo,
    handleViewUserInfo,
    modalOpen,
    setModalOpen,
    selectedUserInfo,
    selectedThreads,
    handleThreadSelection,
    handleSelectAll,
    handleDeselectAll,
    handleArchiveSelected,
    isArchiving,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    confirmArchive,
    archivedStatus,
    handleArchivedStatusChange,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isDeletingArchived,
    handleDeleteSelected,
    confirmDelete,
    handleUnarchiveSelected,
    confirmUnarchive,
    isUnarchiving,
    isUnarchiveModalOpen,
    setIsUnarchiveModalOpen,
    fetchData,
    activeThreadCount,
    archivedThreadCount,
  } = useThreadsLogic(chatId, modes);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page when switching between active and archived
  }, [archivedStatus]);

  // New effect to reset insight page when mode changes
  useEffect(() => {
    setInsightCurrentPage(1);
  }, [mode]);

  const getDateGroup = (date) => {
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    if (isThisWeek(date)) return 'This Week';
    if (isThisMonth(date)) return 'This Month';
    if (isThisYear(date)) return format(date, 'MMMM');
    return format(date, 'MMMM yyyy');
  };

  const paginatedThreads = () => {
    const allThreads = Object.values(sortedAndFilteredThreads()).flat();
    const startIndex = (currentPage - 1) * threadsPerPage;
    const endIndex = startIndex + threadsPerPage;
    return allThreads.slice(startIndex, endIndex);
  };

  const groupPaginatedThreads = () => {
    const grouped = {};
    paginatedThreads().forEach(thread => {
      const date = thread.last_activity ? parseISO(thread.last_activity) : new Date(0);
      const key = getDateGroup(date);
      if (!grouped[key]) grouped[key] = [];
      grouped[key].push(thread);
    });
    return grouped;
  };

  const totalThreads = Object.values(sortedAndFilteredThreads()).flat().length;
  const totalPages = Math.ceil(totalThreads / threadsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleViewThreadWrapper = (threadId, messageId) => {
    setChatbotDrawerId(chatId);
    setChatbotDrawerThreadId(threadId);
    setChatbotDrawerMessageId(messageId);
    setOpen(true);
  };

  useEffect(() => {
    const handleViewChange = () => {
      if (contentRef.current) {
        setContentHeight(`${contentRef.current.scrollHeight}px`);
      }
      setTimeout(() => {
        setContentHeight('auto');
      }, 300);
    };

    handleViewChange();
  }, [mode, isClusterView]);

  const summaryItems = [
    { key: 'TotalThreads', label: 'Total Threads', icon: ChatBubbleLeftRightIcon },
    { key: 'TotalMessages', label: 'Total Messages', icon: ChatBubbleOvalLeftEllipsisIcon },
    { key: 'UniqueUsers', label: 'Unique Users', icon: UsersIcon },
  ];

  const renderArchiveControls = () => {
    return (
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-2">
          <button
            onClick={() => handleArchivedStatusChange('active')}
            className={`px-2 py-1 text-sm rounded-md transition-all duration-200 ${
              archivedStatus === 'active'
                ? 'bg-indigo-600 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Active Threads ({activeThreadCount})
          </button>
          <button
            onClick={() => handleArchivedStatusChange('archived')}
            className={`px-2 py-1 text-sm rounded-md transition-all duration-200 ${
              archivedStatus === 'archived'
                ? 'bg-indigo-600 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Archived Threads ({archivedThreadCount})
          </button>
        </div>
        {selectedThreads.length > 0 && (
          <div className="flex space-x-2">
            <button
              onClick={handleSelectAll}
              className="inline-flex items-center px-2 py-1 text-sm rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 transition-all duration-200"
            >
              <CheckIcon className="h-4 w-4 mr-1" />
              Select All
            </button>
            <button
              onClick={handleDeselectAll}
              className="inline-flex items-center px-2 py-1 text-sm rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 transition-all duration-200"
            >
              <XMarkIcon className="h-4 w-4 mr-1" />
              Deselect All
            </button>
            {archivedStatus === 'active' ? (
              <button
                onClick={handleArchiveSelected}
                disabled={isArchiving}
                className={`inline-flex items-center px-2 py-1 text-sm rounded-md text-white transition-all duration-200 ${
                  isArchiving
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-indigo-600 hover:bg-indigo-700'
                }`}
              >
                <ArchiveBoxIcon className="h-4 w-4 mr-1" />
                {isArchiving ? 'Archiving...' : `Archive (${selectedThreads.length})`}
              </button>
            ) : (
              <>
                <button
                  onClick={handleUnarchiveSelected}
                  disabled={isUnarchiving}
                  className={`inline-flex items-center px-2 py-1 text-sm rounded-md text-white transition-all duration-200 ${
                    isUnarchiving
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-green-600 hover:bg-green-700'
                  }`}
                >
                  <ArrowUturnLeftIcon className="h-4 w-4 mr-1" />
                  {isUnarchiving ? 'Unarchiving...' : `Unarchive (${selectedThreads.length})`}
                </button>
                <button
                  onClick={handleDeleteSelected}
                  disabled={isDeletingArchived}
                  className={`inline-flex items-center px-2 py-1 text-sm rounded-md text-white transition-all duration-200 ${
                    isDeletingArchived
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-red-600 hover:bg-red-700'
                  }`}
                >
                  <TrashIcon className="h-4 w-4 mr-1" />
                  {isDeletingArchived ? 'Deleting...' : `Delete (${selectedThreads.length})`}
                </button>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (mode.value === 'threads') {
      return (
        <>
          <div className="mb-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Threads Summary</h3>
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {summaryItems.map(item => {
                const summaryData = getSummary()[item.key];
                return (
                  <div key={item.key} className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="text-sm font-medium text-gray-500 truncate">{item.label}</dt>
                            <dd>
                              <div className="text-lg font-medium text-gray-900">{summaryData.toLocaleString()}</div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mb-6 flex justify-between items-center">
            <div className="flex space-x-2">
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="date">Sort by Date</option>
                <option value="messages">Sort by Messages</option>
              </select>
              <button
                onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {sortOrder === 'asc' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              <select
                value={groupBy}
                onChange={(e) => setGroupBy(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="date">Group by Date</option>
                <option value="assistant">Group by Assistant</option>
              </select>
            </div>
            <input
              type="text"
              placeholder="Filter threads..."
              value={filterKeyword}
              onChange={(e) => setFilterKeyword(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          {renderArchiveControls()}

          {Object.entries(groupPaginatedThreads()).map(([group, groupThreads]) => (
            <div key={group} className="mb-6">
              <h4 className="text-lg font-medium text-gray-900 mb-2 px-4">{group}</h4>
              <ul className="divide-y divide-gray-200 bg-white shadow overflow-hidden rounded-md">
                {groupThreads.map(thread => renderThreadItem(thread, handleViewThreadWrapper, handleViewUserInfo))}
              </ul>
            </div>
          ))}

          {/* Pagination */}
          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * threadsPerPage + 1}</span> to <span className="font-medium">{Math.min(currentPage * threadsPerPage, totalThreads)}</span> of <span className="font-medium">{totalThreads}</span> results
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {[...Array(totalPages).keys()].map((number) => (
                    <button
                      key={number + 1}
                      onClick={() => paginate(number + 1)}
                      className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                        currentPage === number + 1
                          ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                      }`}
                    >
                      {number + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      const currentInsights = insights[mode.value] || [];
      const insightItems = Array.isArray(currentInsights) 
        ? currentInsights
        : Object.values(currentInsights).flatMap(cluster => cluster.data || []);

      // Filter insights based on search keyword
      const filteredInsights = insightItems.filter(insight => 
        insight.cluster_content.toLowerCase().includes(insightSearchKeyword.toLowerCase())
      );

      // Paginate insights
      const indexOfLastInsight = insightCurrentPage * insightsPerPage;
      const indexOfFirstInsight = indexOfLastInsight - insightsPerPage;
      const currentInsightsPage = filteredInsights.slice(indexOfFirstInsight, indexOfLastInsight);

      const totalInsightPages = Math.ceil(filteredInsights.length / insightsPerPage);

      return (
        <>
          <div className="flex justify-between items-center mb-4">
            <div className="flex-1">
              {!isClusterView && (
                <div className="relative w-64">
                  <input
                    type="text"
                    placeholder="Search insights..."
                    value={insightSearchKeyword}
                    onChange={(e) => setInsightSearchKeyword(e.target.value)}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 pl-10 py-1 text-sm"
                  />
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                </div>
              )}
            </div>
            <div>
              <ToggleSwitch 
                enabled={isClusterView}
                setEnabled={setIsClusterView}
              />
            </div>
          </div>
          {isClusterView ? (
            <div>
              {Object.entries(insights[mode.value] || {}).map(([key, cluster]) => (
                <Cluster 
                  key={key}
                  cluster={cluster}
                  onViewThread={handleViewThreadWrapper}
                  mode={mode.value}
                />
              ))}
            </div>
          ) : (
            <>
              <div className="bg-white shadow sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                  {currentInsightsPage.map(insight => {
                    const insightData = renderInsightRow(insight);
                    return (
                      <li key={insightData.id} className="py-4 hover:bg-gray-50 transition duration-150 ease-in-out rounded-lg">
                        <div className="flex items-center space-x-4 px-4">
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900 truncate">{insightData.content}</p>
                            {insightData.additional_data && (
                              <p className="text-sm text-gray-500 truncate">
                                {renderAdditionalInfo(mode.value, insightData.additional_data)}
                              </p>
                            )}
                          </div>
                          <div className="text-sm text-gray-500">
                            <p>{insightData.date}</p>
                          </div>
                          <button
                            onClick={() => handleViewThreadWrapper(insightData.thread_id, insightData.message_id)}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                          >
                            View Thread
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* Pagination */}
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
                <div className="flex flex-1 justify-between sm:hidden">
                  <button
                    onClick={() => setInsightCurrentPage(insightCurrentPage - 1)}
                    disabled={insightCurrentPage === 1}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => setInsightCurrentPage(insightCurrentPage + 1)}
                    disabled={indexOfLastInsight >= filteredInsights.length}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{indexOfFirstInsight + 1}</span> to <span className="font-medium">{Math.min(indexOfLastInsight, filteredInsights.length)}</span> of <span className="font-medium">{filteredInsights.length}</span> results
                    </p>
                  </div>
                  <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                      <button
                        onClick={() => setInsightCurrentPage(insightCurrentPage - 1)}
                        disabled={insightCurrentPage === 1}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      {[...Array(totalInsightPages).keys()].map((number) => (
                        <button
                          key={number + 1}
                          onClick={() => setInsightCurrentPage(number + 1)}
                          className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                            insightCurrentPage === number + 1
                              ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                              : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          }`}
                        >
                          {number + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => setInsightCurrentPage(insightCurrentPage + 1)}
                        disabled={indexOfLastInsight >= filteredInsights.length}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  };

  if (!threads || !insights) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <Card>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={3}
        style={{ fontSize: '14px' }}
      />
      <div className="space-y-8">
        <h3 className='text-3xl font-bold text-gray-900 text-center'>
          Threads Overview
        </h3>
        
        <div className="flex flex-col items-center space-y-6 mb-8">
          <Tab.Group onChange={(index) => setMode(modes[index])}>
            <Tab.List className="flex flex-wrap justify-center max-w-4xl mx-auto">
              {modes.map((mode) => (
                <Tab
                  key={mode.value}
                  className={({ selected }) =>
                    classNames(
                      'flex items-center px-2 py-1 m-0.5 text-xs font-medium rounded-full',
                      'focus:outline-none transition-all duration-200 ease-in-out',
                      selected
                        ? `${mode.color} shadow-sm`
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    )
                  }
                >
                  {({ selected }) => (
                    <motion.div
                      className="flex items-center space-x-1"
                      initial={false}
                      animate={{ 
                        scale: selected ? 1.05 : 1,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      <mode.icon className={`w-3 h-3 ${selected ? 'text-current' : 'text-gray-400'}`} />
                      <span>{mode.label}</span>
                    </motion.div>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>
      
        <div 
          className={`overflow-hidden transition-all duration-300`}
          style={{ height: contentHeight }}
          ref={contentRef}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={`${mode.value}-${isClusterView}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {renderContent()}
            </motion.div>
          </AnimatePresence>
        </div>
      
        <ChatbotDrawer 
          open={open} 
          setOpen={setOpen} 
          chatbotDrawerId={chatbotDrawerId} 
          threadId={chatbotDrawerThreadId} 
          messageId={chatbotDrawerMessageId} 
        />

        <UserInfoModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          userInfo={selectedUserInfo}
        />
        <Modal
          isOpen={isArchiveModalOpen}
          onClose={() => setIsArchiveModalOpen(false)}
          onConfirm={confirmArchive}
          title="Archive Threads"
          message={`Are you sure you want to archive ${selectedThreads.length} thread${selectedThreads.length > 1 ? 's' : ''}?`}
          confirmText="Archive"
          cancelText="Cancel"
          type="update"
        />
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          title="Delete Archived Threads"
          message={`Are you sure you want to delete ${selectedThreads.length} archived thread${selectedThreads.length > 1 ? 's' : ''}? This action cannot be undone.`}
          confirmText="Delete"
          cancelText="Cancel"
          type="delete"
        />
        <Modal
          isOpen={isUnarchiveModalOpen}
          onClose={() => setIsUnarchiveModalOpen(false)}
          onConfirm={confirmUnarchive}
          title="Unarchive Threads"
          message={`Are you sure you want to unarchive ${selectedThreads.length} thread${selectedThreads.length > 1 ? 's' : ''}?`}
          confirmText="Unarchive"
          cancelText="Cancel"
          type="update"
        />
      </div>
    </Card>
  );
}

export default Threads;