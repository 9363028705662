import { useState, useEffect, useCallback } from 'react';
import { parseISO, isToday, isYesterday, isThisWeek, isThisMonth, format } from 'date-fns';
import axios from 'axios';
import { UserCircleIcon, DocumentTextIcon, ClockIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify'; // Import toast

export function useThreadsLogic(chatId, modes) {
  const [threads, setThreads] = useState(null);
  const [insights, setInsights] = useState({});
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterKeyword, setFilterKeyword] = useState('');
  const [groupBy, setGroupBy] = useState('date');
  const [selectedThreads, setSelectedThreads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterMessageCount, setFilterMessageCount] = useState('');
  const threadsPerPage = 10;
  const [isClusterView, setIsClusterView] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  const [isArchiving, setIsArchiving] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [archivedStatus, setArchivedStatus] = useState('active');
  const [isDeletingArchived, setIsDeletingArchived] = useState(false);
  const [isUnarchiving, setIsUnarchiving] = useState(false);
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
  const [activeThreadCount, setActiveThreadCount] = useState(0);
  const [archivedThreadCount, setArchivedThreadCount] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      // Fetch both active and archived threads
      const [activeResponse, archivedResponse, ...insightResponses] = await Promise.all([
        fetch(`https://www.chatassistant.online/api/get-threads?assistant_id=${chatId}&archived_status=active`),
        fetch(`https://www.chatassistant.online/api/get-threads?assistant_id=${chatId}&archived_status=archived`),
        ...modes.filter(m => m.value !== 'threads').map(m => 
          fetch(`https://www.chatassistant.online/api/insights/${chatId}/${m.value}`)
        )
      ]);

      const activeData = await activeResponse.json();
      const archivedData = await archivedResponse.json();

      // Set the threads based on the current archivedStatus
      setThreads(archivedStatus === 'active' ? activeData.threads.reverse() : archivedData.threads.reverse());

      // Update counts
      setActiveThreadCount(activeData.threads.length);
      setArchivedThreadCount(archivedData.threads.length);

      const insightData = {};
      modes.filter(m => m.value !== 'threads').forEach((mode, index) => {
        insightData[mode.value] = insightResponses[index].json();
      });
      
      const resolvedInsightData = await Promise.all(Object.values(insightData));
      const finalInsightData = {};
      Object.keys(insightData).forEach((key, index) => {
        finalInsightData[key] = resolvedInsightData[index].clustered_data;
      });
      setInsights(finalInsightData);
    } catch (error) {
      console.log("Error fetching data:", error);
      toast.error("Failed to fetch data. Please try again.");
    }
  }, [chatId, modes, archivedStatus]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleThreadSelection = (threadId) => {
    setSelectedThreads(prev => 
      prev.includes(threadId) 
        ? prev.filter(id => id !== threadId)
        : [...prev, threadId]
    );
  };

  const handleSelectAll = () => {
    const allThreadIds = Object.values(sortedAndFilteredThreads())
      .flat()
      .map(thread => thread.thread_id);
    setSelectedThreads(allThreadIds);
  };

  const handleDeselectAll = () => {
    setSelectedThreads([]);
  };

  const handleArchiveSelected = () => {
    if (selectedThreads.length > 0) {
      setIsArchiveModalOpen(true);
    }
  };
  
  const confirmArchive = async () => {
    setIsArchiving(true);
    try {
      const response = await axios.post('/api/archive-threads', {
        thread_ids: selectedThreads,
        assistant_id: chatId
      });
  
      if (response.data.success) {
        await fetchData(); // Refresh data
        setSelectedThreads([]);
        toast.success(`Successfully archived ${selectedThreads.length} thread(s)`);
      } else {
        toast.error('Failed to archive threads: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error archiving threads:', error);
      toast.error('Error archiving threads. Please try again.');
    } finally {
      setIsArchiving(false);
      setIsArchiveModalOpen(false);
    }
  };

  const getDateGroup = (date) => {
    if (isToday(date)) return 'Today';
    if (isYesterday(date)) return 'Yesterday';
    if (isThisWeek(date)) return 'This Week';
    if (isThisMonth(date)) return 'This Month';
    return format(date, 'MMMM yyyy');
  };

  const groupThreads = () => {
    if (!threads) return {};
    return threads.reduce((groups, thread) => {
      let key;
      if (groupBy === 'date') {
        const date = thread.last_activity ? parseISO(thread.last_activity) : new Date(0);
        key = getDateGroup(date);
      } else if (groupBy === 'assistant') {
        key = thread.assistant_id || 'Unknown Assistant';
      }
      if (!groups[key]) groups[key] = [];
      groups[key].push(thread);
      return groups;
    }, {});
  };

  const sortedAndFilteredThreads = () => {
    const grouped = groupThreads();
    return Object.entries(grouped).reduce((acc, [key, threads]) => {
      acc[key] = threads
        .filter(t => 
          (t.last_message && t.last_message.toLowerCase().includes(filterKeyword.toLowerCase())) ||
          (t.fingerprint_id && t.fingerprint_id.toLowerCase().includes(filterKeyword.toLowerCase())) ||
          (t.thread_id && t.thread_id.toLowerCase().includes(filterKeyword.toLowerCase())) ||
          (filterMessageCount && t.num_messages >= parseInt(filterMessageCount))
        )
        .sort((a, b) => {
          if (sortBy === 'date') {
            return sortOrder === 'desc' 
              ? new Date(b.last_activity) - new Date(a.last_activity)
              : new Date(a.last_activity) - new Date(b.last_activity);
          }
          if (sortBy === 'messages') {
            return sortOrder === 'desc' ? b.num_messages - a.num_messages : a.num_messages - b.num_messages;
          }
          return 0;
        });
      return acc;
    }, {});
  };

  const paginatedThreads = () => {
    const allThreads = Object.values(sortedAndFilteredThreads()).flat();
    const startIndex = (currentPage - 1) * threadsPerPage;
    const endIndex = startIndex + threadsPerPage;
    return allThreads.slice(startIndex, endIndex);
  };

  const handleViewThread = (threadId, messageId) => {
    return { threadId, messageId, chatId };
  };

  const handleViewUserInfo = async (fingerprintId) => {
    try {
      const response = await axios.get(`/api/users-info?fingerprint_id=${fingerprintId}`);
      setSelectedUserInfo(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setSelectedUserInfo({});
      setModalOpen(true);
    }
  };

  const handleDeleteSelected = () => {
    if (selectedThreads.length > 0 && archivedStatus === 'archived') {
      setIsDeleteModalOpen(true);
    }
  };
  
  const confirmDelete = async () => {
    setIsDeletingArchived(true);
    try {
      const response = await axios.post('/api/delete-archived-threads', {
        thread_ids: selectedThreads,
        assistant_id: chatId
      });
  
      if (response.data.success) {
        await fetchData(); // Refresh data
        setSelectedThreads([]);
        toast.success(`Successfully deleted ${selectedThreads.length} thread(s)`);
      } else {
        toast.error('Failed to delete threads: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error deleting threads:', error);
      toast.error('Error deleting threads. Please try again.');
    } finally {
      setIsDeletingArchived(false);
      setIsDeleteModalOpen(false);
    }
  };

  const truncate = (str, n) => {
    if (str === undefined || str === null) return '';
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  };


  const renderThreadItem = (thread, onViewThread, onViewUserInfo) => (
    <li key={thread.thread_id} className="py-4 hover:bg-gray-50 transition duration-150 ease-in-out rounded-lg">
      <div className="flex items-center space-x-4 px-4">
        <div className="flex-shrink-0">
          <input
            type="checkbox"
            checked={selectedThreads.includes(thread.thread_id)}
            onChange={() => handleThreadSelection(thread.thread_id)}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
        </div>
        <div className="flex-shrink-0">
          <UserCircleIcon className="h-10 w-10 text-gray-400" />
        </div>
        <div className="flex-1 min-w-0">
          <button
            onClick={() => onViewUserInfo(thread.fingerprint_id)}
            className="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-full shadow-sm text-indigo-800 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            title={thread.fingerprint_id}
          >
            ID: {thread.fingerprint_id ? thread.fingerprint_id.slice(0, 8) + '...' : 'Unknown'}
          </button>
          <div className="flex items-center text-sm text-gray-500 truncate mt-1">
            <DocumentTextIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
            <p>{thread.last_message || 'No messages'}</p>
          </div>
          <div className="flex items-center mt-1">
            <ClockIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
            <p className="text-xs text-gray-500">
              Last activity: {thread.last_activity ? format(parseISO(thread.last_activity), 'MMM d, yyyy HH:mm') : 'Unknown'}
            </p>
          </div>
        </div>
        <div className="text-sm text-gray-500">
          <div className="flex items-center mb-1">
            <ChatBubbleLeftRightIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
            <p>{thread.num_messages || 0} messages</p>
          </div>
          <p className="text-xs text-gray-400">Assistant: {thread.assistant_id ? thread.assistant_id.slice(0, 8) + '...' : 'Unknown'}</p>
        </div>
        <button
          onClick={() => onViewThread(thread.thread_id)}
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
        >
          View Thread
        </button>
      </div>
    </li>
  );

  const prepareInsightDataForTable = (data) => {
    return Object.entries(data || {}).map(([key, cluster]) => ({
      label: cluster.label,
      count: cluster.count,
      thread_id: cluster.data[0]?.thread_id // Assuming the first item has the thread_id
    }));
  };

  const renderInsightRow = (insight) => ({
    id: insight.id,
    content: insight.content || insight.cluster_content,
    additional_data: insight.additional_data,
    date: insight.date ? new Date(insight.date).toLocaleString() : '',
    thread_id: insight.thread_id,
    message_id: insight.message_id,
  });

  const handleViewChange = () => {
    setIsClusterView(!isClusterView);
  };

  const renderAdditionalInfo = (mode, additionalData) => {
    switch (mode) {
      case 'issue_resolution':
        return (
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${additionalData.resolved ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            {additionalData.resolved ? 'Resolved' : 'Unresolved'}
          </span>
        );
      case 'customer_sentiment':
        return (
          <span className="text-sm text-gray-500">
            Sentiment: {additionalData.sentiment}
          </span>
        );
      case 'product_feedback':
        return (
          <span className="text-sm text-gray-500">
            Product: {additionalData.product}
          </span>
        );
      case 'user_frustration':
        return (
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${additionalData.frustration_level > 5 ? 'bg-red-100 text-red-800' : additionalData.frustration_level > 3 ? 'bg-yellow-100 text-yellow-800' : 'bg-orange-100 text-orange-800'}`}>
            Frustration Level: {additionalData.frustration_level}
          </span>
        );
      case 'successful_resolution':
        return (
          <span className="text-sm text-gray-500">
            Time Taken: {additionalData.time_taken}s
          </span>
        );
      case 'upsell_opportunity':
        return (
          <span className="text-sm text-gray-500">
            Relevance: {additionalData.relevance_score.toFixed(2)}
          </span>
        );
      default:
        return null;
    }
  };

  const getSummary = () => {
    return {
      TotalThreads: threads ? threads.length : 0,
      TotalMessages: threads ? threads.reduce((sum, thread) => sum + (thread.num_messages || 0), 0) : 0,
      UniqueUsers: threads ? new Set(threads.map(thread => thread.fingerprint_id)).size : 0,
    };
  };

  const renderSummaryItem = (item) => {
    const value = getSummary()[item.key];
    return {
      key: item.key,
      icon: item.icon,
      label: item.label,
      value: value.toLocaleString()
    };
  };

  const handleArchivedStatusChange = (status) => {
    setArchivedStatus(status);
    setSelectedThreads([]);  // Clear selected threads when switching views
    setCurrentPage(1);  // Reset to first page when switching views
  };

  const handleUnarchiveSelected = () => {
    if (selectedThreads.length > 0 && archivedStatus === 'archived') {
      setIsUnarchiveModalOpen(true);
    }
  };

  const confirmUnarchive = async () => {
    setIsUnarchiving(true);
    try {
      const response = await axios.post('/api/unarchive-threads', {
        thread_ids: selectedThreads,
        assistant_id: chatId
      });

      if (response.data.success) {
        await fetchData(); // Refresh data
        setSelectedThreads([]);
        toast.success(`Successfully unarchived ${selectedThreads.length} thread(s)`);
      } else {
        toast.error('Failed to unarchive threads: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error unarchiving threads:', error);
      toast.error('Error unarchiving threads. Please try again.');
    } finally {
      setIsUnarchiving(false);
      setIsUnarchiveModalOpen(false);
    }
  };

  return {
    threads,
    insights,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    filterKeyword,
    setFilterKeyword,
    groupBy,
    setGroupBy,
    selectedThreads,
    setSelectedThreads,
    currentPage,
    setCurrentPage,
    filterMessageCount,
    setFilterMessageCount,
    handleThreadSelection,
    handleSelectAll,
    handleDeselectAll,
    sortedAndFilteredThreads,
    paginatedThreads,
    handleViewUserInfo,
    handleDeleteSelected,
    confirmDelete,
    handleViewThread,
    truncate,
    prepareInsightDataForTable,
    renderInsightRow,
    renderAdditionalInfo,
    handleViewChange,
    isClusterView,
    setIsClusterView,
    renderThreadItem,
    renderSummaryItem, // Add this line
    getSummary,
    threadsPerPage,  // Add this line
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    modalOpen,
    setModalOpen,
    selectedUserInfo,
    handleArchiveSelected,
    isArchiving,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    confirmArchive,
    archivedStatus,
    handleArchivedStatusChange,
    isDeletingArchived,
    handleUnarchiveSelected,
    confirmUnarchive,
    isUnarchiving,
    isUnarchiveModalOpen,
    setIsUnarchiveModalOpen,
    fetchData, // Add this to the returned object
    activeThreadCount,
    archivedThreadCount,
  };
}