import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'components/Card';
import DataTable from 'components/DataTable';
import AddDataSources from 'components/Drawer/AddDataSources';
import { useParams } from 'react-router-dom';
import Modal from 'components/Modal';

const ChatbotDataSources = ({ rows, dataSetsCount, reloadDataSources }) => {
  const { chatId } = useParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredRows, setFilteredRows] = useState(rows);
  const rowsPerPage = 10;
  const cols = [
    { label: 'Data Source' },
    { label: 'Data Type', align: 'center' },
    { label: 'File Size', align: 'center' },
    { label: 'Characters', align: 'center' },
    { label: 'Actions', align: 'right' }
  ];
  

  const formatNumber = (number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    } else if (number >= 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    } else {
      return number.toString();
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);

  const handleRemoveDataSource = (docId) => {
    setSelectedDocId(docId);
    setIsModalOpen(true);
  };

  const confirmRemoveDataSource = async () => {
    try {
      const response = await fetch('/api/remove_association', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          chat_id: chatId,
          doc_id: selectedDocId,
        })
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          setIsModalOpen(false);
          reloadDataSources(); // Reload data sources instead of refreshing the page
        } else {
          console.error('Failed to remove association:', data.message);
        }
      } else {
        console.error('Failed to remove data source');
      }
    } catch (error) {
      console.error('Error removing data source:', error);
    }
  };

  const renderRow = (row) => {
    return <tr className='border-b border-gray-100'>
      <td className='w-0 px-1 py-5'>
        <input type="checkbox" className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
      </td>
      <td className='px-3 py-5'>
        <div className='flex items-center'>
          <img className="w-11 h-11 flex-none rounded-full bg-gray-50"
            // src={row.avatar}
            src="/images/file-icon.png"
            alt={row.name} />
          <div className="min-w-0 ml-4">
            <span
              onClick={() => navigate('/chatbot/settings')}
              className="font-semibold text-sm leading-5 text-gray-900 cursor-pointer"
            >
              {row.name}
            </span>
            <div className="mt-1 flex items-center gap-2 text-sm leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                Added on {row.date}
              </p>
            </div>
          </div>
        </div>
      </td>
      <td className='text-sm text-gray-500 px-1 py-5 text-center'>
        <span className="border-green-200 bg-green-50 text-green-700 border inline-flex items-center rounded-md px-2 py-1 text-xs leading-4 font-medium">
        {row.data_type}
        </span>
      </td>
      <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          {row.file_size && row.file_size > 0 ? (
            row.file_size < 1024 ? (
              `${row.file_size} bytes` // Display in bytes if file size is less than 1 KB
            ) : row.file_size < 1024 * 1024 ? (
              `${(row.file_size / 1024).toFixed(2)} KB` // Display in KB if file size is less than 1 MB
            ) : (
              `${(row.file_size / (1024 * 1024)).toFixed(2)} MB` // Display in MB if file size is 1 MB or greater
            )
          ) : (
            "-" // Display dash if file size is 0 or null
          )}
        </td>
      <td className='text-sm text-gray-500 px-1 py-5 text-center'>
        {formatNumber(row.n_characters)}
      </td>
      <td className='text-sm text-gray-500 px-1 py-5 text-right'>
        <button 
          type="button" 
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm leading-5 font-semibold text-gray-900 shadow"
          onClick={() => handleRemoveDataSource(row.id)}
        >
          Remove
        </button>
      </td>
    </tr>
  }

  useEffect(() => {
    const results = rows.filter(row =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRows(results);
    setCurrentPage(1);
  }, [searchTerm, rows]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return <Card>
    <div className='flex flex-col lg:flex-row justify-between items-center mb-10'>
      <span className='mb-3 lg:mb-0 font-semibold text-sm text-gray-700 lg:mr-3'>{dataSetsCount} Data Sets</span>
      <div className='flex items-center'>
        <input
          type="text"
          placeholder='Search...'
          className="lg:mb-0 w-full lg:w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-3"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          onClick={() => setOpen(true)}
          type="button"
          className="flex-shrink-0 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm leading-5 font-semibold text-white shadow"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="-ml-0.5 mr-1.5 w-5 h-5">
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
          </svg>
          Add Data Source
        </button>
      </div>
    </div>
    <div className='overflow-x-auto'>
      <div className='min-w-[676px]'>
        <DataTable cols={cols} rows={currentRows} renderRow={renderRow} />
      </div>
    </div>

    {/* Pagination */}
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastRow >= filteredRows.length}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{indexOfFirstRow + 1}</span> to <span className="font-medium">{Math.min(indexOfLastRow, filteredRows.length)}</span> of{' '}
            <span className="font-medium">{filteredRows.length}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
              </svg>
            </button>
            {/* Add page numbers here if needed */}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastRow >= filteredRows.length}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>

    <AddDataSources
      open={open}
      setOpen={setOpen}
      existingSources={rows}
      chatId={chatId}
      reloadDataSources={reloadDataSources}
    />

    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onConfirm={confirmRemoveDataSource}
      title="Confirm Removal"
      message="Are you sure you want to remove this data source from the chat?"
      confirmText="Remove"
      cancelText="Cancel"
      type="delete"  // Add this line
    />
  </Card>
}

export default ChatbotDataSources;