import { useEffect, useRef } from 'react';

export function useProgressAnimation(rows) {
  const animatedProgressRef = useRef({});
  const lastFetchedProgress = useRef({});

  useEffect(() => {
    rows.forEach(row => {
      if (row.status === 'processing') {
        lastFetchedProgress.current[row.id] = row.progress;
      }
    });

    const intervalId = setInterval(() => {
      const newProgress = { ...animatedProgressRef.current };
      rows.forEach(row => {
        if (row.status === 'processing') {
          const start = animatedProgressRef.current[row.id] || 0;
          const end = lastFetchedProgress.current[row.id] || 100;
          const duration = 30000; // 30 seconds to reach the target
          const elapsed = Date.now() - (animatedProgressRef.current[row.id + '_startTime'] || Date.now());
          
          // Slow down progress as it gets closer to the target
          const remainingProgress = end - start;
          const speed = Math.max(0.01, remainingProgress / 100); // Slower speed for smaller remaining progress
          
          newProgress[row.id] = Math.min(end, start + speed);
          if (!animatedProgressRef.current[row.id + '_startTime']) {
            newProgress[row.id + '_startTime'] = Date.now();
          }
        }
      });
      animatedProgressRef.current = newProgress;
    }, 100); // Update every 100ms for smoother animation

    return () => clearInterval(intervalId);
  }, [rows]);

  return animatedProgressRef;
}