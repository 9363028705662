import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams, Route } from 'react-router-dom';
import Tab from 'components/Tab';
import DataSources from 'pages/Chatbot/ChatbotDataSources'; // Import DataSources component
import Settings from 'pages/Chatbot/Settings';
import Share from 'pages/Chatbot/Share';
import Threads from 'pages/Chatbot/Threads';
import Feedbacks from 'pages/Chatbot/Feedbacks';
import Statistics from 'pages/Chatbot/Statistics'; 
import Visitors from 'pages/Chatbot/Visitors'; // Add this import




const ChatbotContainer = () => {
  const navigate = useNavigate();
  const { chatId } = useParams();
  const { pathname } = useLocation();
  const [dataSetsCount, setdataSetsCount] = useState('0'); // State for badge count
  const [active, setActive] = useState(1);
  const [chatDocsIds, setChatDocsIds] = useState('');

  const tabs = [
    { id: 1, label: 'Settings', path: '/settings' },
    { id: 2, label: 'Data Sets', path: '/data-sources', badge: dataSetsCount.toString()  },
    { id: 3, label: 'Share & Integrate', path: '/share' },
    { id: 4, label: 'Threads', path: '/threads' },
    { id: 5, label: 'Feedbacks', path: '/feedbacks' },
    { id: 6, label: 'Statistics', path: '/statistics' }, // Add this line
    { id: 7, label: 'Visitors', path: '/visitors' }, // Add this line

  ];
  const handleClickTab = id => {
    setActive(id);
    const path = tabs.filter(tab => tab.id === id)[0].path;
    navigate(`/chatbot/${chatId}${path}`);
  }


  useEffect(() => {
    setActive(tabs.filter(tab => pathname.indexOf(tab.path) > -1)[0].id);
  }, [pathname]);
 

  // fetch document data
  const [rows, setRows] = useState([]);

  // fetch chat info
  const [chatInfo, setChatInfo] = useState(null);

  const reloadDataSources = async () => {
    try {
      const chatDataResponse = await fetch(`https://www.chatassistant.online/api/documents/${chatId}`).then(response => response.json());
      setRows(chatDataResponse);
      setdataSetsCount(chatDataResponse.length || '0');

      const idList = chatDataResponse.map(item => item.id);
      const newChatDocsIds = idList.join(', ');
      setChatDocsIds(newChatDocsIds);
    } catch (error) {
      console.log("Error fetching data sources:", error);
    }
  };

  const fetchData = async () => {
    try {
      const [chatInfoResponse, chatDataResponse] = await Promise.all([
        fetch(`https://www.chatassistant.online/api/chats/${chatId}`).then(response => response.json()),
        fetch(`https://www.chatassistant.online/api/documents/${chatId}`).then(response => response.json()),
      ]);
      setChatInfo(chatInfoResponse);
      setRows(chatDataResponse);
      setdataSetsCount(chatDataResponse.length || '0');

      const idList = chatDataResponse.map(item => item.id);
      const newChatDocsIds = idList.join(', ');
      setChatDocsIds(newChatDocsIds);
      console.log(newChatDocsIds);
  
      // console.log(chatDocsIds);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("useEffect triggered");
    fetchData();
 
  }, [chatId]); 



  const renderActiveComponent = () => {
    switch (active) {
      case 1:
        return <Settings chatInfo={chatInfo} docIds={chatDocsIds} />;
      case 2:
        return <DataSources rows={rows} dataSetsCount={dataSetsCount} reloadDataSources={reloadDataSources} />;
      case 3:
        return <Share />;
      case 4:
        return <Threads />;
      case 5:
        return <Feedbacks chatId={chatId}/>;
      case 6:
        return <Statistics chatId={chatId}/>; // Add this line
      case 7:
        return <Visitors chatId={chatId}/>; // Add this line
      default:
        return null;
    }
  };

  return <>
    <h3 className='text-2xl leading-5 font-semibold text-gray-900 text-center mb-[15px]'>
      Chatbot detail
    </h3>
    <div className="mb-5">
      <Tab tabs={tabs} active={active} onClick={handleClickTab} />
    </div>
    {/* <Outlet /> */}
    {/* <DataSources rows={rows} /> */}

    {renderActiveComponent()}
  </>
}

export default ChatbotContainer;

