import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { FiEdit3, FiCheck, FiX, FiTrash2, FiRefreshCw, FiUploadCloud, FiPlus } from 'react-icons/fi';

const ImageManager = ({ selectedRowId }) => {
  const [images, setImages] = useState({ processed: [], unprocessed: [] });
  const [uploading, setUploading] = useState(false);
  const [editingImage, setEditingImage] = useState(null);
  const [newDescription, setNewDescription] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [processingImage, setProcessingImage] = useState(null);
  const [autoGenerateDescription, setAutoGenerateDescription] = useState(false);
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false);

  useEffect(() => {
    if (selectedRowId) {
      fetchImages();
    }
  }, [selectedRowId]);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`/api/get_images/${selectedRowId}`);
      const allImages = response.data.images;
      setImages({
        processed: allImages.filter(img => img.is_processed),
        unprocessed: allImages.filter(img => !img.is_processed)
      });
    } catch (error) {
      console.error('Error fetching images:', error);
      notifyError('Failed to fetch images');
    }
  };

  const handleImageUpload = async (file) => {
    if (!file) return;

    setUploading(true);
    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('doc_id', selectedRowId);
      formData.append('process_immediately', autoGenerateDescription);

      if (autoGenerateDescription) {
        const description = await generateImageDescription(file);
        formData.append('description', description);
      }

      await axios.post('/api/upload_image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      fetchImages();
      notifySuccess('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading image:', error);
      notifyError('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = async (vectordb_id) => {
    try {
      await axios.delete(`/api/delete_image/${vectordb_id}`);
      fetchImages();
      notifySuccess('Image deleted successfully');
    } catch (error) {
      console.error('Error deleting image:', error);
      notifyError('Failed to delete image');
    }
  };

  const handleUpdateDescription = async () => {
    if (!editingImage) return;

    try {
      const formData = new FormData();
      formData.append('description', newDescription);
      await axios.put(`/api/update_image_description/${editingImage.vectordb_id}`, formData);

      fetchImages();
      setEditingImage(null);
      setNewDescription('');
      notifySuccess('Description updated successfully');
    } catch (error) {
      console.error('Error updating image description:', error);
      notifyError('Failed to update description');
    }
  };

  const handleProcessImage = async (vectordb_id) => {
    setProcessingImage(vectordb_id);
    try {
      await axios.post(`/api/process_image/${vectordb_id}`);
      fetchImages();
      notifySuccess('Image processed successfully');
    } catch (error) {
      console.error('Error processing image:', error);
      if (error.response && error.response.status === 400) {
        notifyError(error.response.data.detail);
      } else {
        notifyError('Failed to process image');
      }
    } finally {
      setProcessingImage(null);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleImageUpload(e.dataTransfer.files[0]);
    }
  };

  const toggleDescription = (vectorId) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [vectorId]: !prev[vectorId]
    }));
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.3, ease: 'easeOut' } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.2, ease: 'easeIn' } }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, transition: { duration: 0.2 } }
  };

  const generateImageDescription = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('doc_id', selectedRowId);

    try {
      const response = await axios.post('/api/generate_image_description', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data.description;
    } catch (error) {
      console.error('Error generating image description:', error);
      notifyError('Failed to generate image description');
      return '';
    }
  };

  const handleBulkImageUpload = async (files) => {
    setUploading(true);
    const formData = new FormData();
    formData.append('doc_id', selectedRowId);
    
    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }

    try {
      await axios.post('/api/bulk_upload_images', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      fetchImages();
      notifySuccess('Images uploaded successfully');
    } catch (error) {
      console.error('Error uploading images:', error);
      notifyError('Failed to upload images');
    } finally {
      setUploading(false);
    }
  };

  const handleEditImage = (image) => {
    setEditingImage({
      vectordb_id: image.vectordb_id,
      description: image.description
    });
    setNewDescription(image.description);
  };

  const generateAIDescription = async (vectordb_id) => {
    setIsGeneratingDescription(true);
    try {
      const endpoint = `/api/generate_image_description/${vectordb_id}`;
      const response = await axios.post(endpoint);
      setNewDescription(response.data.description);
      toast.success('AI description generated successfully');
    } catch (error) {
      console.error('Error generating AI description:', error);
      toast.error('Failed to generate AI description');
    } finally {
      setIsGeneratingDescription(false);
    }
  };

  const renderImageGrid = (imageList, title) => (
    <div>
      <h3 className="text-2xl font-semibold text-gray-800 mb-4">{title}</h3>
      <motion.div 
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mb-12"
        layout
      >
        <AnimatePresence>
          {imageList.map((image, index) => (
            console.log(image), 
            console.log(image.vector_id), 
            <motion.div
              key={image.vectordb_id}
              className="bg-white rounded-2xl shadow-lg overflow-hidden relative"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
              layout
            >
              <div className="relative aspect-w-16 aspect-h-9">
                <img
                  src={`api/${image.image_path}`}
                  alt={image.description}
                  className="object-cover w-full h-full cursor-pointer"
                  onClick={() => {
                    setLightboxIndex(index);
                    setLightboxOpen(true);
                  }}
                />
                <div className="absolute top-2 right-2 flex space-x-1">
                {!image.is_processed && (
                  <motion.button
                    onClick={() => handleProcessImage(image.vectordb_id)}
                    className={`bg-green-500 text-white p-1.5 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200 ${!image.description.trim() ? 'opacity-50 cursor-not-allowed' : ''}`}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    disabled={!image.description.trim()}
                  >
                    <FiCheck className="w-4 h-4" />
                  </motion.button>
                )}
                  <motion.button
                    onClick={() => handleDeleteImage(image.vectordb_id)}
                    className="bg-red-500 text-white p-1.5 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <FiTrash2 className="w-4 h-4" />
                  </motion.button>
                </div>
              </div>
              <div className="p-6">
                <p className={`text-gray-600 ${expandedDescriptions[image.vectordb_id] ? '' : 'line-clamp-3'}`}>
                  {image.description}
                </p>
                {image.description && image.description.length > 100 && (
                  <button
                    onClick={() => toggleDescription(image.vectordb_id)}
                    className="text-indigo-600 hover:text-indigo-800 transition-colors duration-200 mt-3 font-medium"
                  >
                    {expandedDescriptions[image.vectordb_id] ? 'Show Less' : 'Show More'}
                  </button>
                )}
                <div className="mt-4">
                  <motion.button
                    onClick={() => handleEditImage(image)}
                    className="text-sm bg-indigo-100 text-indigo-600 px-3 py-1 rounded-full hover:bg-indigo-200 transition-colors duration-200"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Edit Description
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    </div>
  );

  // Custom toast functions
  const notifySuccess = (message) => toast.success(message, {
    icon: '✓',
  });

  const notifyError = (message) => toast.error(message, {
    icon: '✕',
  });

  const renderUploadSection = () => (
    <div className="mt-8 bg-white shadow-md rounded-lg border border-gray-200">
      <div className="px-4 py-3 flex items-center justify-between">
        <div className="flex items-center">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e.target.files[0])}
            disabled={uploading}
            className="hidden"
            id="image-upload"
          />
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => handleBulkImageUpload(e.target.files)}
            disabled={uploading}
            className="hidden"
            id="bulk-image-upload"
          />
          <label
            htmlFor="image-upload"
            className="flex items-center cursor-pointer text-sm font-medium text-gray-600 hover:text-gray-800 transition-colors duration-200"
          >
            <FiPlus className="w-5 h-5 mr-2" />
            Add Image
          </label>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="auto-generate"
              checked={autoGenerateDescription}
              onChange={(e) => setAutoGenerateDescription(e.target.checked)}
              className="mr-2 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="auto-generate" className="text-sm text-gray-600">
              Auto-generate description
            </label>
          </div>
          <motion.button
            onClick={() => document.getElementById('bulk-image-upload').click()}
            className="px-3 py-1 bg-indigo-100 text-indigo-600 text-sm rounded-full hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors duration-200 flex items-center"
            disabled={uploading}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FiUploadCloud className="mr-1" />
            {uploading ? 'Uploading...' : 'Bulk Upload'}
          </motion.button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex-1 overflow-y-auto p-8 bg-gradient-to-br from-gray-50 to-gray-100">
      <h2 className="text-4xl font-bold text-gray-800 mb-8 tracking-tight">Document Images</h2>
      
      {renderImageGrid(images.processed, "Processed Images")}
      {renderImageGrid(images.unprocessed, "Unprocessed Images")}

      {renderUploadSection()}

      {/* Edit Description Modal */}
      <AnimatePresence>
        {editingImage && (
          <motion.div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            onClick={() => setEditingImage(null)}
          >
            <motion.div 
              className="bg-white rounded-xl shadow-2xl max-w-2xl w-full overflow-hidden"
              variants={modalVariants}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="p-8">
                <div className="flex justify-between items-center mb-6">
                  <h3 className="text-2xl font-semibold text-gray-900">Edit Description</h3>
                  <button
                    onClick={() => setEditingImage(null)}
                    className="text-gray-400 hover:text-gray-500 transition-colors duration-200"
                  >
                    <FiX className="w-6 h-6" />
                  </button>
                </div>
                <div className="mb-6">
                  <textarea
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                    className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 h-48 resize-none transition-all duration-200 text-gray-700"
                    placeholder="Enter image description..."
                  />
                </div>
                <div className="flex justify-between items-center">
                  <motion.button
                    onClick={() => generateAIDescription(editingImage.vectordb_id)}
                    className="flex items-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-200"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    disabled={isGeneratingDescription}
                  >
                    {isGeneratingDescription ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Generating...
                      </>
                    ) : (
                      <>
                        <FiRefreshCw className="w-5 h-5 mr-2" />
                        Generate AI Description
                      </>
                    )}
                  </motion.button>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => setEditingImage(null)}
                      className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-colors duration-200"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleUpdateDescription}
                      className="flex items-center px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors duration-200"
                    >
                      <FiCheck className="w-5 h-5 mr-2" />
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={[...images.processed, ...images.unprocessed].map(image => ({
          src: `api/${image.image_path}`,
          description: image.description
        }))}
        index={lightboxIndex}
        plugins={[Zoom, Captions]}
      />

      <ToastContainer 
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        toastClassName="bg-white shadow-md rounded-lg overflow-hidden border-l-4 border-gray-300"
        bodyClassName="text-sm font-medium text-gray-800 p-3"
        progressClassName="bg-gray-200"
        closeButton={false}
        icon={false}
        limit={3}
      />
    </div>
  );
};

export default ImageManager;