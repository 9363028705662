import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import { XMarkIcon, TrashIcon, PlusIcon, ArrowPathIcon, EllipsisVerticalIcon, PencilIcon, CheckIcon } from '@heroicons/react/24/outline';
import Tab from 'components/Tab';
import DeleteModal from 'components/DeleteModal';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import { Document, Page, pdfjs } from 'react-pdf';
import ImageManager from '../ImageManager';
import ReactMarkdown from 'react-markdown';
import DocumentInfo from './DocumentInfo';

// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const DataSourceDetails = ({ open, setOpen, selectedRowId }) => {
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [active, setActive] = useState(1);
  const [chats, setChats] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [fullText, setFullText] = useState('');
  const [initialFullText, setInitialFullText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [videoChunks, setVideoChunks] = useState([]);
  const [initialVideoChunks, setInitialVideoChunks] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [isReverting, setIsReverting] = useState(false);
  const [allChats, setAllChats] = useState([]);
  const [selectedChats, setSelectedChats] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [documentInfo, setDocumentInfo] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSavingContent, setIsSavingContent] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);

  const tabs = [
    { id: 1, label: 'Document Info' },
    { id: 2, label: 'Data Content' },
    { id: 3, label: 'Chat Assignments', badge: chats.length.toString() },
    { id: 4, label: 'View Document' },
    { id: 5, label: 'Images' }, // Add this new tab
  ];

  const handleClickTab = (id) => setActive(id);

  useEffect(() => {
    if (selectedRowId && open) {
      fetchDocumentInfo(selectedRowId);
      fetchAllChats();
      fetchChatsOfDoc(selectedRowId);
      fetchFullText(selectedRowId);
    }
  }, [selectedRowId, open]);

  useEffect(() => {
    setFilteredChats(allChats);
  }, [allChats]);

  const fetchDocumentInfo = async (docId) => {
    try {
      const response = await fetch(`https://www.chatassistant.online/api/document_info/${docId}`);
      const data = await response.json();
      setDocumentInfo(data);
    } catch (error) {
      console.log('Error fetching document info:', error);
    }
  };

  const fetchAllChats = async () => {
    try {
      const response = await fetch('https://www.chatassistant.online/api/chats');
      const data = await response.json();
      setAllChats(data);
    } catch (error) {
      console.log('Error fetching all chats:', error);
    }
  };

  const fetchChatsOfDoc = async (docId) => {
    try {
      const response = await fetch(`https://www.chatassistant.online/api/chats_of_doc/${docId}`);
      const data = await response.json();
      setSelectedChats(data["chats"].map(chat => chat.id));
      setChats(data["chats"])
      console.log(selectedChats)
    } catch (error) {
      console.log('Error fetching chats of document:', error);
    }
  };

  const fetchFullText = async (selectedRowId) => {
    try {
      const response = await fetch(`https://www.chatassistant.online/api/get_full_text/${selectedRowId}`);
      const data = await response.json();
      if (data.is_video) {
        setIsVideo(true);
        setVideoChunks(data.video_chunks);
        setInitialVideoChunks(JSON.parse(JSON.stringify(data.video_chunks))); // Deep copy
      } else {
        setIsVideo(false);
        setFullText(data.full_text);
        setInitialFullText(data.full_text);
      }
    } catch (error) {
      console.log('Error fetching full text:', error);
    }
  };

  const updateFullText = async () => {
    try {
      setIsSavingContent(true);
      const response = await fetch(`https://www.chatassistant.online/api/update_full_text/${selectedRowId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          is_video: isVideo,
          video_chunks: isVideo ? videoChunks : null,
          full_text: isVideo ? null : fullText 
        }),
      });
      if (response.ok) {
        setIsEditing(false);
      } else {
        console.log('Error updating full text');
      }
    } catch (error) {
      console.log('Error updating full text:', error);
    } finally {
      setIsSavingContent(false);
    }
  };

  const updateBadgeCount = (count) => {
    const updatedTabs = [...tabs];
    updatedTabs[1].badge = count;
  };

  const handleDelete = async (chatId) => {
    setOpenDeleteModal(true);
    setDeleteId(chatId);
  };

  const handleChunkEdit = useCallback((index, newText) => {
    setVideoChunks(chunks => 
      chunks.map((chunk, i) => 
        i === index ? { ...chunk, text: newText } : chunk
      )
    );
  }, []);

  const handleChunkDelete = useCallback((index) => {
    setVideoChunks(chunks => chunks.filter((_, i) => i !== index));
  }, []);

  const handleChunkAdd = useCallback(() => {
    const lastChunk = videoChunks[videoChunks.length - 1];
    setVideoChunks(chunks => [
      ...chunks, 
      { 
        text: '', 
        start_time: 0, 
        end_time: lastChunk ? lastChunk.end_time : 0 
      }
    ]);
  }, [videoChunks]);

  const handleStartEditing = () => {
    setIsEditing(true);
    // Store the current state as the initial state
    if (isVideo) {
      setInitialVideoChunks(JSON.parse(JSON.stringify(videoChunks)));
    } else {
      setInitialFullText(fullText);
    }
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    // Revert to the initial state
    if (isVideo) {
      setVideoChunks(JSON.parse(JSON.stringify(initialVideoChunks)));
    } else {
      setFullText(initialFullText);
    }
  };

  const handleRevertToOriginal = async () => {
    try {
      setIsReverting(true);
      const response = await fetch(`https://www.chatassistant.online/api/get_original_content/${selectedRowId}`);
      const data = await response.json();
      if (data.is_video) {
        setIsVideo(true);
        setVideoChunks(data.original_video_chunks);
      } else {
        setIsVideo(false);
        setFullText(data.original_full_text);
      }
      setIsReverting(false);
    } catch (error) {
      console.log('Error fetching original content:', error);
      setIsReverting(false);
    }
  };

  const handleChatSelection = useCallback((chatId) => {
    setSelectedChats(prev => 
      prev.includes(chatId) 
        ? prev.filter(id => id !== chatId)
        : [...prev, chatId]
    );
  }, []);

  const handleSaveAssociations = async () => {
    setIsSaving(true);
    try {
      const response = await fetch('https://www.chatassistant.online/api/edit_associations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          center_id: selectedRowId,
          associated_ids: selectedChats.join(','),
          center_type: 'doc',
        }),
      });
      if (response.ok) {
        console.log('Associations updated successfully');
        // Optionally, you can refetch the chats to update the UI
        fetchChatsOfDoc(selectedRowId);
      } else {
        console.log('Error saving associations');
      }
    } catch (error) {
      console.log('Error saving associations:', error);
    }
    setIsSaving(false);
  };

  const chatCols = [
    { label: 'Chatbot Name' },
    { label: 'Statistics' },
    { label: 'Data Sets' },
    { label: 'Status' },
    { label: 'Actions', align: 'right' }
  ];

  const formatNumber = (number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    } else if (number >= 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    } else {
      return number.toString();
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    if (term) {
      const filtered = allChats.filter(chat => 
        chat.name.toLowerCase().includes(term)
      );
      setFilteredChats(filtered);
    } else {
      setFilteredChats(allChats);
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredChats(allChats);
  };

  const renderChatRow = useCallback((chat) => {
    const isSelected = selectedChats.includes(chat.id);
    return (
      <tr 
        key={chat.id} 
        className={`border-b border-gray-100 ${isSelected ? 'bg-indigo-50' : ''} cursor-pointer`}
        onClick={() => handleChatSelection(chat.id)}
      >
        <td className='w-0 px-1 py-5'>
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            checked={isSelected}
            onChange={() => handleChatSelection(chat.id)}
            onClick={(e) => e.stopPropagation()} // Prevent row click when clicking checkbox
          />
        </td>
        <td className='px-1 py-5'>
          <div className='flex items-center'>
            <img 
              className="w-11 h-11 flex-none rounded-full bg-gray-50"
              src={chat.img || "/images/chat-icon.jpg"}
              alt={chat.name}
            />
            <div className="min-w-0 ml-4">
              <span className="font-semibold text-base leading-5 text-gray-900">
                {chat.name}
              </span>
              <div className="mt-1 flex items-center gap-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  Created on {chat.date || 'N/A'}
                </p>
              </div>
            </div>
          </div>
        </td>
        <td className='text-xs text-gray-500 px-1 py-5'>
          {chat.n_chats_started || 0} / Chats started<br />
          {chat.n_messages_asked || 0} / Questions asked
        </td>
        <td className='text-xs text-gray-500 px-1 py-5'>
          {chat.number_of_streams || 0} / Data Sets<br />
          {chat.total_chat_characters ? formatNumber(chat.total_chat_characters) : 0} / Characters
        </td>
        <td className='text-sm text-gray-500 px-1 py-5'>
          <span className={`${chat.status === 1 ? `border-green-200 bg-green-50 text-green-700` : `border-amber-200 bg-amber-50 text-amber-500`} border inline-flex items-center rounded-md px-2 py-1 text-xs leading-4 font-medium`}>
            {chat.status === 1 ? 'Active' : 'Inactive'}
          </span>
        </td>
        <td className='text-sm text-gray-500 px-1 py-5'>
          <div className="flex justify-end relative">
            <Menu as="div" className="relative">
              <Menu.Button className="block text-gray-500">
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-95"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute mt-2 right-0 z-10 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg border border-gray-300">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => navigate(`/chatbot/${chat.id}/settings`)}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block px-4 py-2 text-sm text-gray-700 cursor-pointer`}
                      >
                        Edit Chatbot
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </td>
      </tr>
    );
  }, [selectedChats, handleChatSelection]);

  const handleSave = async (field, value) => {
    try {
      const response = await axios.patch(`/api/update_document_info/${selectedRowId}`, {
        [field]: value
      });
      setDocumentInfo(response.data);
      toast.success('Saved', {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'minimal-toast'
      });
    } catch (error) {
      console.error('Error updating document info:', error);
      toast.error('Failed to save', {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'minimal-toast'
      });
    }
  };

  const EditableField = ({ label, field, value, type = 'input' }) => {
    const [localValue, setLocalValue] = useState(value);
    const textareaRef = useRef(null);

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    useEffect(() => {
      if (type === 'textarea' && textareaRef.current) {
        adjustTextareaHeight();
      }
    }, [localValue, type]);

    const adjustTextareaHeight = () => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    const handleChange = (e) => {
      setLocalValue(e.target.value);
      if (type === 'textarea') {
        adjustTextareaHeight();
      }
    };

    const handleBlur = () => {
      if (localValue !== value) {
        handleSave(field, localValue);
      }
    };

    return (
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
        <div className="relative">
          {type === 'textarea' ? (
            <textarea
              ref={textareaRef}
              className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ 
                resize: 'none',
                overflow: 'hidden',
                whiteSpace: 'pre-line'
              }}
            />
          ) : type === 'select' ? (
            <select
              className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
              value={localValue}
              onChange={(e) => {
                handleChange(e);
                handleSave(field, e.target.value);
              }}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
              <option value="restricted">Restricted</option>
            </select>
          ) : (
            <input
              type="text"
              className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ whiteSpace: 'pre-line' }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderKeywords = (keywords) => {
    const keywordArray = keywords.split(',').map(k => k.trim());
    const colors = ['bg-blue-100 text-blue-800', 'bg-green-100 text-green-800', 'bg-yellow-100 text-yellow-800', 'bg-red-100 text-red-800', 'bg-indigo-100 text-indigo-800', 'bg-purple-100 text-purple-800', 'bg-pink-100 text-pink-800'];
    
    return (
      <div className="flex flex-wrap gap-2 mt-2">
        {keywordArray.map((keyword, index) => (
          <span key={index} className={`px-2 py-1 rounded-full text-xs font-medium ${colors[index % colors.length]}`}>
            {keyword}
          </span>
        ))}
      </div>
    );
  };

  const renderDocument = () => {
    if (!documentInfo) {
      console.log('No document info available');
      return <p>No document information available</p>;
    }
  
    const { dataType, file_path } = documentInfo;
    console.log('Rendering document:', { dataType, file_path });
  
    if (dataType === 'YT') {
      return (
        <div className="w-full h-0 pb-[56.25%] relative">
          <ReactPlayer
            url={`/api/${file_path}`}
            width="100%"
            height="100%"
            controls
            className="absolute top-0 left-0"
          />
        </div>
      );
    } else if (dataType === 'PDF') {
      return (
        <div className="pdf-container" style={{ height: '80vh', width: '100%' }}>
          <iframe
            src={`/api/${file_path}`}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            title="PDF Viewer"
          />
        </div>
      );
    } else {
      console.log('Unsupported file type:', dataType);
      return <p>Unsupported file type: {dataType}</p>;
    }
  };

  const renderContent = () => {
    if (isVideo) {
      return (
        <div className="space-y-6">
          {videoChunks.map((chunk, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg">
              <div className="bg-gray-50 px-4 py-2 flex justify-between items-center">
                <span className="text-sm font-medium text-gray-700">
                  {`${chunk.start_time.toFixed(2)}s - ${chunk.end_time.toFixed(2)}s`}
                </span>
                {isEditing && (
                  <button
                    onClick={() => handleChunkDelete(index)}
                    className="text-red-500 hover:text-red-700 transition-colors duration-200"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                )}
              </div>
              <div className="p-4">
                {isEditing ? (
                  <textarea
                    value={chunk.text}
                    onChange={(e) => handleChunkEdit(index, e.target.value)}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200 ease-in-out"
                    rows="3"
                    style={{ whiteSpace: 'pre-line' }}
                  />
                ) : (
                  <ReactMarkdown className="prose max-w-none">
                    {chunk.text}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          ))}
          {isEditing && (
            <button
              onClick={handleChunkAdd}
              className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-200 flex items-center justify-center w-full"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add Chunk
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className="w-full h-full border border-gray-300 rounded-lg overflow-hidden shadow-sm">
          {isEditing ? (
            <textarea
              value={fullText}
              onChange={(e) => setFullText(e.target.value)}
              className="w-full h-full p-4 resize-none focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm"
              style={{ whiteSpace: 'pre-line' }}
            />
          ) : (
            <div className="w-full h-full overflow-auto bg-white p-6">
              <ReactMarkdown className="prose max-w-none">
                {fullText}
              </ReactMarkdown>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-4xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6 py-6 bg-gray-50 border-b border-gray-200">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Data Source Details
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 relative">
                    <div className="absolute inset-0 px-4 sm:px-6 py-6">
                      <div className="h-full flex flex-col">
                        <div className="mb-5">
                          <Tab tabs={tabs} active={active} onClick={handleClickTab} />
                        </div>
                        {active === 1 && (
                          <DocumentInfo 
                            documentInfo={documentInfo} 
                            setDocumentInfo={setDocumentInfo} 
                            selectedRowId={selectedRowId}  // Pass selectedRowId to DocumentInfo
                          />
                        )}
                        {active === 2 && (
                          <div className="flex-1 flex flex-col pb-6">
                            <div className="flex-1 relative mb-6">
                              {renderContent()}
                            </div>
                            <div className="px-4 sm:px-6">
                              <div className="flex justify-between items-center space-x-3">
                                {isEditing ? (
                                  <>
                                    <button
                                      onClick={updateFullText}
                                      disabled={isSavingContent}
                                      className="inline-flex justify-center items-center rounded-md bg-indigo-600 px-3 py-2 text-sm leading-5 font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                                    >
                                      {isSavingContent ? (
                                        <ArrowPathIcon className="animate-spin h-5 w-5 mr-2" />
                                      ) : (
                                        <CheckIcon className="h-5 w-5 mr-2" />
                                      )}
                                      {isSavingContent ? 'Saving...' : 'Save Changes'}
                                    </button>
                                    <button
                                      onClick={handleCancelEditing}
                                      className="inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm leading-5 font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                                    >
                                      <XMarkIcon className="h-5 w-5 mr-2" />
                                      Cancel
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    onClick={handleStartEditing}
                                    className="inline-flex justify-center items-center rounded-md bg-indigo-600 px-3 py-2 text-sm leading-5 font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                                  >
                                    <PencilIcon className="h-5 w-5 mr-2" />
                                    Edit Content
                                  </button>
                                )}
                                <button
                                  onClick={handleRevertToOriginal}
                                  disabled={isReverting}
                                  className="inline-flex justify-center items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm leading-5 font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
                                >
                                  {isReverting ? (
                                    <ArrowPathIcon className="animate-spin h-5 w-5 mr-2" />
                                  ) : (
                                    <ArrowPathIcon className="h-5 w-5 mr-2" />
                                  )}
                                  Revert to Original
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {active === 3 && (
                          <div className="flex-1 overflow-y-auto px-4 sm:px-6 py-6">
                            <div className="mb-6">
                              <h2 className="text-lg font-medium text-gray-900">Chat Assignments</h2>
                              <p className="mt-1 text-sm text-gray-500">Manage the chatbots associated with this data source.</p>
                            </div>
                            <div className='flex flex-col lg:flex-row justify-between items-center mb-6'>
                              <div className='flex items-center w-full lg:w-auto mb-4 lg:mb-0'>
                                <input
                                  type="text"
                                  placeholder='Search chats...'
                                  className="lg:mb-0 w-full lg:w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-3"
                                  value={searchTerm}
                                  onChange={handleSearch}
                                />
                                {searchTerm && (
                                  <button
                                    onClick={clearSearch}
                                    className="text-gray-500 hover:text-gray-700 mr-3"
                                  >
                                    <XMarkIcon className="h-5 w-5" />
                                  </button>
                                )}
                              </div>
                              <button
                                onClick={handleSaveAssociations}
                                disabled={isSaving || !!searchTerm}
                                className={`inline-flex justify-center items-center rounded-md px-3 py-2 text-sm leading-5 font-semibold text-white transition-colors duration-200 ${
                                  isSaving || searchTerm
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                }`}
                              >
                                {isSaving ? (
                                  <ArrowPathIcon className="animate-spin h-5 w-5 mr-2" />
                                ) : (
                                  <CheckIcon className="h-5 w-5 mr-2" />
                                )}
                                {isSaving ? 'Saving...' : 'Save Assignments'}
                              </button>
                            </div>
                            {searchTerm && (
                              <div className="mb-4 text-sm text-yellow-600 bg-yellow-100 p-3 rounded-md">
                                Please clear the search to apply changes. This ensures all selections are accounted for.
                              </div>
                            )}
                            <div className="overflow-x-auto">
                              <div className="align-middle inline-block min-w-full">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                      <tr>
                                        <th scope="col" className="w-0 px-1 py-3"></th>
                                        {chatCols.map((col, index) => (
                                          <th
                                            key={index}
                                            scope="col"
                                            className={`px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${col.align ? `text-${col.align}` : ''}`}
                                          >
                                            {col.label}
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      {filteredChats.map(renderChatRow)}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            {/* Remove the button from here */}
                            {/* <div className="mt-6 flex justify-end">
                              ... (button code)
                            </div> */}
                          </div>
                        )}
                        {active === 4 && (
                          <div className="flex-1 overflow-y-auto">
                            <div className="mb-4 flex justify-end space-x-2">
                              <a
                                href={`/api/${documentInfo.file_path}`}
                                download
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Download
                              </a>
                              <a
                                href={`/api/${documentInfo.file_path}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Open in New Window
                              </a>
                            </div>
                            {renderDocument()}
                          </div>
                        )}
                        {active === 5 && (
                          <ImageManager selectedRowId={selectedRowId} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>

    </Transition.Root>
  );
};

const InfoItem = ({ label, value }) => (
  <div>
    <dt className="text-sm font-medium text-gray-500 mb-1">{label}</dt>
    <dd className="text-sm font-semibold text-gray-900">{value}</dd>
  </div>
);

export default DataSourceDetails;