import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ChatbotDrawer from 'components/Drawer/ChatbotDrawer';
import StaffThreadView from 'components/StaffThreadView';

const HumanInterventionRequests = () => {
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [action, setAction] = useState('');
  const [response, setResponse] = useState('');
  const [lastEventTime, setLastEventTime] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [selectedAssistantId, setSelectedAssistantId] = useState(null);
  const [staffViewOpen, setStaffViewOpen] = useState(false);

  useEffect(() => {
    console.log('Component mounted, setting up EventSource');
    const eventSource = new EventSource('https://www.chatassistant.online/api/sse/human-intervention-requests');

    eventSource.onopen = (event) => {
      console.log('SSE connection opened:', event);
    };

    eventSource.onmessage = (event) => {
      console.log('Raw SSE message received:', event);
      setLastEventTime(new Date().toISOString());
      try {
        const newRequests = JSON.parse(event.data);
        console.log('Parsed new requests:', newRequests);
        if (Array.isArray(newRequests)) {
          setRequests(newRequests);
        } else {
          console.error('Received data is not an array:', newRequests);
        }
      } catch (error) {
        console.error('Error parsing SSE data:', error);
      }
    };

    eventSource.addEventListener('new_requests', (event) => {
      console.log('New requests event received:', event);
      setLastEventTime(new Date().toISOString());
      try {
        const newRequests = JSON.parse(event.data);
        console.log('Parsed new requests from event:', newRequests);
        if (Array.isArray(newRequests)) {
          setRequests(newRequests);
        } else {
          console.error('Received data is not an array:', newRequests);
        }
      } catch (error) {
        console.error('Error parsing SSE data from event:', error);
      }
    });

    eventSource.onerror = (error) => {
      console.error('Error in SSE connection:', error);
      eventSource.close();
    };

    return () => {
      console.log('Component unmounting, closing EventSource');
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    console.log('Requests state updated:', requests);
  }, [requests]);

  const handleAction = async (requestId, actionType) => {
    setSelectedRequest(requestId);
    setAction(actionType);
  };

  const submitAction = async () => {
    try {
      const res = await fetch('https://www.chatassistant.online/api/handle-human-intervention', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          request_id: selectedRequest,
          action: action,
          response: response
        })
      });
      const data = await res.json();
      if (data.success) {
        toast.success(data.message);
        setSelectedRequest(null);
        setAction('');
        setResponse('');
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error handling request:', error);
      toast.error('Failed to handle request');
    }
  };

  const handleViewThread = (threadId, assistantId) => {
    setSelectedThreadId(threadId);
    setSelectedAssistantId(assistantId);
    setStaffViewOpen(true);
  };

  const handleCloseStaffView = () => {
    setStaffViewOpen(false);
    setSelectedThreadId(null);
    setSelectedAssistantId(null);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Human Intervention Requests</h1>
      <p>Last event received: {lastEventTime || 'No events received yet'}</p>
      {requests.length === 0 ? (
        <p>No requests</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2">Thread ID</th>
                <th className="px-4 py-2">Assistant ID</th>
                <th className="px-4 py-2">Reason</th>
                <th className="px-4 py-2">Created At</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((request) => (
                <tr key={request.id}>
                  <td className="border px-4 py-2">{request.thread_id}</td>
                  <td className="border px-4 py-2">{request.assistant_id}</td>
                  <td className="border px-4 py-2">{request.reason}</td>
                  <td className="border px-4 py-2">{new Date(request.created_at).toLocaleString()}</td>
                  <td className="border px-4 py-2">{request.status}</td>
                  <td className="border px-4 py-2">
                    <button 
                      onClick={() => handleViewThread(request.thread_id, request.assistant_id)} 
                      className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                    >
                      View Thread
                    </button>
                    {request.status === 'pending' && (
                      <>
                        <button onClick={() => handleAction(request.id, 'resolve')} className="bg-green-500 text-white px-2 py-1 rounded mr-2">
                          Resolve
                        </button>
                        <button onClick={() => handleAction(request.id, 'escalate')} className="bg-yellow-500 text-white px-2 py-1 rounded">
                          Escalate
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {selectedRequest && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg font-bold">{action === 'resolve' ? 'Resolve' : 'Escalate'} Request</h3>
            <textarea
              className="w-full h-32 p-2 border rounded mt-2"
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              placeholder={action === 'resolve' ? 'Enter resolution details...' : 'Enter escalation notes...'}
            ></textarea>
            <div className="mt-3">
              <button onClick={submitAction} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">
                Submit
              </button>
              <button onClick={() => setSelectedRequest(null)} className="bg-gray-300 px-4 py-2 rounded">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <StaffThreadView 
        open={staffViewOpen}
        onClose={handleCloseStaffView}
        threadId={selectedThreadId}
        assistantId={selectedAssistantId}
      />
    </div>
  );
};

export default HumanInterventionRequests;
