import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ requiredRole }) => {
    const isAuthenticated = localStorage.getItem('access_token') !== null;
    const userInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
    const userRole = userInfo.role;

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if (requiredRole) {
        if (requiredRole === 'admin' && userRole !== 'admin') {
            return <Navigate to="/" replace />;
        }
        if (requiredRole === 'staff' && userRole !== 'admin' && userRole !== 'staff') {
            return <Navigate to="/" replace />;
        }
    }

    return <Outlet />;
};

export default PrivateRoute;