import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/solid';

const FeedbackModal = ({ isOpen, onClose, title, feedbackText, setFeedbackText, additionalInfo, setAdditionalInfo, onSubmit, chatColor }) => {
    return (
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
          <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
  
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
  
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5"> 
                    <Dialog.Title as="h3" className="text-xl leading-6 font-semibold text-gray-900 mb-4">{title}</Dialog.Title>
                    <div className="mt-2">
                    <textarea 
                      className="form-control w-full h-32 p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none" 
                      placeholder={`What did you ${title.toLowerCase()} about this response?`} 
                      value={feedbackText}
                      onChange={(e) => setFeedbackText(e.target.value)}
                    />
                      {title === 'Dislike' && (
                        <div className="mt-4 flex justify-between items-center">
                          {['Not useful', 'Not clear', 'Not true'].map((option) => (
                            <div key={option} className="flex items-center">
                              <input 
                                type="checkbox" 
                                id={option.replace(' ', '')}
                                checked={additionalInfo[option.replace(' ', '').toLowerCase()]}
                                onChange={() => setAdditionalInfo(prev => ({...prev, [option.replace(' ', '').toLowerCase()]: !prev[option.replace(' ', '').toLowerCase()]}))}
                                className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label htmlFor={option.replace(' ', '')} className="ml-2 text-base text-gray-700">{option}</label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50 sm:text-sm transition-all duration-200"
                    style={{ backgroundColor: chatColor || '#3B82F6' }}
                    onClick={onSubmit}
                  >
                    Submit Feedback
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

export const FeedbackSystem = ({ chatId, histories, modalState, setModalState, feedbackText, setFeedbackText, additionalInfo, setAdditionalInfo, submitFeedback, chatColor }) => {
    const closeFeedbackModal = () => {
        setModalState({ isOpen: false, type: null, messageId: null });
    };

    return (
        <FeedbackModal
            isOpen={modalState.isOpen}
            onClose={closeFeedbackModal}
            title={modalState.type === 'like' ? 'Like' : 'Dislike'}
            feedbackText={feedbackText}
            setFeedbackText={setFeedbackText}
            additionalInfo={additionalInfo}
            setAdditionalInfo={setAdditionalInfo}
            onSubmit={submitFeedback}
            chatColor={chatColor}
        />
    );
};

export const LikeButton = ({ messageId, showFeedbackModal, isPreview }) => (
  <button 
    className={`p-1 rounded-full transition-colors duration-200 ${isPreview ? 'cursor-default' : 'hover:bg-gray-200'}`}
    onClick={() => !isPreview && showFeedbackModal('like', messageId)}
    disabled={isPreview}
  >
    <HandThumbUpIcon className={`h-5 w-5 text-gray-500 hover:text-green-500`} />
  </button>
);

export const DislikeButton = ({ messageId, showFeedbackModal, isPreview }) => (
  <button 
    className={`p-1 rounded-full transition-colors duration-200 ${isPreview ? 'cursor-default' : 'hover:bg-gray-200'}`}
    onClick={() => !isPreview && showFeedbackModal('dislike', messageId)}
    disabled={isPreview}
  >
    <HandThumbDownIcon className={`h-5 w-5 text-gray-500 hover:text-red-500`} />
  </button>
);