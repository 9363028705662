import React, { useState, useEffect } from 'react';
import Card from 'components/Card';
import { useParams } from 'react-router-dom';
import { QrCodeIcon, LinkIcon, DocumentDuplicateIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

const Share = () => {
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [iframeCode, setIframeCode] = useState('');
  const [copyAnimation, setCopyAnimation] = useState({ link: false, code: false });
  const { chatId } = useParams(); 

  useEffect(() => {
    const fetchQrCodeImage = async () => {
      try {
        const response = await fetch(`https://www.chatassistant.online/api/generate_qrcode?link=c/${chatId}&box_size=10&border=4`);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setQrCodeImage(url);
      } catch (error) {
        console.error('Error fetching QR code image:', error);
      }
    };

    fetchQrCodeImage();
    setIframeCode(`<iframe src="https://www.chatassistant.online/c/${chatId}" style="width:100%; height:400px; border:none;"></iframe>`);
  }, [chatId]);

  const handleDownload = () => {
    if (qrCodeImage) {
      const downloadLink = document.createElement('a');
      downloadLink.href = qrCodeImage;
      downloadLink.download = 'qr_code.png';
      downloadLink.click();
    }
  };

  const handleCopy = (type) => {
    const content = type === 'link' 
      ? `https://www.chatassistant.online/chat/${chatId}`
      : iframeCode;
    
    navigator.clipboard.writeText(content).then(() => {
      animateCopyButton(type);
    });
  };

  const animateCopyButton = (type) => {
    setCopyAnimation(prev => ({ ...prev, [type]: true }));
    setTimeout(() => setCopyAnimation(prev => ({ ...prev, [type]: false })), 2000);
  };

  const ShareOption = ({ icon: Icon, title, content, actionText, onAction, copied }) => (
    <div className="bg-white shadow sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
          <Icon className="h-6 w-6 text-indigo-600 mr-2" />
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{content}</p>
        </div>
        <div className="mt-5">
          <button
            type="button"
            onClick={onAction}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {copied ? 'Copied!' : actionText}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <Card>
      <div className="space-y-8">
        <h3 className='text-3xl font-bold text-gray-900 text-center mb-8'>
          Share Your Chatbot
        </h3>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="flex flex-col items-center bg-white shadow sm:rounded-lg p-6">
            <QrCodeIcon className="h-12 w-12 text-indigo-600 mb-4" />
            <img src={qrCodeImage} alt="QR Code" className="w-48 h-48 mb-4" />
            <button
              onClick={handleDownload}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Download QR Code
            </button>
          </div>

          <div className="space-y-6">
            <ShareOption
              icon={LinkIcon}
              title="Share Link"
              content={`https://www.chatassistant.online/chat/${chatId}`}
              actionText="Copy Link"
              onAction={() => handleCopy('link')}
              copied={copyAnimation.link}
            />

            <ShareOption
              icon={DocumentDuplicateIcon}
              title="Embed Code"
              content="Copy and paste this code to embed the chatbot on your website."
              actionText="Copy Code"
              onAction={() => handleCopy('code')}
              copied={copyAnimation.code}
            />

            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
                  <ArrowTopRightOnSquareIcon className="h-6 w-6 text-indigo-600 mr-2" />
                  Open Chat
                </h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Open the chatbot in a new window.</p>
                </div>
                <div className="mt-5">
                  <a
                    href={`https://www.chatassistant.online/chat/${chatId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Open Chat
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Share;