import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from 'logo.png';
import { logout } from '../../utils/auth';
import { Fragment } from 'react';

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const defaultImageUrl = 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';
  useEffect(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('user_info'));
    setUserInfo(storedUserInfo);
  }, []);

  const handleLogout = () => {
    logout();
    setUserInfo(null);
    navigate('/');
  };

  const navigation = userInfo ? [
    ...(userInfo.role === 'admin' ? [
      { name: 'Create Chatbot', href: '/create' },
      { name: 'My Chatbots', href: '/chatbots' },
      { name: 'Data Sets', href: '/data-source' },
      { name: 'Users', href: '/user-management' },
      { name: 'Escalations', href: '/human-intervention-requests' },
    ] : []),
    ...(userInfo.role === 'staff' ? [
      { name: 'Data Sets', href: '/data-source' },
      { name: 'Escalations', href: '/human-intervention-requests' },
    ] : []),
  ] : [];

  const userNavigation = userInfo
    ? [
        { name: 'Your Profile', href: '/' },
        { name: 'Settings', href: '/' },
        { name: 'Sign out', onClick: handleLogout },
      ]
    : [
        { name: 'Sign in', href: '/login' },
      ];

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-white">
        {({ open }) => (
          <>
            <div className={`mx-auto max-w-[1136px] px-4 sm:px-6 lg:px-8`}>
              <div className="flex h-16 items-center justify-between">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img
                      className="h-8"
                      src={Logo}
                      alt="Chat0"
                    />
                  </Link>
                </div>
                {userInfo && (
                  <div className="hidden md:block flex-grow">
                    <div className="flex items-center justify-center space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            pathname === item.href
                              ? 'bg-gray-900 text-white'
                              : `text-gray-700 hover:bg-gray-700 hover:text-white`,
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={pathname === item.href ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {userInfo ? (
                      <>
                        <span className="text-gray-700 mr-4 text-sm font-medium">{userInfo.username}</span>
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              <img className="h-8 w-8 rounded-full" src={userInfo?.imageUrl || defaultImageUrl} alt="" />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    item.onClick ? (
                                      <button
                                        onClick={item.onClick}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                        )}
                                      >
                                        {item.name}
                                      </button>
                                    ) : (
                                      <Link
                                        to={item.href}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'block px-4 py-2 text-sm text-gray-700'
                                        )}
                                      >
                                        {item.name}
                                      </Link>
                                    )
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </>
                    ) : (
                      <Link
                        to="/login"
                        className="text-gray-700 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      >
                        Sign in
                      </Link>
                    )}
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              {userInfo ? (
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.href === pathname ? 'bg-gray-900 text-white' : `text-gray-700 hover:bg-gray-700 hover:text-white`,
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.href === pathname ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              ) : (
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <Link
                    to="/login"
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white"
                  >
                    Sign in
                  </Link>
                </div>
              )}
              <div className="border-t border-gray-700 pb-3 pt-4">
                {userInfo ? (
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={userInfo?.imageUrl || defaultImageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className={`text-base font-medium leading-none text-gray-700`}>
                        {userInfo?.username || 'Guest'}
                      </div>
                      <div className={`text-sm font-medium leading-none text-gray-700`}>
                        {userInfo?.email || 'Unknown'}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="px-5">
                    <Link
                      to="/login"
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white"
                    >
                      Sign in
                    </Link>
                  </div>
                )}
                {userInfo && (
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        onClick={item.onClick}
                        className={`block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white`}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Header;
