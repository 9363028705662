import { jsPDF } from "jspdf";

export const saveChatAsPDF = (histories, chatId) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 15;
  const maxWidth = pageWidth - 2 * margin;

  // Set up fonts
  doc.addFont('https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf', 'Roboto', 'normal');
  doc.addFont('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', 'Roboto', 'bold');
  doc.setFont("Roboto");

  // Add header
  doc.setFillColor(52, 152, 219); // A modern blue color
  doc.rect(0, 0, pageWidth, 40, 'F');
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(24);
  doc.setFont("Roboto", "bold");
  doc.text("Conversation Transcript", pageWidth / 2, 28, { align: "center" });

  // Add chat ID and date
  doc.setTextColor(60, 60, 60); // Set text color to dark gray
  doc.setFontSize(10);
  doc.setFont("Roboto", "normal");
  doc.text(`Transcript ID: ${chatId}`, margin, 50);
  doc.text(`Date: ${new Date().toLocaleString()}`, pageWidth - margin, 50, { align: "right" });

  let yPosition = 70;

  histories.slice(1).forEach((item, index) => {
    const isUser = !item.chatbot;
    const speaker = isUser ? "Human" : "AI Assistant";
    const message = item.data.html;

    // Add speaker
    doc.setFontSize(12);
    doc.setFont("Roboto", "bold");
    doc.setTextColor(isUser ? 52 : 46, isUser ? 152 : 204, isUser ? 219 : 113);
    doc.text(`${speaker}:`, margin, yPosition);
    yPosition += 7;

    // Add message
    doc.setFont("Roboto", "normal");
    doc.setFontSize(11);
    doc.setTextColor(60, 60, 60);
    const textLines = doc.splitTextToSize(message, maxWidth - 10);
    
    // Draw a light background for the message
    const messageHeight = textLines.length * 6 + 6;
    doc.setFillColor(isUser ? 235 : 240, isUser ? 245 : 253, isUser ? 251 : 245);
    doc.roundedRect(margin, yPosition - 3, maxWidth, messageHeight, 3, 3, 'F');

    textLines.forEach((line) => {
      doc.text(line, margin + 5, yPosition);
      yPosition += 6;
    });

    yPosition += 15; // Add some space between messages

    // Add a new page if needed
    if (yPosition > pageHeight - 30) {
      doc.addPage();
      yPosition = 20;
    }
  });

  // Add page numbers
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFont("Roboto", "normal");
    doc.setFontSize(10);
    doc.setTextColor(150, 150, 150);
    doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2, pageHeight - 10, { align: "center" });
  }

  // Save the PDF
  doc.save(`conversation_transcript_${chatId}.pdf`);
};