import React, { useState, useEffect } from 'react';
import { Line, Bar, Doughnut, Radar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { FiFileText, FiMessageSquare, FiBook, FiUsers, FiClock, FiTrendingUp } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import Card from 'components/Card';
import { Tab } from '@headlessui/react';

ChartJS.register(...registerables);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statisticsModes = [
  { value: 'chat_activity', label: 'Chat Activity', color: 'bg-blue-100 text-blue-800' },
  { value: 'document_types', label: 'Document Types', color: 'bg-green-100 text-green-800' },
  { value: 'knowledge_base', label: 'Knowledge Base', color: 'bg-purple-100 text-purple-800' },
];

function Statistics({ chatId }) {  // Accept chatId as a prop
  const [documentStats, setDocumentStats] = useState(null);
  const [chatActivity, setChatActivity] = useState(null);
  const [knowledgeBaseUsage, setKnowledgeBaseUsage] = useState(null);
  const [userEngagement, setUserEngagement] = useState(null);
  // New state variables for additional endpoints
  const [documentUploadTrends, setDocumentUploadTrends] = useState(null);
  const [languageDistribution, setLanguageDistribution] = useState(null);
  const [processingStatus, setProcessingStatus] = useState(null);
  const [assistantPopularity, setAssistantPopularity] = useState(null);
  const [knowledgeBaseOverview, setKnowledgeBaseOverview] = useState(null);
  const [userRetention, setUserRetention] = useState(null);
  const [documentTypeDistribution, setDocumentTypeDistribution] = useState(null);
  const [assistantUsageByTime, setAssistantUsageByTime] = useState(null);
  const [chatSessionDuration, setChatSessionDuration] = useState(null);

  const [mode, setMode] = useState(statisticsModes[0]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const [
          docStats,
          chatStats,
          kbUsage,
          engagementStats,
          uploadTrends,
          langDist,
          procStatus,
          assistantPop,
          kbOverview,
          retention,
          docTypeDist,
          usageByTime,
          sessionDuration
        ] = await Promise.all([
          fetch('/api/statistics/document_stats').then(res => res.json()),
          fetch(`/api/statistics/chat_activity/${chatId}`).then(res => res.json()),
          fetch(`/api/statistics/knowledge_base_usage/${chatId}`).then(res => res.json()),
          fetch('/api/statistics/user_engagement').then(res => res.json()),
          fetch('/api/statistics/document_upload_trends').then(res => res.json()),
          fetch('/api/statistics/docs_language_distribution').then(res => res.json()),
          fetch('/api/statistics/document_processing_status').then(res => res.json()),
          fetch('/api/statistics/assistant_popularity').then(res => res.json()),
          fetch('/api/statistics/knowledge_base_overview').then(res => res.json()),
          fetch('/api/statistics/user_retention').then(res => res.json()),
          fetch('/api/statistics/document_type_distribution').then(res => res.json()),
          fetch('/api/statistics/assistant_usage_by_time').then(res => res.json()),
          fetch('/api/statistics/chat_session_duration').then(res => res.json())
        ]);
        setDocumentStats(docStats);
        setChatActivity(chatStats);
        setKnowledgeBaseUsage(kbUsage);
        setUserEngagement(engagementStats);
        setDocumentUploadTrends(uploadTrends);
        setLanguageDistribution(langDist);
        setProcessingStatus(procStatus);
        setAssistantPopularity(assistantPop);
        setKnowledgeBaseOverview(kbOverview);
        setUserRetention(retention);
        setDocumentTypeDistribution(docTypeDist);
        setAssistantUsageByTime(usageByTime);
        setChatSessionDuration(sessionDuration);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    if (chatId) {
      fetchStats();
    }
  }, [chatId]);

  // Updated color palette
  const colors = {
    primary: 'rgba(79, 70, 229, 1)', // Indigo
    primaryLight: 'rgba(99, 102, 241, 0.2)', // Light Indigo for background
    secondary: 'rgba(245, 158, 11, 1)', // Amber
    tertiary: 'rgba(236, 72, 153, 1)', // Pink
    quaternary: 'rgba(59, 130, 246, 1)', // Blue
    background: 'rgba(243, 244, 246, 1)', // Light gray
    text: 'rgba(31, 41, 55, 1)', // Dark gray
  };

  // Updated chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            family: "'Inter', sans-serif",
            weight: 500,
          },
          color: colors.text,
        },
      },
      tooltip: {
        backgroundColor: colors.background,
        titleColor: colors.primary,
        bodyColor: colors.text,
        borderColor: colors.primary,
        borderWidth: 1,
        cornerRadius: 8,
        titleFont: {
          family: "'Inter', sans-serif",
          weight: 600,
        },
        bodyFont: {
          family: "'Inter', sans-serif",
          weight: 400,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "'Inter', sans-serif",
            weight: 500,
          },
          color: colors.text,
        },
      },
      y: {
        grid: {
          color: colors.primaryLight,
        },
        ticks: {
          font: {
            family: "'Inter', sans-serif",
            weight: 500,
          },
          color: colors.text,
        },
      },
    },
  };

  // Updated data preparation (example for chatActivityData)
  const chatActivityData = {
    labels: chatActivity?.daily_chat_activity.map(day => day.date) || [],
    datasets: [{
      label: 'Chats',
      data: chatActivity?.daily_chat_activity.map(day => day.count) || [],
      borderColor: colors.primary,
      backgroundColor: colors.primaryLight,
      tension: 0.4,
      fill: true,
      pointBackgroundColor: colors.primary,
      pointBorderColor: '#fff',
      pointBorderWidth: 2,
      pointRadius: 4,
      pointHoverRadius: 6,
    }]
  };

  const documentTypesData = {
    labels: Object.keys(documentStats?.document_types || {}),
    datasets: [{
      data: Object.values(documentStats?.document_types || {}),
      backgroundColor: [
        colors.primary,
        colors.secondary,
        colors.tertiary,
        colors.quaternary,
        'rgba(124, 58, 237, 0.8)', // Additional color (purple)
      ],
    }]
  };

  const knowledgeBaseUsageData = {
    labels: knowledgeBaseUsage?.document_usage.slice(0, 5).map(doc => doc.name) || [],
    datasets: [{
      label: 'Usage Count',
      data: knowledgeBaseUsage?.document_usage.slice(0, 5).map(doc => doc.total_usage_count) || [],
      backgroundColor: 'rgba(75, 192, 192, 0.8)',
    }]
  };

  const charactersPerDocData = {
    labels: ['Average', 'Total'],
    datasets: [{
      label: 'Characters',
      data: [documentStats?.average_characters_per_document, documentStats?.total_characters],
      backgroundColor: ['rgba(54, 162, 235, 0.8)', 'rgba(255, 99, 132, 0.8)'],
    }]
  };

  const userEngagementData = {
    labels: userEngagement?.daily_user_engagement.map(day => day.date) || [],
    datasets: [
      {
        label: 'Message Count',
        data: userEngagement?.daily_user_engagement.map(day => day.message_count) || [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Unique Users',
        data: userEngagement?.daily_user_engagement.map(day => day.unique_users) || [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y-axis-2',
      }
    ]
  };

  // New data preparation for additional endpoints
  const documentUploadTrendsData = {
    labels: documentUploadTrends?.daily_document_uploads.map(day => day.date) || [],
    datasets: [{
      label: 'Upload Count',
      data: documentUploadTrends?.daily_document_uploads.map(day => day.upload_count) || [],
      borderColor: colors.primary,
      backgroundColor: colors.primaryLight,
      tension: 0.1,
      fill: true,
    }]
  };

  const languageDistributionData = {
    labels: languageDistribution?.chat_language_distribution.map(item => item.language) || [],
    datasets: [{
      label: 'Document Count',
      data: languageDistribution?.chat_language_distribution.map(item => item.docs_count) || [],
      backgroundColor: [colors.primary, colors.secondary, colors.tertiary, colors.quaternary],
    }]
  };

  const processingStatusData = {
    labels: Object.keys(processingStatus?.document_processing_status || {}),
    datasets: [{
      data: Object.values(processingStatus?.document_processing_status || {}),
      backgroundColor: [colors.primary, colors.secondary, colors.tertiary, colors.quaternary],
    }]
  };

  const assistantPopularityData = {
    labels: assistantPopularity?.assistant_popularity.slice(0, 5).map(item => item.name) || [],
    datasets: [{
      label: 'Chats Started',
      data: assistantPopularity?.assistant_popularity.slice(0, 5).map(item => item.chats_started) || [],
      backgroundColor: colors.primary,
    }]
  };

  const userRetentionData = {
    labels: userRetention?.daily_user_retention.map(day => day.date) || [],
    datasets: [{
      label: 'Returning Users',
      data: userRetention?.daily_user_retention.map(day => day.returning_users) || [],
      borderColor: colors.primary,
      backgroundColor: colors.primaryLight,
      tension: 0.1,
      fill: true,
    }]
  };

  const documentTypeDistributionData = {
    labels: documentTypeDistribution?.document_type_distribution.map(item => item.type) || [],
    datasets: [{
      label: 'Document Count',
      data: documentTypeDistribution?.document_type_distribution.map(item => item.count) || [],
      backgroundColor: 'rgba(75, 192, 192, 0.8)',
    }]
  };

  const assistantUsageByTimeData = {
    labels: assistantUsageByTime?.hourly_usage.map(item => item.hour) || [],
    datasets: [{
      label: 'Message Count',
      data: assistantUsageByTime?.hourly_usage.map(item => item.message_count) || [],
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
      tension: 0.1,
      fill: true,
    }]
  };

  const renderContent = () => {
    switch (mode.value) {
      case 'chat_activity':
        return (
          <>
            <ChartCard title="Chat Activity (Last 30 Days)">
              <Line data={chatActivityData} options={chartOptions} />
            </ChartCard>
            <ChartCard title="User Engagement">
              <Line data={userEngagementData} options={chartOptions} />
            </ChartCard>
            <ChartCard title="Document Upload Trends">
              <Line data={documentUploadTrendsData} options={chartOptions} />
            </ChartCard>
            <ChartCard title="User Retention">
              <Line data={userRetentionData} options={chartOptions} />
            </ChartCard>
          </>
        );
      case 'document_types':
        return (
          <>
            <ChartCard title="Document Types">
              <Doughnut data={documentTypesData} options={chartOptions} />
            </ChartCard>
            <ChartCard title="Characters per Document">
              <Bar data={charactersPerDocData} options={chartOptions} />
            </ChartCard>
            <ChartCard title="Language Distribution">
              <Bar data={languageDistributionData} options={chartOptions} />
            </ChartCard>
            <ChartCard title="Document Processing Status">
              <Doughnut data={processingStatusData} options={chartOptions} />
            </ChartCard>
          </>
        );
      case 'knowledge_base':
        return (
          <>
            <ChartCard title="Top 5 Knowledge Base Usage">
              <Bar data={knowledgeBaseUsageData} options={chartOptions} />
            </ChartCard>
            <ChartCard title="Top 5 Assistant Popularity">
              <Bar data={assistantPopularityData} options={chartOptions} />
            </ChartCard>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      <div className="space-y-8">
        <h3 className='text-3xl font-bold text-gray-900 text-center mb-8'>
          Assistant Analytics Dashboard
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <StatCard icon={<FiFileText />} title="Total Documents" value={documentStats?.total_documents} />
          <StatCard icon={<FiMessageSquare />} title="Total Chats" value={chatActivity?.daily_chat_activity.reduce((sum, day) => sum + day.count, 0)} />
          <StatCard icon={<FiBook />} title="Knowledge Base Items" value={knowledgeBaseUsage?.document_usage.length} />
          <StatCard icon={<FiUsers />} title="Avg. Chars per Doc" value={Math.round(documentStats?.average_characters_per_document || 0)} />
          <StatCard icon={<FiClock />} title="Avg. Session Duration" value={`${Math.round(chatSessionDuration?.average_session_duration_minutes || 0)} min`} />
          <StatCard icon={<FiTrendingUp />} title="Total KB Accesses" value={knowledgeBaseOverview?.total_document_accesses} />
        </div>

        <div className="flex flex-col items-center space-y-6 mb-8">
          <Tab.Group onChange={(index) => setMode(statisticsModes[index])}>
            <Tab.List className="flex flex-wrap justify-center max-w-4xl mx-auto">
              {statisticsModes.map((mode) => (
                <Tab
                  key={mode.value}
                  className={({ selected }) =>
                    classNames(
                      'flex items-center px-3 py-2 m-1 text-sm font-medium rounded-full',
                      'focus:outline-none transition-all duration-200 ease-in-out',
                      selected
                        ? `${mode.color} shadow-sm`
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    )
                  }
                >
                  {({ selected }) => (
                    <motion.div
                      className="flex items-center space-x-2"
                      initial={false}
                      animate={{ 
                        scale: selected ? 1.05 : 1,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      <span>{mode.label}</span>
                    </motion.div>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={mode.value}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {renderContent()}
          </motion.div>
        </AnimatePresence>
      </div>
    </Card>
  );
}

const StatCard = ({ icon, title, value }) => (
  <Card className="flex items-center p-6 transition-all duration-300 hover:shadow-lg">
    <div className="text-3xl text-indigo-500 mr-4">{icon}</div>
    <div>
      <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      <p className="text-2xl font-bold text-indigo-600">{value}</p>
    </div>
  </Card>
);

const ChartCard = ({ title, children }) => (
  <Card className="w-full transition-all duration-300 hover:shadow-lg">
    <h3 className="text-xl font-semibold text-gray-800 mb-4">{title}</h3>
    <div className="h-80">
      {children}
    </div>
  </Card>
);

export default Statistics;