import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  return <div className='min-h-screen'>
    {pathname !== '/search' && pathname !== '/bot' && <Header dark={pathname !== '/'} />}
    <div className={`mx-auto ${pathname !== `/` ? `max-w-[1200px]` : `max-w-[1200px]`} px-4 py-6 sm:px-6 lg:px-8 pb-10`}>
      {children}
    </div>
    {pathname !== '/search' && pathname !== '/bot' && <Footer />}
  </div>
}

export default Layout;