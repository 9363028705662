import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const DocumentInfo = ({ documentInfo, setDocumentInfo, selectedRowId }) => {
    const handleSave = async (field, value) => {
    try {
      const response = await axios.patch(`/api/update_document_info/${selectedRowId}`, {
        [field]: value
      });
      setDocumentInfo(response.data);
      toast.success('Saved', {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'minimal-toast'
      });
    } catch (error) {
      console.error('Error updating document info:', error);
      toast.error('Failed to save', {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'minimal-toast'
      });
    }
  };

  const EditableField = ({ label, field, value, type = 'input' }) => {
    const [localValue, setLocalValue] = useState(value);
    const textareaRef = useRef(null);

    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    useEffect(() => {
      if (type === 'textarea' && textareaRef.current) {
        adjustTextareaHeight();
      }
    }, [localValue, type]);

    const adjustTextareaHeight = () => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    const handleChange = (e) => {
      setLocalValue(e.target.value);
      if (type === 'textarea') {
        adjustTextareaHeight();
      }
    };

    const handleBlur = () => {
      if (localValue !== value) {
        handleSave(field, localValue);
      }
    };

    return (
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
        <div className="relative">
          {type === 'textarea' ? (
            <textarea
              ref={textareaRef}
              className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ 
                resize: 'none',
                overflow: 'hidden',
                whiteSpace: 'pre-line'
              }}
            />
          ) : type === 'select' ? (
            <select
              className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
              value={localValue}
              onChange={(e) => {
                handleChange(e);
                handleSave(field, e.target.value);
              }}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
              <option value="restricted">Restricted</option>
            </select>
          ) : (
            <input
              type="text"
              className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ whiteSpace: 'pre-line' }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderKeywords = (keywords) => {
    const keywordArray = keywords.split(',').map(k => k.trim());
    const colors = ['bg-blue-100 text-blue-800', 'bg-green-100 text-green-800', 'bg-yellow-100 text-yellow-800', 'bg-red-100 text-red-800', 'bg-indigo-100 text-indigo-800', 'bg-purple-100 text-purple-800', 'bg-pink-100 text-pink-800'];
    
    return (
      <div className="flex flex-wrap gap-2 mt-2">
        {keywordArray.map((keyword, index) => (
          <span key={index} className={`px-2 py-1 rounded-full text-xs font-medium ${colors[index % colors.length]}`}>
            {keyword}
          </span>
        ))}
      </div>
    );
  };

  const InfoItem = ({ label, value }) => (
    <div>
      <dt className="text-sm font-medium text-gray-500 mb-1">{label}</dt>
      <dd className="text-sm font-semibold text-gray-900">{value}</dd>
    </div>
  );

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="flex-1 overflow-y-auto px-6 py-4">
      {documentInfo ? (
        <div className="space-y-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Document Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6">
            <EditableField label="Name" field="name" value={documentInfo.name} />
            <EditableField label="Author" field="author" value={documentInfo.author} />
            <EditableField label="Reading Permission" field="readingPermission" value={documentInfo.readingPermission} type="select" />
            <EditableField label="Chat Usage Permission" field="chatUsagePermission" value={documentInfo.chatUsagePermission} type="select" />
          </div>
          <EditableField label="Description" field="description" value={documentInfo.description} type="textarea" />
          <EditableField label="Summary" field="summary" value={documentInfo.summary} type="textarea" />
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Keywords</label>
            {renderKeywords(documentInfo.keywords)}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
            <InfoItem label="Data Type" value={documentInfo.dataType} />
            <InfoItem label="File Size" value={formatFileSize(documentInfo.fileSize)} />
            <InfoItem label="Characters" value={documentInfo.characterCount.toLocaleString()} />
            <InfoItem label="Language" value={documentInfo.language} />
            <InfoItem label="Source" value={documentInfo.source} />
            {documentInfo.videoLength && (
              <InfoItem label="Video Length" value={`${documentInfo.videoLength} seconds`} />
            )}
          </div>
          <div className="bg-gray-50 rounded-lg p-4 shadow-sm">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Dates</h3>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <InfoItem label="Date of Upload" value={formatDate(documentInfo.uploadDate)} />
              <InfoItem label="Last Modified" value={formatDate(documentInfo.lastModified)} />
              <InfoItem label="Last Accessed" value={formatDate(documentInfo.lastAccessed)} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
        </div>
      )}
    </div>
  );
};

export default DocumentInfo;