import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from 'components/Card';
import DataTable from 'components/DataTable';
import Modal from 'components/Modal';

const UserManagement = () => {
  console.log("UserManagement component rendering");
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({ username: '', email: '', password: '', role: 'staff' });

  const cols = [
    { label: 'Username' },
    { label: 'Email' },
    { label: 'Role' },
    { label: 'Actions', align: 'right' },
  ];

  useEffect(() => {
    console.log("UserManagement useEffect triggered");
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    console.log("Fetching users...");
    setLoading(true);
    setError(null);
    try {
      console.log("Access token:", localStorage.getItem('access_token'));
      const response = await axios.get('https://www.chatassistant.online/api/admin/users', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      console.log("Users fetched:", response.data);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error.message || 'An error occurred while fetching users');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateUser = async () => {
    try {
      await axios.post('https://www.chatassistant.online/api/auth/register', newUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      setOpenModal(false);
      setNewUser({ username: '', email: '', password: '', role: 'staff' });
      fetchUsers();
    } catch (error) {
      console.error('Error creating user:', error.response?.data?.detail || error.message);
      alert(error.response?.data?.detail || 'Error creating user');
    }
  };

  const handleUpdateUser = async () => {
    try {
      await axios.put(`https://www.chatassistant.online/api/admin/users/${selectedUser.id}`, selectedUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      setOpenModal(false);
      fetchUsers();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleDeleteUser = async () => {
    try {
      await axios.delete(`https://www.chatassistant.online/api/admin/users/${selectedUser.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      setOpenModal(false);
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const renderRow = (row) => (
    <tr key={row.id}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{row.username}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.email}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.role}</td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <button
          onClick={() => {
            setSelectedUser(row);
            setModalAction('update');
            setOpenModal(true);
          }}
          className="text-indigo-600 hover:text-indigo-900 mr-2"
        >
          Edit
        </button>
        <button
          onClick={() => {
            setSelectedUser(row);
            setModalAction('delete');
            setOpenModal(true);
          }}
          className="text-red-600 hover:text-red-900"
        >
          Delete
        </button>
      </td>
    </tr>
  );

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">User Management</h1>
      {loading ? (
        <p>Loading users...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : (
        <>
          <button
            onClick={() => {
              setModalAction('create');
              setOpenModal(true);
            }}
            className="mb-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create New User
          </button>
          <Card>
            <DataTable cols={cols} rows={users} renderRow={renderRow} />
          </Card>
          <Modal
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
            onConfirm={modalAction === 'create' ? handleCreateUser : modalAction === 'update' ? handleUpdateUser : handleDeleteUser}
            title={modalAction === 'create' ? 'Create User' : modalAction === 'update' ? 'Update User' : 'Delete User'}
            message={
              modalAction === 'delete'
                ? `Are you sure you want to delete ${selectedUser?.username}?`
                : modalAction === 'create'
                ? (
                  <div>
                    <input
                      type="text"
                      placeholder="Username"
                      value={newUser.username}
                      onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      value={newUser.email}
                      onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      value={newUser.password}
                      onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <select
                      value={newUser.role}
                      onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="staff">Staff</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                )
                : (
                  <div>
                    <input
                      type="text"
                      placeholder="Username"
                      value={selectedUser?.username}
                      onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      value={selectedUser?.email}
                      onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <select
                      value={selectedUser?.role}
                      onChange={(e) => setSelectedUser({ ...selectedUser, role: e.target.value })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="staff">Staff</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                )
            }
            confirmText={modalAction === 'create' ? 'Create' : modalAction === 'update' ? 'Update' : 'Delete'}
            cancelText="Cancel"
            type={modalAction === 'delete' ? 'delete' : 'confirm'}
          />
        </>
      )}
    </div>
  );
};

export default UserManagement;
