import React, { useState, useEffect, Fragment, useRef } from 'react';
import { createPortal } from 'react-dom';
import Card from 'components/Card';
import DataTable from 'components/DataTable';
import ChatbotDrawer from 'components/Drawer/ChatbotDrawer';
import { useParams } from 'react-router-dom';
import ListIcon from 'assets/icons/menu.png';
import LayoutIcon from 'assets/icons/dashboard.png';
// import ClusterIcon from 'assets/icons/cluster.png'; // Add a new icon for clusters
import { Menu, RadioGroup, Transition, Dialog, Switch, Tab } from '@headlessui/react';
import { EllipsisVerticalIcon, ChevronDownIcon, ChevronUpIcon, BadgeCheckIcon, XCircleIcon, FaceSmileIcon, FaceFrownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import UserCircleIcon from '@heroicons/react/24/outline/UserCircleIcon';
import { usePopper } from 'react-popper';

const feedbackModes = [
  { value: 'positive', label: "Positive reviews", icon: FaceSmileIcon, color: 'bg-green-100 text-green-800' },
  { value: 'negative', label: "Negative reviews", icon: FaceFrownIcon, color: 'bg-red-100 text-red-800' },
];

const viewModes = [
  { value: 'table', label: "Table", icon: LayoutIcon },
  { value: 'clusters', label: "Clusters", icon: ListIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const renderFeedbackDetails = (feedback) => {
  const additionalInfo = feedback.additional_info ? JSON.parse(feedback.additional_info) : null;
  
  const feedbackTypes = {
    notUseful: { label: "Not Helpful", color: "red" },
    notClear: { label: "Unclear", color: "yellow" },
    notTrue: { label: "Inaccurate", color: "orange" },
  };

  const getColorClasses = (color) => {
    const colorClasses = {
      red: "bg-red-100 text-red-800",
      yellow: "bg-yellow-100 text-yellow-800",
      orange: "bg-orange-100 text-orange-800",
    };
    return colorClasses[color] || "bg-gray-100 text-gray-800";
  };

  return (
    <div className="mt-2 space-y-2 text-sm">
      <p><span className="font-semibold">User Query:</span> {feedback.user_query}</p>
      <p><span className="font-semibold">Chatbot Answer:</span> {feedback.chatbot_answer}</p>
      <p><span className="font-semibold">Date:</span> {new Date(feedback.date).toLocaleString()}</p>
      {additionalInfo && Object.keys(additionalInfo).length > 0 && (
        <div>
          <span className="font-semibold">Feedback Type:</span>
          <div className="mt-1 flex flex-wrap gap-2">
            {Object.entries(additionalInfo).map(([key, value]) => (
              value && (
                <span 
                  key={key} 
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    getColorClasses(feedbackTypes[key]?.color)
                  }`}
                >
                  <XCircleIcon className="mr-1 h-4 w-4" aria-hidden="true" />
                  {feedbackTypes[key]?.label || key}
                </span>
              )
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const FeedbackItem = ({ feedback, index, onViewThread, onShowDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={index % 2 === 0 ? 'bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6' : 'bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'}>
      <dt className="text-sm font-medium text-gray-500">Feedback</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <div className="flex justify-between items-start">
          <p>{feedback.feedback}</p>
          <div className="flex items-center">
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="mr-2 text-indigo-600 hover:text-indigo-900"
            >
              {isExpanded ? 'Less' : 'More'}
            </button>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => onViewThread(feedback.thread_id, feedback.user_message_id)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                        >
                          View Thread
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => onShowDetails(feedback)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm cursor-pointer'
                          )}
                        >
                          Show Details
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        {isExpanded && renderFeedbackDetails(feedback)}
      </dd>
    </div>
  );
};

const Cluster = ({ cluster, isPositive, onViewThread, onShowDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-6">
      <div className="bg-white shadow sm:rounded-lg">
        <div 
          className="px-4 py-5 sm:px-6 flex justify-between items-center cursor-pointer hover:bg-gray-50"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
            {cluster.label}
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
              {cluster.feedbacks.length} Feedbacks
            </span>
          </h3>
          {isExpanded ? (
            <ChevronUpIcon className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
          )}
        </div>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="border-t border-gray-200">
                <dl>
                  {cluster.feedbacks.map((feedback, index) => (
                    <FeedbackItem 
                      key={feedback.id} 
                      feedback={feedback} 
                      index={index}
                      onViewThread={(threadId) => onViewThread(threadId, feedback.user_message_id)}
                      onShowDetails={onShowDetails}
                    />
                  ))}
                </dl>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const DropdownMenu = ({ isOpen, onClose, onViewThread, onShowDetails, referenceElement }) => {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
  });

  if (!isOpen) return null;

  return createPortal(
    <div
      ref={setPopperElement}
      style={{
        ...styles.popper,
        zIndex: 9999, // Add this line to ensure high z-index
      }}
      {...attributes.popper}
      className="mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      <div className="py-1">
        <a
          onClick={onViewThread}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
        >
          View Thread
        </a>
        <a
          onClick={onShowDetails}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
        >
          Show Details
        </a>
      </div>
    </div>,
    document.body
  );
};

const Feedback = () => {
  const { chatId } = useParams();
  const [positiveFeedbacks, setPositiveFeedbacks] = useState(null);
  const [negativeFeedbacks, setNegativeFeedbacks] = useState(null);
  const [clusteredLikes, setClusteredLikes] = useState(null);
  const [clusteredDislikes, setClusteredDislikes] = useState(null);
  const [open, setOpen] = useState(false);
  const [chatbotDrawerId, setChatbotDrawerId] = useState(null);
  const [chatbotDrawerThreadId, setChatbotDrawerThreadId] = useState(null);
  const [chatbotDrawerMessageId, setChatbotDrawerMessageId] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [expandedClusters, setExpandedClusters] = useState({});
  const [mode, setMode] = useState(feedbackModes[0]);
  const [isClusterView, setIsClusterView] = useState(true);
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuButtonRefs = useRef({});

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const positiveResponse = await fetch(`https://www.chatassistant.online/api/get-positive-feedbacks/${chatId}`);
        const positiveText = await positiveResponse.text();
        const positiveData = JSON.parse(positiveText);

        const negativeResponse = await fetch(`https://www.chatassistant.online/api/get-negative-feedbacks/${chatId}`);
        const negativeText = await negativeResponse.text();
        const negativeData = JSON.parse(negativeText);

        const likeResponse = await fetch(`https://www.chatassistant.online/api/clustered-feedbacks/${chatId}/like`);
        const likeText = await likeResponse.text();
        const likeData = JSON.parse(likeText);

        const dislikeResponse = await fetch(`https://www.chatassistant.online/api/clustered-feedbacks/${chatId}/dislike`);
        const dislikeText = await dislikeResponse.text();
        const dislikeData = JSON.parse(dislikeText);

        setPositiveFeedbacks(positiveData.feedbacks);
        setNegativeFeedbacks(negativeData.feedbacks);
        setClusteredLikes(likeData.clustered_feedbacks);
        setClusteredDislikes(dislikeData.clustered_feedbacks);
      } catch (error) {
        console.log("Error fetching feedbacks:", error);
      }
    };

    fetchFeedbacks();
  }, [chatId]);

  if (!positiveFeedbacks || !negativeFeedbacks || !clusteredLikes || !clusteredDislikes) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  const renderRow = (row) => {
    return (
      <tr className='border-b border-gray-100' key={row.id}>
        <td className='w-0 px-1 py-5'>
          <input
            type='checkbox'
            className='w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
          />
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-left'>
          {row.id}
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-left'>
          <span title={row.user_query}>{truncate(row.user_query, 30)}</span>
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-left'>
          <span title={row.chatbot_answer}>{truncate(row.chatbot_answer, 30)}</span>
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-left'>
          <span title={row.feedback}>{truncate(row.feedback, 30)}</span>
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          <div className='flex items-center'>
            <div className="flex flex-shrink-0 self-center ml-3">
              <button
                ref={(el) => menuButtonRefs.current[row.id] = el}
                onClick={() => setOpenMenuId(openMenuId === row.id ? null : row.id)}
                className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600"
              >
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <DropdownMenu
                isOpen={openMenuId === row.id}
                onClose={() => setOpenMenuId(null)}
                onViewThread={() => {
                  setChatbotDrawerId(row.assistant_id);
                  setChatbotDrawerThreadId(row.thread_id);
                  setOpen(true);
                  setOpenMenuId(null);
                }}
                onShowDetails={() => {
                  setSelectedFeedback(row);
                  setOpenMenuId(null);
                }}
                referenceElement={menuButtonRefs.current[row.id]}
              />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const toggleCluster = (clusterLabel) => {
    setExpandedClusters(prev => ({
      ...prev,
      [clusterLabel]: !prev[clusterLabel]
    }));
  };

  const cols = [
    { label: '#', align: 'left' },
    { label: 'User Query', align: 'left' },
    { label: 'Chatbot Answer', align: 'left' },
    { label: 'Feedback', align: 'left' },
    { label: 'Actions', align: 'center' },
  ];

  const ToggleSwitch = ({ enabled, setEnabled }) => (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled ? 'bg-indigo-600' : 'bg-gray-200'
        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
      >
        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
        />
      </Switch>
      <Switch.Label className="ml-4 text-sm font-medium text-gray-900">
        {enabled ? "Cluster View" : "Table View"}
      </Switch.Label>
    </Switch.Group>
  );

  const handleViewThread = (threadId, messageId) => {
    setChatbotDrawerId(chatId);
    setChatbotDrawerThreadId(threadId);
    setChatbotDrawerMessageId(messageId);
    setOpen(true);
  };

  const renderFeedbackItem = (feedback) => (
    <li key={feedback.id} className="py-4 hover:bg-gray-50 transition duration-150 ease-in-out rounded-lg">
      <div className="flex items-center space-x-4 px-4">
        <div className="flex-shrink-0">
          <UserCircleIcon className="h-10 w-10 text-gray-400" />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate">{feedback.feedback}</p>
          <p className="text-sm text-gray-500 truncate">{feedback.chatbot_answer}</p>
        </div>
        <div className="text-sm text-gray-500">
          <p>{new Date(feedback.date).toLocaleString()}</p>
        </div>
        <div>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:text-gray-600">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => handleViewThread(feedback.thread_id, feedback.user_message_id)}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        View Thread
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => setSelectedFeedback(feedback)}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Show Details
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </li>
  );

  const renderContent = () => {
    const feedbacks = mode.value === 'positive' ? positiveFeedbacks : negativeFeedbacks;
    const clusters = mode.value === 'positive' ? clusteredLikes : clusteredDislikes;

    if (isClusterView) {
      return (
        <div>
          {Object.entries(clusters || {}).map(([key, cluster]) => (
            <Cluster 
              key={key}
              cluster={cluster}
              onViewThread={handleViewThread}
              onShowDetails={setSelectedFeedback}
              mode={mode.value}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {feedbacks.map(renderFeedbackItem)}
          </ul>
        </div>
      );
    }
  };

  return (
    <Card>
      <div className="space-y-8">
        <h3 className='text-3xl font-bold text-gray-900 text-center'>
          Feedback Overview
        </h3>

        <div className="flex flex-col items-center space-y-6 mb-8">
          <Tab.Group onChange={(index) => setMode(feedbackModes[index])}>
            <Tab.List className="flex flex-wrap justify-center max-w-4xl mx-auto">
              {feedbackModes.map((mode) => (
                <Tab
                  key={mode.value}
                  className={({ selected }) =>
                    classNames(
                      'flex items-center px-3 py-2 m-1 text-sm font-medium rounded-full',
                      'focus:outline-none transition-all duration-200 ease-in-out',
                      selected
                        ? `${mode.color} shadow-sm`
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    )
                  }
                >
                  {({ selected }) => (
                    <motion.div
                      className="flex items-center space-x-2"
                      initial={false}
                      animate={{ 
                        scale: selected ? 1.05 : 1,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      <mode.icon className={`w-5 h-5 ${selected ? 'text-current' : 'text-gray-400'}`} />
                      <span>{mode.label}</span>
                    </motion.div>
                  )}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>

        <div className="flex justify-end mb-4">
          <ToggleSwitch 
            enabled={isClusterView}
            setEnabled={setIsClusterView}
          />
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={`${mode.value}-${isClusterView}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {renderContent()}
          </motion.div>
        </AnimatePresence>

        <ChatbotDrawer 
          open={open} 
          setOpen={setOpen} 
          chatbotDrawerId={chatbotDrawerId} 
          threadId={chatbotDrawerThreadId} 
          messageId={chatbotDrawerMessageId}
        />

        {selectedFeedback && (
          <Transition appear show={!!selectedFeedback} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setSelectedFeedback(null)}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
              </Transition.Child>
  
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-4">
                        Feedback Details
                      </Dialog.Title>
                      <div className="mt-2">
                        {/* User Query */}
                        <div className="chat-history mb-5 flex justify-end">
                          <span className="inline-block text-white bg-black rounded-2xl p-2 px-4 shadow self-end">
                            <span className="font-bold">User: </span>
                            <span dangerouslySetInnerHTML={{ __html: selectedFeedback.user_query }} style={{ whiteSpace: 'pre-line' }}></span>
                          </span>
                        </div>
                        {/* Chatbot Answer */}
                        <div className="chat-history mb-5 flex justify-start">
                          <span className="inline-block bg-white text-black rounded-2xl p-2 px-4 shadow">
                            <span className="font-bold">Chatbot: </span>
                            <span dangerouslySetInnerHTML={{ __html: selectedFeedback.chatbot_answer }} style={{ whiteSpace: 'pre-line' }}></span>
                          </span>
                        </div>
                        <p className="font-semibold mb-2">User Feedback:</p>
                        <textarea readOnly className="w-full h-32 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" value={selectedFeedback.feedback}></textarea>
                        {selectedFeedback.formatted_additional_info && (
                          <p className="mt-4"><b>Additional Info:</b> {selectedFeedback.formatted_additional_info}</p>
                        )}
                      </div>
  
                      <div className="mt-4 flex justify-end space-x-2">
                        <button
                          type="button"
                          className="flex-shrink-0 inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm leading-5 font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                            setChatbotDrawerId(chatId);
                            setChatbotDrawerThreadId(selectedFeedback.thread_id);
                            setOpen(true);
                          }}
                        >
                          View Thread
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          onClick={() => setSelectedFeedback(null)}
                        >
                          Close
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        )}
      </div>
    </Card>
  );
};

export default Feedback;