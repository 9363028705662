import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import { HandThumbUpIcon, HandThumbDownIcon, SpeakerWaveIcon, PlayIcon, PauseIcon, TagIcon, MicrophoneIcon, StopIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';

import UploadIcon from 'assets/icons/upload_1.svg';
import CloseIcon from 'assets/icons/close.svg';
import RestartIcon from 'assets/icons/restart.svg';
import { useParams, useLocation } from 'react-router-dom'; 
import { jsPDF } from 'jspdf';
import useMediaQuery from '@mui/material/useMediaQuery'; // You might need to install @mui/material
import { LightBulbIcon } from '@heroicons/react/24/solid';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import ReactMarkdown from 'react-markdown';
import { FeedbackSystem, LikeButton, DislikeButton } from 'utils/FeedbackSystem';
import { renderContext, ContextModal, useContextHandling } from 'utils/ContextHandler';
import { useMicrophoneHandling } from 'utils/MicrophoneHandler';
import { useTextToSpeech } from 'utils/TextToSpeechHandler';
import { saveChatAsPDF } from 'utils/pdfUtils';


const languages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' }, 
  { code: 'ar', name: 'Arabic' },
  { code: 'de', name: 'German' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TypingIndicator = () => (
  <div className="chat-history self-start">
    <span className="inline-block bg-white rounded-2xl p-2 px-4 shadow">
      <span className="font-bold">Chatbot: </span>
      <span>Typing...</span>
    </span>
  </div>
);


const FollowUpQuestion = ({ question, onClick }) => (
  <button
    onClick={onClick}
    className="group w-full text-left p-3 rounded-lg bg-white shadow-sm hover:shadow-md transition-all duration-200 ease-in-out border border-gray-200 hover:border-blue-300"
  >
    <div className="flex items-center">
      <LightBulbIcon className="h-5 w-5 text-yellow-400 group-hover:text-yellow-500 mr-3" />
      <span className="text-sm text-gray-700 group-hover:text-gray-900 font-medium">{question}</span>
    </div>
  </button>
);

const FAQItem = ({ faq, onClick }) => (
  <button
    onClick={onClick}
    className="flex items-center px-4 py-2 m-1 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-blue-50 hover:border-blue-300 hover:text-blue-700 transition-all duration-200 ease-in-out shadow-sm"
  >
    <LightBulbIcon className="h-4 w-4 mr-2 text-yellow-400" />
    <span className="truncate">{faq.name}</span>
  </button>
);

const FAQSection = ({ faqItems, sendMessage }) => (
  <div className="w-full mb-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
    <h4 className="text-sm font-semibold text-gray-700 mb-3">Suggested questions:</h4>
    <div className="flex flex-wrap -m-1">
      {faqItems.map((faq) => (
        <FAQItem key={faq.id} faq={faq} onClick={() => sendMessage(faq.name)} />
      ))}
    </div>
  </div>
);


const LoadingSpinner = () => (
  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
);


const Chat = ({ 
  chatId: propChatId, 
  threadId: propThreadId, 
  initialMessageId, 
  isPreview = false, 
  previewChatInfo = null,
  previewHistories = null,
  isStaffMember,
  isHumanConversation,
}) => {
  const { chatId: paramsChatId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const chatId = propChatId || paramsChatId;
  const threadId = queryParams.get('thread_id') || propThreadId;
  const [isStaffMode, setIsStaffMode] = useState(false);
  const [chatInfo, setChatInfo] = useState(previewChatInfo || null);
  const [histories, setHistories] = useState(previewHistories || []);
  const [isTyping, setIsTyping] = useState(false);
  const chatContainerRef = useRef(null);
  const [docIds, setDocIds] = useState([]);
  const [faqItems, setFaqItems] = useState([]);
  const [googleFont, setGoogleFont] = useState(null);
  const [hideContext, setHideContext] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].code);
  const alt_image = 'https://www.chatassistant.online/api/static/chat_imgs/chatbot_alt.png';
  const viewMode = !!threadId;
  const [showFollowUps, setShowFollowUps] = useState(true);
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lightboxSlides, setLightboxSlides] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useMediaQuery('(min-width:768px)');
  const { isRecording, toggleRecording, sendAudioToServer } = useMicrophoneHandling();


  const [feedbackModalState, setFeedbackModalState] = useState({
    isOpen: false,
    type: null,
    messageId: null,
  });
  const [feedbackText, setFeedbackText] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState({
    notUseful: false,
    notClear: false,
    notTrue: false,
  });
  const {
    expandedContexts,
    selectedContext,
    isModalOpen,
    toggleContext,
    openContextModal,
    closeContextModal,
  } = useContextHandling();

  const {
    playAudioFromAPI,
    playAudio,
    pauseAudio,
    toggleAudio,
    getAudioIcon,
    isAudioPlaying,
    loadingAudio,
  } = useTextToSpeech();

  const [sseConnected, setSseConnected] = useState(false);
  const [lastEventTime, setLastEventTime] = useState(null);
  const [locallysentMessages, setLocallySentMessages] = useState(new Set());

  const addMessageToHistory = useCallback((newMessage) => {
    setHistories((prevHistories) => {
      // Check if the message already exists in the history
      if (!prevHistories.some(msg => msg.id === newMessage.id)) {
        return [...prevHistories, {
          id: newMessage.id,
          type: 'text',
          data: {
            html: newMessage.content[0].text.value,
            context: newMessage.context
          },
          chatbot: newMessage.role === 'assistant' || newMessage.role === 'human',
          role: message.role,  // Use the role from the message data
          messageId: newMessage.id
        }];
      }
      return prevHistories;
    });
  }, []);

  useEffect(() => {
    if (!isPreview && threadId) {
      console.log('Setting up SSE connection for thread:', threadId);
      const eventSource = new EventSource(`https://www.chatassistant.online/api/sse/chat/${threadId}`);

      eventSource.onopen = (event) => {
        console.log('SSE connection opened:', event);
      };

      const handleNewMessages = (event) => {
        console.log('New messages event received:', event);
        try {
          const newMessages = JSON.parse(event.data);
          console.log('Parsed new messages from event:', newMessages);
          if (Array.isArray(newMessages)) {
            setHistories(prevHistories => {
              const updatedHistories = [...prevHistories];
              newMessages.forEach(message => {
                if (!updatedHistories.some(h => h.id === message.id) && !locallysentMessages.has(message.id)) {
                  updatedHistories.push({
                    id: message.id,
                    type: 'text',
                    data: {
                      html: message.content,
                      context: message.context
                    },
                    chatbot: message.role === 'assistant' || message.role === 'human',
                    role: message.role,  // Use the role from the message data
                    messageId: message.id
                  });
                }
              });
              return updatedHistories;
            });
          } else {
            console.error('Received data is not an array:', newMessages);
          }
        } catch (error) {
          console.error('Error parsing SSE data from event:', error);
        }
      };

      eventSource.onmessage = handleNewMessages;
      eventSource.addEventListener('new_messages', handleNewMessages);

      eventSource.onerror = (error) => {
        console.error('Error in SSE connection:', error);
        eventSource.close();
      };

      return () => {
        console.log('Closing SSE connection');
        eventSource.close();
      };
    }
  }, [isPreview, threadId, locallysentMessages]);

  // Add this useEffect to listen for changes in isHumanConversation
  useEffect(() => {
    if (threadId) {
      const eventSource = new EventSource(`https://www.chatassistant.online/api/sse/conversation-state/${threadId}`);
      
      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setIsHumanConversation(data.is_human_conversation);
      };
  
      return () => {
        eventSource.close();
      };
    }
  }, [threadId]);

  const showFeedbackModal = (type, messageId) => {
    setFeedbackModalState({ isOpen: true, type, messageId });
    setFeedbackText('');
    setAdditionalInfo({ notUseful: false, notClear: false, notTrue: false });
  };

  const submitFeedback = async () => {
    let feedbackMessageId = feedbackModalState.messageId;
  
    // Find the user query that precedes the specific chatbot message
    let userQuery = "";
    let chatbotAnswer = "";
    let userMessageId = "";
    let assistantMessageId = "";
    let found = false;
    for (let i = histories.length - 1; i >= 0; i--) {
      if (found) {
        if (!histories[i].chatbot) {
          userQuery = histories[i].data.html;
          userMessageId = histories[i].messageId;
          break;
        }
      } else if (histories[i].id === feedbackMessageId) {
        chatbotAnswer = histories[i].data.html;
        assistantMessageId = histories[i].messageId;
        found = true;
      }
    }
  
    const data = {
      type: feedbackModalState.type,
      feedback: feedbackText,
      additionalInfo: additionalInfo,
      chatId: chatId,
      userQuery: userQuery,
      chatbotAnswer: chatbotAnswer,
      userMessageId: userMessageId,
      assistantMessageId: assistantMessageId,
    };
    
    try {
      const response = await fetch('https://www.chatassistant.online/api/submit_feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        console.log('Feedback submitted successfully');
      } else {
        console.error('Error submitting feedback:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  
    setFeedbackModalState({ isOpen: false, type: null, messageId: null });
  };

  const parseFaqString = (faqString) => {
    if (!faqString) {
      return []; // Return empty array if faqString is empty
    }
  
    const faqArray = faqString.split(",");
    
    return faqArray.map((faq, index) => ({
      id: index + 1,
      name: faq.trim(),
    }));
  };

  const scrollToMessage = useCallback((messageId) => {
    const element = document.getElementById(`msg-${messageId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setHighlightedMessageId(messageId);
      setTimeout(() => setHighlightedMessageId(null), 2000); // Remove highlight after 2 seconds
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [histories]);
  
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (isPreview) {
      setChatInfo(previewChatInfo);
      setHistories(previewHistories);
      // Add this line to set FAQ items in preview mode
      setFaqItems(parseFaqString(previewChatInfo.faq_list));
    } else {
      const fetchData = async () => {
        try {
          const chatInfoResponse = await fetch(`https://www.chatassistant.online/api/chats/${chatId}`).then((response) => response.json());
  
          setChatInfo(chatInfoResponse);
          setDocIds(chatInfoResponse.doc_ids);
          setGoogleFont(chatInfoResponse.google_font);
          setHideContext(chatInfoResponse.hide_context);
          const updatedFaqItems = parseFaqString(chatInfoResponse.faq_list);
          setFaqItems(updatedFaqItems);

          const initialMessage = {
            id: 1,
            type: 'image',
            data: {
              url: chatInfoResponse.img || alt_image,
              html: chatInfoResponse.initial_text,
            },
            chatbot: true,
          };

          if (threadId) {
            const response = await fetch(`https://www.chatassistant.online/api/get-threads-messages/${threadId}`);
            const data = await response.json();
            const formattedHistories = data.thread_ui.map((message) => ({
              id: message.id,
              type: 'text',
              data: { 
                html: message.content,
                context: message.context // Add this line to include the context
              },
              chatbot: message.role === 'assistant' || message.role === 'human',
              role: message.role,  // Use the role from the message data
            }));
            setHistories([initialMessage, ...formattedHistories]);
            if (initialMessageId) {
              setTimeout(() => scrollToMessage(initialMessageId), 100);
            }
          } else {
            const response = await fetch(`https://www.chatassistant.online/api/thread/${chatId}/messages`);
            const threadUI = await response.json();
            console.log(threadUI);
            const formattedHistories = threadUI.map((message, index) => ({
              id: message.id,
              type: 'text',
              data: { 
                html: message.content,
                context: message.context // Add this line to include the context
              },
              chatbot: message.role === 'assistant',
              role: message.role,  // Use the role from the message data
              messageId: message.id,
            }));
  
            setHistories([initialMessage, ...formattedHistories]);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };
  
      fetchData();
    }
  }, [isPreview, previewChatInfo, previewHistories, chatId, threadId, initialMessageId]);

  useEffect(() => {
    if (initialMessageId && histories.length > 0) {
      setTimeout(() => scrollToMessage(initialMessageId), 100);
    }
  }, [initialMessageId, histories, scrollToMessage]);

  const restartChat = async () => {
    setHistories([
      {
        id: 1,
        type: 'image',
        data: {
          url: chatInfo.img || alt_image,
          html: chatInfo.initial_text,
        },
        chatbot: true,
      },
    ]);

    try {
      const response = await fetch('/api/new_chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ assistant_id: chatId }),
      });
      const result = await response.json();
      if (!result.success) {
        console.error("Error restarting chat:", result.error);
      }
    } catch (error) {
      console.error("Error restarting chat:", error);
    }
  };

  const handleSavePDF = () => {
    saveChatAsPDF(histories, chatId);
  };

  const sendMessage = async (query) => {
    if (isPreview) {
      const newHistory = {
        id: Date.now(),
        type: 'text',
        data: { html: query },
        chatbot: false,
      };
      const botResponse = {
        id: Date.now() + 1,
        type: 'text',
        data: { html: "This is a preview response." },
        chatbot: true,
      };
      setHistories(prev => [...prev, newHistory, botResponse]);
    } else {
      setShowFollowUps(false);
      
      const tempId = `temp_${Date.now()}`;
      
      const newMessage = {
        id: tempId,
        type: 'text',
        data: {
          html: query,
        },
        chatbot: false,
        role: isStaffMember ? 'human' : 'user',
      };
    
      setHistories((prevHistories) => [...prevHistories, newMessage]);

      const data = {
        query,
        doc_ids: docIds,
        chatid: chatId,
        chat_history: histories.map(h => ({
          role: h.role || (h.chatbot ? 'assistant' : 'user'),
          content: h.data.html
        })),
        language: selectedLanguage,
        thread_id: threadId || null,
        is_human_conversation: isHumanConversation,
        is_staff_member: isStaffMember,
      };
    
      if (!isHumanConversation) {
        setIsTyping(true);
      }
    
      try {
        const response = await fetch("https://www.chatassistant.online/api/send_message", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
    
        if (response.ok) {
          const result = await response.json();
          
          setHistories((prevHistories) => 
            prevHistories.map(msg => 
              msg.id === tempId ? { ...msg, id: result.message_id, messageId: result.message_id } : msg
            )
          );
          
          setLocallySentMessages(prev => new Set(prev).add(result.message_id));

          // Update the conversation state
          setIsHumanConversation(result.is_human_conversation);

          if (!isHumanConversation) {
            // Handle chatbot response
            const chatbotResponse = {
              id: result.assistant_message_id,
              type: 'text',
              data: {
                html: result.response,
                context: result.context,
                relevant_images: result.relevant_images,
              },
              chatbot: true,
              messageId: result.assistant_message_id,
            };

            setHistories(prevHistories => prevHistories.filter(history => history.type !== 'followups'));
            if (result.follow_up_questions && Object.keys(result.follow_up_questions).length > 0) {
              const followUpMessage = {
                id: Date.now() + 2,
                type: 'followups',
                data: {
                  follow_up_questions: result.follow_up_questions,
                },
                chatbot: true,
              };
              setHistories((prevHistories) => [
                ...prevHistories,
                chatbotResponse,
                followUpMessage
              ]);
            } else {
              setHistories((prevHistories) => [
                ...prevHistories,
                chatbotResponse
              ]);
            }

            setShowFollowUps(true);
            setLocallySentMessages(prev => new Set(prev).add(result.assistant_message_id));
          }
        } else {
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
      
      if (!isHumanConversation) {
        setIsTyping(false);
      }
      scrollToBottom();
    }
  };
  
  const handleAudioRecording = useCallback(() => {
    if (isPreview) return; // Prevent audio recording in preview mode
    if (!isRecording) {
      toggleRecording();
    } else {
      toggleRecording();
      setIsLoading(true);
      sendAudioToServer()
        .then(transcription => {
          setInputText(transcription);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error processing audio:", error);
          setIsLoading(false);
        });
    }
  }, [isRecording, toggleRecording, sendAudioToServer, setInputText, setIsLoading]);

  const renderAudioButton = (message, audioId) => (
    <button
      onClick={() => toggleAudio(audioId, message.data.html, selectedLanguage)}
      className="ml-2 text-gray-500 hover:text-gray-700"
    >
      {getAudioIcon(audioId) === 'loading' ? (
        <LoadingSpinner />
      ) : getAudioIcon(audioId) === 'pause' ? (
        <PauseIcon className="h-5 w-5" />
      ) : getAudioIcon(audioId) === 'play' ? (
        <PlayIcon className="h-5 w-5" />
      ) : (
        <SpeakerWaveIcon className="h-5 w-5" />
      )}
    </button>
  );

  if (!chatInfo) {
    return 'Loading Chat..';
  }


  const renderImageThumbnails = (relevant_images) => {
    if (!relevant_images || relevant_images.length === 0) {
      console.log("No relevant images to display");
      return null;
    }
  
    console.log("Rendering image thumbnails:", relevant_images);
  
    const lightboxSlides = relevant_images.map(image => ({
      src: `https://www.chatassistant.online/api/${image.image_path}`,
      description: image.description
    }));
  
    return (
      <div className="flex flex-wrap mt-2 gap-2">
        {relevant_images.map((image, idx) => {
          const imageId = image.id || image.vector_id || `img-${idx}`;
          return (
            <div 
              key={imageId} 
              className="relative aspect-w-16 aspect-h-9 w-24 h-24 group cursor-pointer transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
              onClick={() => {
                console.log(`Image clicked: ${imageId}`, image);
                setLightboxIndex(idx);
                setLightboxSlides(lightboxSlides);
                setLightboxOpen(true);
              }}
            >
              <img
                src={`https://www.chatassistant.online/api/${image.image_path}`}
                alt={image.description}
                className="object-cover w-full h-full rounded-md transition-all duration-300 group-hover:brightness-75"
              />
              <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>
          );
        })} 
      </div>
    );
  };



  const renderFollowUpQuestions = (followUpQuestions) => {
    if (!showFollowUps) return null;

    return (
      <div className="w-full mt-4 space-y-3">
        <h4 className="text-sm font-semibold text-gray-700 mb-2">Suggested follow-up questions:</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
          {Object.values(followUpQuestions).map((question, index) => (
            <FollowUpQuestion
              key={index}
              question={question}
              onClick={() => sendMessage(question)}
            />
          ))}
        </div>
      </div>
    );
  };


  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSendMessage = () => {
    if (isPreview) return; // Prevent sending messages in preview mode
    if (inputText.trim()) {
      sendMessage(inputText);
      setInputText('');
      setTimeout(scrollToBottom, 100);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleStaffIntervention = async () => {
    if (!isStaffMember) return;

    try {
      const response = await fetch("https://www.chatassistant.online/api/staff-intervene", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ threadId }),
      });

      if (response.ok) {
        const result = await response.json();
        setIsHumanConversation(result.is_human_conversation);
      }
    } catch (error) {
      console.error("Error in staff intervention:", error);
    }
  };

  const handleReturnToBot = async () => {
    if (!isStaffMember) return;

    try {
      const response = await fetch("https://www.chatassistant.online/api/return-to-bot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ threadId }),
      });

      if (response.ok) {
        const result = await response.json();
        setIsHumanConversation(result.is_human_conversation);
      }
    } catch (error) {
      console.error("Error returning to bot:", error);
    }
  };

  return (
    <div className="flex h-screen flex-col bg-white px-6 py-2 shadow-xl relative" style={googleFont ? { fontFamily: googleFont } : null}>
      <div className='h-full flex flex-col border border-gray-300 rounded-lg shadow-sm overflow-hidden relative'>
        <div className="p-3 flex justify-between items-center bg-white border-b border-gray-200">

          <span className='text-gray-500 text-sm inline-block px-3 py-1'>
            Powered by <span className='font-bold text-gray-900'>NCTech.com.mx</span>
          </span>
          <div className="flex items-center">
            <button 
              className='w-8 p-1'
              onClick={handleSavePDF}
            >
              <img src={UploadIcon} />
            </button>
            {!viewMode && (
              <button 
                className='ml-2 w-8 p-1'
                onClick={restartChat}
              >
                <img src={RestartIcon} />
              </button>
            )}
            <button className='ml-2 w-8 p-1'>
              <img src={CloseIcon} />
            </button>
            <Menu as="div" className="relative inline-block text-left ml-4">
              <div>
                <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                  <span>{languages.find(lang => lang.code === selectedLanguage)?.name}</span>
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                  <div className="py-1">
                    {languages.map((language) => (
                      <Menu.Item key={language.code}>
                        {({ active }) => (
                          <a
                            onClick={() => setSelectedLanguage(language.code)}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm cursor-pointer'
                            )}
                          >
                            {language.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          {isStaffMember && (
            <>
              {isHumanConversation ? (
                <button
                  onClick={handleReturnToBot}
                  className="px-4 py-2 rounded bg-blue-500 text-white"
                >
                  Return to Bot
                </button>
              ) : (
                <button
                  onClick={handleStaffIntervention}
                  className="px-4 py-2 rounded bg-green-500 text-white"
                >
                  Intervene
                </button>
              )}
            </>
          )}
          <div className="text-sm font-medium">
            {isHumanConversation ? "Staff Conversation" : "Bot Conversation"}
          </div>
        </div>
        <div className='flex-grow bg-gray-100 overflow-y-auto' ref={chatContainerRef}>
          <div className='flex flex-col p-4'>
            {histories.map((history, index) => (
              <React.Fragment key={history.id}>
                <div 
                  id={`msg-${history.id}`}
                  className={`chat-history mb-5 ${
                    (isStaffMember && (history.role === 'human' || history.role === 'assistant')) || 
                    (!isStaffMember && (history.chatbot || history.role === 'assistant' || history.role === 'human')) 
                      ? 'self-start' 
                      : 'self-end'
                  } message-animate ${
                    highlightedMessageId === history.id ? 'message-highlight' : ''
                  }`}
                  data-message-id={history.id}
                >
                  <span 
                    className={`inline-block ${
                      (isStaffMember && (history.role === 'human' || history.role === 'assistant')) || 
                      (!isStaffMember && (history.chatbot || history.role === 'assistant' || history.role === 'human'))
                        ? 'bg-white text-black' 
                        : 'text-white'
                    }
                    ${history.type === 'followups' ? 'hidden' : null}
                    rounded-2xl p-2 px-4 shadow`} 
                    style={
                      (isStaffMember && (history.role === 'human' || history.role === 'assistant')) || 
                      (!isStaffMember && (history.chatbot || history.role === 'assistant' || history.role === 'human'))
                        ? null 
                        : { backgroundColor: chatInfo.chat_color || 'black' }
                    }
                  >
                    {
                      history.type === 'image'
                      ? <div className='flex w-80'>
                          <img src={history.data.url} className='w-20 h-20 rounded' />
                          <div className='pl-4'>
                            <ReactMarkdown className="prose max-w-none">
                              {history.data.html}
                            </ReactMarkdown>
                          </div>
                        </div>
                      : <>
                          {((isStaffMember && (history.role === 'human' || history.role === 'assistant')) || 
                            (!isStaffMember && (history.chatbot || history.role === 'assistant' || history.role === 'human'))) && (
                            history.type === 'context'
                            ? <span className="font-bold">Reference: </span>
                            : <span className="font-bold">
                                {history.role === 'human' ? 'Staff: ' : history.role === 'assistant' ? 'Chatbot: ' : 'Chatbot: '}
                              </span>
                          )}
                          <ReactMarkdown className={`prose max-w-none ${
                            (isStaffMember && (history.role === 'human' || history.role === 'assistant')) || 
                            (!isStaffMember && (history.chatbot || history.role === 'assistant' || history.role === 'human'))
                              ? '' 
                              : 'text-white'
                          }`} components={{
                            code({node, inline, className, children, ...props}) {
                              return (
                                <code className={`${className} ${inline ? 'bg-gray-200 rounded px-1' : ''}`} {...props}>
                                  {children}
                                </code>
                              )
                            }
                          }}>
                            {history.data.html}
                          </ReactMarkdown>
                          {/* {history.chatbot && !hideContext && history.data.context && renderContext(history.data.context, history.id)} */}
                          {history.role === 'assistant' && !hideContext && history.data.context && renderContext(
                            history.data.context,
                            history.id,
                            history.data.relevant_images,
                            expandedContexts,
                            toggleContext,
                            (item, index) => openContextModal(item, index)
                          )}
                          {history.role === 'assistant' && history.data.relevant_images && renderImageThumbnails(history.data.relevant_images)}
                          {history.role === 'assistant' && !viewMode && (
                          <span className="message-icons flex items-center space-x-2 mt-2">
                            <LikeButton 
                              messageId={history.id}
                              showFeedbackModal={showFeedbackModal}
                              isPreview={isPreview}
                            />
                            <DislikeButton 
                              messageId={history.id}
                              showFeedbackModal={showFeedbackModal}
                              isPreview={isPreview}
                            />
                            {renderAudioButton(history, history.id)}
                          </span>
                          )}
                        </>
                    }
                  </span>
                  {history.type === 'followups' && showFollowUps && renderFollowUpQuestions(history.data.follow_up_questions)}
                </div>
                {index === 0 && history.chatbot && faqItems.length > 0 && (
                  <FAQSection faqItems={faqItems} sendMessage={sendMessage} />
                )}
              </React.Fragment>
            ))}
            {isTyping && !isStaffMember && !isHumanConversation && <TypingIndicator />}
          </div>
        </div>
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="relative flex-grow">
            <input
              type="text"
              className="w-full h-10 border-none px-3 rounded-full bg-gray-100 focus:ring-2 focus:ring-blue-500"
              placeholder="Type a message..."
              value={inputText}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              disabled={isPreview || isLoading} 
            />
            {isLoading && (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
              </div>
            )}
          </div>
          <button
            className="w-10 h-10 rounded-full flex items-center justify-center transition-colors duration-300"
            style={{ backgroundColor: chatInfo.chat_color || 'black' }}
            onClick={isPreview ? undefined : (inputText.trim() ? handleSendMessage : handleAudioRecording)}
            disabled={isLoading || isPreview || !isHumanConversation} // Disable button when not in human conversation
          >
            {inputText.trim() ? (
              <PaperAirplaneIcon className="h-5 w-5 text-white" />
            ) : isRecording ? (
              <StopIcon className="h-5 w-5 text-white" />
            ) : (
              <MicrophoneIcon className="h-5 w-5 text-white" />
              )}
          </button>
        </div>
      </div>
      <div className='flex items-start justify-end flex-shrink-0 h-20 py-2 relative'>
        <span className='inline-block bg-white rounded-lg shadow-md text-xs text-gray-500 px-2 py-1 absolute left-1/2 -translate-x-1/2'>
          Powered by <span className='font-bold text-gray-900'>NCTech.com.mx</span>
        </span>

      </div>
      {selectedContext && (
        <ContextModal
          context={selectedContext}
          isOpen={isModalOpen}
          onClose={closeContextModal}
          chatColor={chatInfo.chat_color}
        />
      )}
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={lightboxSlides}
        index={lightboxIndex}
        plugins={[Zoom, Captions]}
      />
      <FeedbackSystem 
        chatId={chatId} 
        histories={histories}
        modalState={feedbackModalState}
        setModalState={setFeedbackModalState}
        feedbackText={feedbackText}
        setFeedbackText={setFeedbackText}
        additionalInfo={additionalInfo}
        setAdditionalInfo={setAdditionalInfo}
        submitFeedback={submitFeedback}
        chatColor={chatInfo.chat_color}
      />
    </div>
  );
};

export default Chat;