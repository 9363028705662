import React, { useState, useEffect  } from 'react';
import Card from 'components/Card';
import Dropzone from 'components/Dropzone';
import Tab from 'components/Tab';

const UploadData = () => {
  const tabs = [
    { id: 1, label: "Files" },
    { id: 2, label: "Text" },
    { id: 3, label: "Youtube Video" },
  ];
  const [selectedTab, setSelectedTab] = useState(1);
  // plain_text
  const [customText, setCustomText] = useState('');

  // video_url
  const [videoUrl, setVideoUrl] = useState('');
  const [videoDescription, setVideoDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCustomTextChange = (event) => {
    setCustomText(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    // Handle form submission here
    const formData = new FormData();
    formData.append('plain_text', customText);

    fetch('https://www.chatassistant.online/api/upload_data_new', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error: ' + response.status);
      })
      .then((data) => {
        // Handle successful response
        console.log(data);
      })
      .catch((error) => {
        // Handle error
        console.error('Error:', error);
      }).finally(() => {
        setIsLoading(false);
        setCustomText('')
      });
      
  };

  const handleVideoSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('youtube_link', videoUrl);
    // Add other necessary form fields if required
  
    fetch('https://www.chatassistant.online/api/upload_data_new', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Error: ' + response.status);
      })
      .then((data) => {
        // Handle successful response
        console.log(data);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error('Error:', error);
        setIsLoading(false);
      });
  };

  return (
    <div className='max-w-[600px] mx-auto flex flex-col items-center pt-10'>
      <div className='max-w-2xl flex flex-col items-center'>
        <p className='font-semibold text-indigo-600 mb-2'>
          Create custom Chatbot
        </p>
        <p className='text-center font-bold text-3xl text-gray-800 mb-4'>
          Start by adding Data
        </p>
        <p className='text-center text-gray-600 mb-5'>
          Add Data Source to your Chatbot: Files (PDF, Excel, Word, CSV), Websites, Images, Videos, Or Custom Text.
        </p>
      </div>
      <div className='w-full flex flex-col items-center pt-10 relative'>
        <div className='absolute top-0 left-1/2 -translate-x-1/2 block w-[1px] bg-gray-300 h-full'>
          <span className='w-2 h-2 bg-gray-300 rounded-full absolute top-0 left-1/2 -translate-x-1/2'></span>
        </div>
        <Card className="relative z-10 w-full mb-8 !pt-[14px]">
          <Tab
            tabs={tabs}
            active={selectedTab}
            onClick={(e) => setSelectedTab(e)}
          />
          <div className='w-full'>
            {selectedTab === 1 && (
              <Dropzone />
            )}
            {selectedTab === 2 && (
              <div className='flex flex-col items-center'>
                <p className="block text-sm font-medium leading-6 text-gray-700 mb-1">
                  Description will be showing here
                </p>
                <div className="w-full mb-3">
                  <textarea
                    placeholder='Add Custom Text here...'
                    className="h-24 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={customText}
                    onChange={handleCustomTextChange}
                  />
                </div>
                <button
                  type="button"
                  className="flex-shrink-0 inline-flex items-center rounded-md !bg-[#54B87E] px-3 py-2 text-sm leading-5 font-semibold text-white shadow"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="-ml-0.5 mr-1.5 w-5 h-5">
                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
                  </svg>
                  {isLoading ? 'Processing Text...' : 'Add Text to Chatbot'}
                </button>
              </div>
            )}
            {selectedTab === 3 && (
              <div className='flex flex-col items-center'>
                <p className="block text-sm font-medium leading-6 text-gray-700 mb-1">
                  Description will be showing here
                </p>
                <div className="w-full mb-3">
                  <input
                    type="text"
                    placeholder='Add Video URL here'
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                  />
                </div>
                <div className="w-full mb-3">
                  <textarea
                    placeholder='Add Video Description here'
                    className="h-24 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={videoDescription}
                    onChange={(e) => setVideoDescription(e.target.value)}
                  />
                </div>
                <button
                  type="button"
                  className="flex-shrink-0 inline-flex items-center rounded-md !bg-[#54B87E] px-3 py-2 text-sm leading-5 font-semibold text-white shadow"
                  onClick={handleVideoSubmit}
                  disabled={isLoading}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="-ml-0.5 mr-1.5 w-5 h-5"
                  >
                    <path
                      d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
                    ></path>
                  </svg>
                  {isLoading ? 'Processing Video...' : 'Add to Chatbot'}

                  
                </button>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default UploadData;