import React, { useState, useEffect, useRef } from 'react';
import Card from 'components/Card';
import { useParams } from 'react-router-dom';
import Chat from 'components/Drawer/Chat'; // Import the Chat component
import { HexColorPicker } from "react-colorful";
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

const Settings = ({ docIds }) => {
  const { chatId } = useParams();
  const [chatInfo, setChatInfo] = useState({
    name: '',
    description: '',
    initial_text: 'Hey, how can I help you? 👋',
    img: 'https://www.chatassistant.online/api/static/chat_imgs/chatbot_alt.png',
    chat_color: '#000000',
    faq_list: '',
    hide_context: false,
    hide_record_btn: false,
    summary: '',
    chat_status: '0',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const previewHistories = [
    {
      id: 0,
      type: 'image',
      data: {
        url: chatInfo.img || 'https://www.chatassistant.online/api/static/chat_imgs/chatbot_alt.png',
        html: chatInfo.initial_text,
      },
      chatbot: true,
    },
    {
      id: 1,
      type: 'text',
      data: { html: "Hello!" },
      chatbot: false,
    },
    {
      id: 2,
      type: 'text',
      data: { html: "Hello! How can I assist you today?" },
      chatbot: true,
    },
    {
      id: 3,
      type: 'text',
      data: { html: "Can you tell me about your services?" },
      chatbot: false,
    },
    {
      id: 4,
      type: 'text',
      data: { 
        html: "Certainly! Our company offers a wide range of services including web development, mobile app development, and cloud solutions. Would you like more information about any specific service?",
        context: [
          {
            data_type: 'text',
            full_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            highlight_start: "Sed do eiusmod",
            highlight_end: "ea commodo consequat",
          },
          {
            data_type: 'text',
            full_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            highlight_start: "ullamco laboris nisi",
            highlight_end: "culpa qui officia",
          }
        ]
      },
      chatbot: true,
    },
    {
      id: 5,
      type: 'text',
      data: { html: "Can you show me an example of your work?" },
      chatbot: false,
    },
    {
      id: 6,
      type: 'text',
      data: { 
        html: "Of course! Here's an example of a recent web application we developed:",
        relevant_images: [
          {
            image_path: "static/image_data/file-icon.png",
            description: "Web application dashboard"
          }
        ]
      },
      chatbot: true,
    },
  ];

  const parseFaqString = (faqString) => {
    const faqArray = faqString.split(",");

    return faqArray.map((faq, index) => ({
      id: index + 1,
      name: faq.trim(),
    }));
  };

  useEffect(() => {
    const fetchChatInfo = async () => {
      try {
        const response = await fetch(`https://www.chatassistant.online/api/chats/${chatId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch chat info');
        }
        const data = await response.json();
        setChatInfo(prevState => ({
          ...prevState,
          ...data,
          hide_context: data.hide_context === '1',
          hide_record_btn: data.hide_record_btn === '1',
        }));
      } catch (error) {
        console.error("Error fetching chat info:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChatInfo();
  }, [chatId]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  console.log(chatInfo);

  const alt_image = 'https://www.chatassistant.online/api/static/chat_imgs/chatbot_alt.png'
  const histories = [
    {
      id: 1,
      type: 'image',
      data: {
        url: chatInfo.img || alt_image,
        html: chatInfo.initial_text
      },
      chatbot: true
    },
    {
      id: 2,
      type: 'text',
      data: {
        html: 'What is your name?'
      },
      chatbot: false
    },
    {
      id: 3,
      type: 'text',
      data: {
        html: 'My Name is Peter Smith and I am an Astronaut at NASA of United States of America. Please flow me on <a href="#">twitter</a>'
      },
      chatbot: true
    },
  ]

  const handleSaveSettings = async () => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append('chat_id', chatId);
    formData.append('doc_ids', docIds);
    formData.append('chat_name', chatInfo.name);
    formData.append('chat_description', chatInfo.description);
    formData.append('faq_list', chatInfo.faq_list);
    formData.append('summary', chatInfo.summary);
    formData.append('chat_status', chatInfo.chat_status);
    formData.append('hide_context', chatInfo.hide_context);
    formData.append('hide_record_btn', chatInfo.hide_record_btn);
    formData.append('chat_color', chatInfo.chat_color);
    formData.append('initial_text', chatInfo.initial_text);

    if (chatInfo.edit_thumbnail) {
      formData.append('edit_thumbnail', chatInfo.edit_thumbnail);
    }

    try {
      const response = await fetch('https://www.chatassistant.online/api/edit_chat', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        // Handle successful response
        toast.success('Settings saved successfully', {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Handle error response
        toast.error('Failed to save settings', {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      // Handle fetch error
      console.log('Error saving settings:', error);
      toast.error('Error saving settings', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChatInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event) => {
    const input_name = event.target.name;
    const file = event.target.files[0]; // Access the selected file
    setChatInfo({ ...chatInfo, [input_name]: file });
  };

  return (
    <div className="flex flex-col space-y-4 pb-16 relative min-h-screen">
      <div className="grid grid-cols-2 gap-4">
        <Card className="w-full">
          <h2 className="text-xl font-bold mb-4">Chat Settings</h2>
          <div className="space-y-4">
            <div>
              <label htmlFor="chat-name" className="block text-sm font-medium text-gray-700">Chat Name</label>
              <input 
                type="text" 
                id="chat-name" 
                name="name" 
                value={chatInfo.name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
              <textarea 
                id="description" 
                name="description" 
                value={chatInfo.description}
                onChange={handleInputChange}
                rows="3"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="initial-text" className="block text-sm font-medium text-gray-700">Initial Text</label>
              <textarea 
                id="initial-text" 
                name="initial_text" 
                value={chatInfo.initial_text}
                onChange={handleInputChange}
                rows="3"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label htmlFor="faq-list" className="block text-sm font-medium text-gray-700">FAQ List</label>
              <textarea 
                id="faq-list" 
                name="faq_list" 
                value={chatInfo.faq_list}
                onChange={handleInputChange}
                rows="4"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        </Card>
        <Card className="w-full !p-0 overflow-hidden h-[600px]">
          <Chat 
            chatId={chatId} 
            isPreview={true}
            previewChatInfo={chatInfo}
            previewHistories={previewHistories}
          />
        </Card>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Card className="w-full">
          <h2 className="text-xl font-bold mb-4">Advanced Settings</h2>
          <div className="space-y-4">

            <div>
              <label htmlFor="summary" className="block text-sm font-medium text-gray-700">Summary</label>
              <textarea 
                id="summary" 
                name="summary" 
                value={chatInfo.summary}
                onChange={handleInputChange}
                rows="4"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
        </Card>
        <Card className="w-full">
          <h2 className="text-xl font-bold mb-4">Appearance</h2>
          <div className="space-y-4">
            <div>
              <label htmlFor="chat-color" className="block text-sm font-medium text-gray-700">Chat Color</label>
              <div className="mt-1 flex items-center space-x-2">
                <button
                  type="button"
                  onClick={() => setShowColorPicker(!showColorPicker)}
                  className="w-10 h-10 rounded-md border border-gray-300 shadow-sm"
                  style={{ backgroundColor: chatInfo.chat_color }}
                />
                <input 
                  type="text" 
                  id="chat-color" 
                  name="chat_color" 
                  value={chatInfo.chat_color}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              {showColorPicker && (
                <div ref={colorPickerRef} className="absolute z-10 mt-2">
                  <HexColorPicker 
                    color={chatInfo.chat_color} 
                    onChange={(color) => setChatInfo(prev => ({ ...prev, chat_color: color }))} 
                  />
                </div>
              )}
            </div>
            <div>
              <label htmlFor="edit-thumbnail" className="block text-sm font-medium text-gray-700">Thumbnail</label>
              <input
                type="file"
                id="edit-thumbnail"
                name="edit_thumbnail"
                onChange={handleFileChange}
                className="mt-1 block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-indigo-50 file:text-indigo-700
                  hover:file:bg-indigo-100"
              />
            </div>
          </div>
        </Card>
      </div>
      <Card className="w-full">
        <h2 className="text-xl font-bold mb-4">Chat Configuration</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="chat-status" className="block text-sm font-medium text-gray-700">Activate Chat</label>
            <select 
              id="chat-status" 
              name="chat_status" 
              value={chatInfo.chat_status}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
          <div>
            <label htmlFor="hide-context" className="block text-sm font-medium text-gray-700">Hide Context</label>
            <select 
              id="hide-context" 
              name="hide_context" 
              value={chatInfo.hide_context}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
          <div>
            <label htmlFor="hide-record-btn" className="block text-sm font-medium text-gray-700">Hide Record Button</label>
            <select 
              id="hide-record-btn" 
              name="hide_record_btn" 
              value={chatInfo.hide_record_btn}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>
      </Card>
      <div className="flex justify-end mt-6"> {/* Added mt-6 for some top margin */}
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={handleSaveSettings}
          disabled={isSaving}
        >
          {isSaving ? (
            <>
              <ArrowPathIcon className="animate-spin -ml-1 mr-2 h-5 w-5" />
              Saving Changes...
            </>
          ) : (
            'Save Settings'
          )}
        </button>
      </div>
    </div>
  );
}

export default Settings;