import React, { Fragment, useState } from 'react';

const DataTable = ({ cols, rows, renderRow, handleSelectedItems }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelection = (itemId, isSelected) => {
    if (isSelected) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    } else {
      setSelectedItems((prevSelectedItems) => prevSelectedItems.filter((id) => id !== itemId));
    }
  };

  // Ensure rows is an array
  const safeRows = Array.isArray(rows) ? rows : [];

  return (
    <table className='w-full'>
      <thead>
        <tr>
          <th className='w-0'>
            <input type="checkbox" className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
          </th>
          {cols.map((col, i) => {
            const align = col.align || 'left';
            return (
              <th
                key={i}
                className={`font-semibold text-sm text-gray-900 px-1 py-3.5 ${align === 'left' ? 'text-left' : align === 'right' ? 'text-right' : 'text-center'}`}
              >
                {col.label}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {safeRows.length > 0 ? (
          safeRows.map((row, i) => (
            <Fragment key={i}>
              {renderRow(row, handleSelectedItems || handleItemSelection)}
            </Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={cols.length + 1} className="text-center py-4">
              No data available
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DataTable;