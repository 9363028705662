import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateChatbot from 'pages/UploadData';
import Home from 'pages/Home';
import Chatbots from 'pages/Chatbots';
import Chatbot from 'pages/Chatbot';
import ChatbotSettings from 'pages/Chatbot/Settings';
import ChatbotDataSources from 'pages/Chatbot/ChatbotDataSources';
import Threads from 'pages/Chatbot/Threads';
import ChatbotShare from 'pages/Chatbot/Share';
import DataSource from 'pages/DataSourceManager';
import Pricing from 'pages/Pricing';
import Search from 'pages/Search';
import Layout from 'components/Layout';
import 'assets/components.css';
import Chat from 'components/Drawer/Chat';
import Feedbacks from 'pages/Chatbot/Feedbacks';
import Statistics from 'pages/Chatbot/Statistics';
import Visitors from 'pages/Chatbot/Visitors';
import Login from 'pages/Login';
import UserManagement from 'pages/UserManagement';
import PrivateRoute from './components/PrivateRoute';
import HumanInterventionRequests from 'pages/HumanInterventionRequests';
function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();
  const isChatPath = location.pathname.startsWith('/c/');
  const isLoginPath = location.pathname === '/login';

  if (isLoginPath) {
    return <Login />;
  }
  /** when editing this code, please make sure not to remove any route
  "c/:chatId" and "chat/:chatId" are different paths one with layout and one without**/
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        {isChatPath ? (
          <Route path="c/:chatId" element={<Chat docIds={[]} />} />
        ) : (
          <Route element={<LayoutWrapper />}>
            <Route path="/" element={<Home />} />
            <Route path="/chat/:chatId" element={<Chat docIds={[]} />} />
            <Route element={<PrivateRoute requiredRole="admin" />}>
              <Route path="/create" element={<CreateChatbot />} />
              <Route path="/chatbots" element={<Chatbots />} />
              <Route path="/chatbot/:chatId" element={<Chatbot />}>
                <Route path="settings" element={<ChatbotSettings />} />
                <Route path="data-sources" element={<ChatbotDataSources />} />
                <Route path="share" element={<ChatbotShare />} />
                <Route path="threads" element={<Threads />} />
                <Route path="feedbacks" element={<Feedbacks />} />
                <Route path="statistics" element={<Statistics />} />
                <Route path="visitors" element={<Visitors />} />
              </Route>
              <Route path="/user-management" element={<UserManagement />} />
            </Route>
            <Route element={<PrivateRoute requiredRole="staff" />}>
              <Route path="/data-source" element={<DataSource />} />
            </Route>
            <Route element={<PrivateRoute requiredRole="staff" />}>
              <Route path="/human-intervention-requests" element={<HumanInterventionRequests />} />
            </Route>            
          </Route>
        )}
      </Routes>
      <ToastContainer 
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

// This wrapper component applies the Layout to all routes except login and chat
function LayoutWrapper() {
  const location = useLocation();
  const isChatPath = location.pathname.startsWith('/c/');

  return isChatPath ? <Outlet /> : <Layout><Outlet /></Layout>;
}
export default App;
