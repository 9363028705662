import { useState, useCallback, useRef } from 'react';

export const useTextToSpeech = () => {
  const [audioCache, setAudioCache] = useState({});
  const [loadingAudio, setLoadingAudio] = useState({});
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const playAudioFromAPI = useCallback(async (message, language_code, audioId) => {
    // Check if the audio is already in the cache
    if (audioCache[audioId]) {
      playAudio(`audio-${audioId}`, audioId);
      return;
    }

    setLoadingAudio((prevLoading) => ({
      ...prevLoading,
      [audioId]: true,
    }));

    try {
      const response = await fetch('https://www.chatassistant.online/api/generate_audio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: message,
          language_code: language_code,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate audio');
      }

      const audioData = await response.json();
      const audioUrl = `data:audio/mpeg;base64,${audioData.data.audio}`;

      // Create an audio element and set its properties
      const audioElement = document.createElement('audio');
      audioElement.src = audioUrl;
      audioElement.id = `audio-${audioId}`;
      audioElement.hidden = true;

      audioElement.addEventListener('ended', () => {
        setCurrentlyPlayingAudio(null);
        setIsAudioPlaying(false);
      });

      const parentContainer = document.getElementById(`msg-${audioId}`);
      if (parentContainer) {
        parentContainer.dataset.audioId = `audio-${audioId}`;
        parentContainer.appendChild(audioElement);
        console.log(`Appended audio element:`, audioElement);

        // Save the audio element in the cache
        setAudioCache((prevCache) => ({
          ...prevCache,
          [audioId]: audioElement,
        }));

        setLoadingAudio((prevLoading) => ({
          ...prevLoading,
          [audioId]: false,
        }));

        playAudio(`audio-${audioId}`, audioId);
      } else {
        console.error(`Parent container for message ID msg-${audioId} not found`);
        setLoadingAudio((prevLoading) => ({
          ...prevLoading,
          [audioId]: false,
        }));
      }
    } catch (error) {
      console.error('Error playing audio:', error);
      setLoadingAudio((prevLoading) => ({
        ...prevLoading,
        [audioId]: false,
      }));
    }
  }, [audioCache]);

  const playAudio = useCallback((audioId, id) => {
    const audioElement = document.getElementById(audioId);

    if (audioElement) {
      if (currentlyPlayingAudio && currentlyPlayingAudio !== audioId) {
        pauseAudio(currentlyPlayingAudio);
      }
      setCurrentlyPlayingAudio(audioId);
      setIsAudioPlaying(true);
      audioElement.play();
      console.log(`Playing audio with ID ${audioId}`);
    } else {
      console.error(`Audio element with ID ${audioId} not found`);
    }
  }, [currentlyPlayingAudio]);

  const pauseAudio = useCallback((audioId) => {
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      audioElement.pause();
      setIsAudioPlaying(false);
      console.log(`Paused audio with ID ${audioId}`);
    } else {
      console.error(`Audio element with ID ${audioId} not found`);
    }
  }, []);

  const toggleAudio = useCallback((audioId, message, language_code) => {
    if (currentlyPlayingAudio === `audio-${audioId}` && isAudioPlaying) {
      pauseAudio(`audio-${audioId}`);
    } else if (currentlyPlayingAudio === `audio-${audioId}` && !isAudioPlaying) {
      playAudio(`audio-${audioId}`, audioId);
    } else {
      playAudioFromAPI(message, language_code, audioId);
    }
  }, [currentlyPlayingAudio, isAudioPlaying, pauseAudio, playAudio, playAudioFromAPI]);

  const getAudioIcon = useCallback((audioId) => {
    if (loadingAudio[audioId]) {
      return 'loading';
    }
    if (currentlyPlayingAudio === `audio-${audioId}`) {
      return isAudioPlaying ? 'pause' : 'play';
    }
    return 'speaker';
  }, [loadingAudio, currentlyPlayingAudio, isAudioPlaying]);

  return {
    playAudioFromAPI,
    playAudio,
    pauseAudio,
    toggleAudio,
    getAudioIcon,
    isAudioPlaying,
    loadingAudio,
  };
};

