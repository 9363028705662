import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { ArrowTopRightOnSquareIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const renderContext = (context, messageId, relevant_images, expandedContexts, toggleContext, openContextModal) => {
  if (!context || context.length === 0) {
    if (!relevant_images || relevant_images.length === 0) {
      return null;
    }
    // If there are only images and no context, don't render the context dropdown
    return null;
  }

  const isExpanded = expandedContexts[messageId];

  return (
    <div className="mt-2">
      <button
        onClick={() => toggleContext(messageId)}
        className="flex items-center text-sm text-blue-500 hover:text-blue-700 transition-colors duration-200"
      >
        {isExpanded ? (
          <>
            <ChevronUpIcon className="h-4 w-4 mr-1" />
            Hide Context
          </>
        ) : (
          <>
            <ChevronDownIcon className="h-4 w-4 mr-1" />
            Show Context ({context.length})
          </>
        )}
      </button>

      {isExpanded && (
        <div className="mt-2 space-y-2">
          {context.map((item, index) => (
            <div key={index} className="bg-gray-100 rounded-lg p-2 text-sm">
              <span className="font-semibold text-gray-700">Source {index + 1}:</span>{' '}
              <button
                onClick={() => openContextModal(item, index)}
                className="text-blue-500 hover:text-blue-700 transition-colors duration-200"
              >
                View Details
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const ContextModal = ({ context, isOpen, onClose, chatColor }) => {
  const videoRef = useRef(null);
  const [processedText, setProcessedText] = useState('');

  useEffect(() => {
    if (isOpen && context && context.item.data_type === 'YT' && videoRef.current) {
      videoRef.current.currentTime = context.item.start_time;
    }
    if (context && context.item.full_text) {
      const { full_text, highlight_start, highlight_end } = context.item;
      if (highlight_start && highlight_end) {
        const startIndex = full_text.indexOf(highlight_start);
        const endIndex = full_text.indexOf(highlight_end) + highlight_end.length;

        if (startIndex !== -1 && endIndex !== -1) {
          const before = full_text.slice(0, startIndex);
          const highlighted = full_text.slice(startIndex, endIndex);
          const after = full_text.slice(endIndex);

          setProcessedText(`${before}<mark class="bg-yellow-200 px-1 rounded">${highlighted}</mark>${after}`);
        } else {
          setProcessedText(full_text);
        }
      } else {
        setProcessedText(full_text);
      }
    }
  }, [isOpen, context]);

  if (!context) return null;

  const { item, index } = context;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Context Source {index + 1}
                  </Dialog.Title>
                  <div className="mt-4">
                    {item.data_type === 'YT' ? (
                      <div className="space-y-4">
                        <div className="aspect-w-16 aspect-h-9 max-w-md mx-auto">
                          <video
                            ref={videoRef}
                            className="w-full h-full rounded-lg object-contain max-h-[60vh]"
                            controls
                            preload="metadata"
                            src={`https://www.chatassistant.online/api/${context.item.file_path}`}
                            onLoadedMetadata={(e) => {
                              e.target.currentTime = item.start_time;
                            }}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                        <div className="flex justify-between text-sm text-gray-500">
                          <p>Start: {formatTime(item.start_time)}</p>
                          <p>End: {formatTime(item.end_time)}</p>
                        </div>
                        <ReactMarkdown 
                          className="prose max-w-none text-gray-700 leading-relaxed"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {processedText}
                        </ReactMarkdown>
                      </div>
                    ) : item.data_type === 'image' ? (
                      <div className="space-y-4">
                        <img 
                          src={`https://www.chatassistant.online/api/static/${item.img_path}`} 
                          alt="Context Image" 
                          className="w-full h-auto rounded-lg max-h-[60vh] object-contain mx-auto"
                        />
                        <ReactMarkdown 
                          className="prose max-w-none text-gray-700 leading-relaxed"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {processedText}
                        </ReactMarkdown>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <ReactMarkdown 
                          className="prose max-w-none text-gray-700 leading-relaxed"
                          rehypePlugins={[rehypeRaw]}
                        >
                          {processedText}
                        </ReactMarkdown>
                        {item.file_path && (
                          <div className="flex items-center space-x-2 text-sm text-gray-500">
                            <span>Source:</span>
                            <a 
                              href={`https://www.chatassistant.online/api/${item.file_path}`} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              className="text-blue-500 hover:text-blue-700 flex items-center"
                            >
                              {item.file_name}
                              <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                            </a>
                            <button
                              onClick={() => window.open(`https://www.chatassistant.online/api/${item.file_path}`, '_blank')}
                              className="text-blue-500 hover:text-blue-700 flex items-center"
                            >
                              <ArrowDownTrayIcon className="h-4 w-4" />
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50 sm:ml-3 sm:w-auto sm:text-sm transition-opacity duration-200"
                  style={{ backgroundColor: chatColor || '#3B82F6' }}
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const useContextHandling = () => {
  const [expandedContexts, setExpandedContexts] = useState({});
  const [selectedContext, setSelectedContext] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleContext = (messageId) => {
    setExpandedContexts(prev => ({
      ...prev,
      [messageId]: !prev[messageId]
    }));
  };

  const openContextModal = (context, index) => {
    setSelectedContext({ item: context, index });
    setIsModalOpen(true);
  };

  const closeContextModal = () => {
    setSelectedContext(null);
    setIsModalOpen(false);
  };

  return {
    expandedContexts,
    selectedContext,
    isModalOpen,
    toggleContext,
    openContextModal,
    closeContextModal,
  };
};