import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import CardImage from 'assets/card.png';
import ClockIcon from 'assets/icons/clock.svg';
import { ChatBubbleBottomCenterTextIcon, ShareIcon } from '@heroicons/react/24/outline';
import DeleteModal from './DeleteModal';

const ChatbotCard = ({ name, date, status, onOpenChatbot, id, n_chats_started, n_messages_asked, number_of_streams,thumbnail }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white p-4 px-6 sm:px-4 shadow rounded-xl">
      {/* <img src={CardImage} className='rounded-xl mb-3' /> */}
      <img src={thumbnail !== '' ? thumbnail : CardImage} className="rounded-xl mb-3"  />

      <div className="flex justify-between mb-3">
        {/* <span className={`${status === `Active` ? `border-green-200 bg-green-50 text-green-700` : `border-amber-200 bg-amber-50 text-amber-500`} border inline-flex items-center rounded-md px-2 py-1 text-xs leading-4 font-medium`}>
          {status}
        </span> */}
        <span className={`${status === 1 ? 'border-green-200 bg-green-50 text-green-700' : 'border-amber-200 bg-amber-50 text-amber-500'} border inline-flex items-center rounded-md px-2 py-1 text-xs leading-4 font-medium`}>
          {status === 1 ? 'Active' : 'Inactive'}
        </span>


        <div className='flex items-center'>
          <div className="flex flex-shrink-0 self-center ml-3">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      <Link to={`/chatbot/${id}/settings`} className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50">
                        Edit Chatbot
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        // onClick={() => setOpen(true)}
                        onClick={() => {
                          setOpen(true);
                        }}
                        className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                      >
                        Delete Chatbot
                      </a>
                    </Menu.Item>
                    {/* <Menu.Item>
                      <Link to="/search" className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50">
                        View Desktop Chatbot
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/bot" className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50">
                        View Mobile Chatbot
                      </Link>
                    </Menu.Item> */}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <p className="text-xl leading-5 font-semibold text-gray-900 mb-2">
          <a onClick={() => navigate(`/chatbot/${id}/settings`)} className="cursor-pointer hover:underline">
            {name}
          </a>
        </p>
      </div>
      <div className='text-xs text-gray-500'>
        {number_of_streams} Data Sets added
      </div>
      <div className='border-t border-b my-3 py-3 text-xs'>
        <div className='flex items-center justify-between'>
          <span className='font-bold'>Messages send</span>
          <span className='text-gray-500'>{n_messages_asked}</span>
        </div>
        {/* <div className='w-full h-1.5 rounded-full bg-gray-100 overflow-hidden my-3'>
          <div className='w-3/4 h-full bg-indigo-600 rounded-full'></div>
        </div> */}
        <div className='w-full h-1.5 rounded-full bg-gray-100 overflow-hidden my-3'>
          <div
            className='h-full bg-indigo-600 rounded-full'
            style={{ width: `${(n_messages_asked / 100) * 100}%` }}
          ></div>
        </div>
        <div className='flex items-center justify-between'>
          <span className='font-bold'>Chats started</span>
          <span className='text-gray-500'>{n_chats_started}</span>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-3'>
        <button
          type="button"
          onClick={onOpenChatbot}
          className="w-full rounded-md bg-white px-6 py-3 text-sm leading-5 font-semibold text-gray-900 shadow flex items-center justify-center"
        >
          <ChatBubbleBottomCenterTextIcon className="w-5 h-5 mr-2" />
          Open Chatbot
        </button>
        <button onClick={() => navigate(`/chatbot/${id}/share`)} type="button" className="w-full rounded-md bg-white px-6 py-3 text-sm leading-5 font-semibold text-gray-900 shadow flex items-center justify-center">
          <ShareIcon className="w-5 h-5 mr-2" />
          Embed/Share
        </button>
      </div>
      <p className="flex items-center text-xs mt-1 text-gray-500 mt-3">
        <img src={ClockIcon} className='w-3 opacity-60 mr-1' />
        Created on {date}
      </p>
      <DeleteModal open={open} setOpen={setOpen} chatId={id} itemType={'chat'}/>
    </div>
  )
}

export default ChatbotCard;