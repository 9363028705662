import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Card from 'components/Card';
import DataTable from 'components/DataTable';
import DataSourceDrawer from 'components/Drawer/DataSourceDetails';
import CreateChatbotDrawer from 'components/Drawer/UploadDataDrawer';
import Modal from 'components/Modal'; // Import the new Modal component
import { useProgressAnimation } from 'components/Hooks/useProgressAnimation';

const DataSourceManager = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCreateChatbot, setOpenCreateChatbot] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [totalCharacters, setTotalCharacters] = useState('');
  const [processingCount, setProcessingCount] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const rowsPerPage = 10;

  const cols = [
    { label: 'Data Source' },
    { label: 'Data Type', align: 'center' },
    { label: 'File Size', align: 'center' },
    { label: 'Characters', align: 'center' },
    { label: 'Assignments', align: 'center' },
    { label: 'Status', align: 'center' },
    { label: 'Actions', align: 'right' },
  ];

  const [rows, setRows] = useState([]);
  useEffect(() => {
    setInitialLoading(true);
    fetchInitialChatData();
  }, []);
  const animatedProgressRef = useProgressAnimation(rows);

  useEffect(() => {
    const results = rows.filter(row =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRows(results);
    setCurrentPage(1);
  }, [searchTerm, rows]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const fetchInitialChatData = async () => {
    try {
      await fetchChatData();
    } finally {
      setInitialLoading(false);
    }
  };

  const formatNumber = (number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    } else if (number >= 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    } else {
      return number.toString();
    }
  };

  
  const fetchChatData = async () => {
    try {
      const response = await fetch('https://www.chatassistant.online/api/data_sources');
      const data = await response.json();
      
      setRows(prevRows => {
        const updatedRows = [...prevRows];
        const newRows = [];
        data.docs.forEach(newRow => {
          const index = updatedRows.findIndex(row => row.id === newRow.id);
          if (index !== -1) {
            updatedRows[index] = newRow;
          } else {
            newRows.push(newRow);
          }
        });
        return [...updatedRows, ...newRows];
      });

      setProcessingCount(data.processing_count);
      const totalCharacters = data.docs.reduce((total, row) => total + row.n_characters, 0);
      setTotalCharacters(formatNumber(totalCharacters));

      if (data.processing_count > 0) {
        setTimeout(fetchChatData, 5000);
      }
    } catch (error) {
      console.log('Error fetching chat data:', error);
    }
  };

  const handleDelete = (docId) => {
    setDeleteId(docId);
    setOpenDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`https://www.chatassistant.online/api/delete_doc/${deleteId}`, {
        method: 'GET',
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setOpenDeleteModal(false);
        // Remove the deleted item from the rows state
        setRows(prevRows => prevRows.filter(row => row.id !== deleteId));
        // Update filteredRows as well
        setFilteredRows(prevFiltered => prevFiltered.filter(row => row.id !== deleteId));
        // Recalculate total characters
        const newTotalCharacters = rows.reduce((total, row) => {
          if (row.id !== deleteId) {
            return total + row.n_characters;
          }
          return total;
        }, 0);
        setTotalCharacters(formatNumber(newTotalCharacters));
        // Optionally, fetch fresh data
        fetchChatData();
      } else {
        throw new Error('Delete request failed');
      }
    } catch (error) {
      console.error('Error deleting data source:', error);
    }
  };

  const handleEditDataSource = (rowId) => {
    setOpen(true);
    setSelectedRowId(rowId); // Set the selected row ID
  };

  const handleSelectAll = () => {
    if (selectedDocs.length === filteredRows.length) {
      setSelectedDocs([]);
    } else {
      setSelectedDocs(filteredRows.map(row => row.id));
    }
  };

  const handleSelectDoc = (docId) => {
    setSelectedDocs(prev => 
      prev.includes(docId) ? prev.filter(id => id !== docId) : [...prev, docId]
    );
  };

  const handleMassDelete = () => {
    setOpenDeleteModal(true);
  };

  const confirmMassDelete = async () => {
    try {
      const response = await fetch('https://www.chatassistant.online/api/mass_delete_docs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ doc_ids: selectedDocs }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setOpenDeleteModal(false);
        // Remove the deleted items from the rows state
        setRows(prevRows => prevRows.filter(row => !selectedDocs.includes(row.id)));
        // Update filteredRows as well
        setFilteredRows(prevFiltered => prevFiltered.filter(row => !selectedDocs.includes(row.id)));
        // Recalculate total characters
        const newTotalCharacters = rows.reduce((total, row) => {
          if (!selectedDocs.includes(row.id)) {
            return total + row.n_characters;
          }
          return total;
        }, 0);
        setTotalCharacters(formatNumber(newTotalCharacters));
        // Clear selected docs
        setSelectedDocs([]);
        // Optionally, fetch fresh data
        fetchChatData();
      } else {
        throw new Error('Mass delete request failed');
      }
    } catch (error) {
      console.error('Error deleting data sources:', error);
    }
  };

  const renderRow = (row) => {

    return (
      <tr className='border-b border-gray-100'>
        <td className='w-0 px-1 py-5'>
          <input
            type='checkbox'
            checked={selectedDocs.includes(row.id)}
            onChange={() => handleSelectDoc(row.id)}
            className='w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
          />
        </td>
        <td className='px-3 py-5'>
          <div className='flex items-center'>
            <img
              className='w-11 h-11 flex-none rounded-full bg-gray-50'
              src='/images/file-icon.png'
              alt={row.name}
            />
            <div className='min-w-0 ml-4'>
              <span
                onClick={() => setOpen(true)}
                className='font-semibold text-sm leading-5 text-gray-900 cursor-pointer'
              >
                {row.name}
              </span>
              <div className='mt-1 flex items-center gap-2 text-sm leading-5 text-gray-500'>
                <p className='whitespace-nowrap'>Added on {row.date}</p>
              </div>
            </div>
          </div>
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          <span className='border-green-200 bg-green-50 text-green-700 border inline-flex items-center rounded-md px-2 py-1 text-xs leading-4 font-medium'>
            {row.data_type}
          </span>
        </td>
        {/* <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          {(row.file_size/ 1024).toFixed(2)}KB
        </td> */}
        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          {row.file_size && row.file_size > 0 ? (
            row.file_size < 1024 ? (
              `${row.file_size} bytes` // Display in bytes if file size is less than 1 KB
            ) : row.file_size < 1024 * 1024 ? (
              `${(row.file_size / 1024).toFixed(2)} KB` // Display in KB if file size is less than 1 MB
            ) : (
              `${(row.file_size / (1024 * 1024)).toFixed(2)} MB` // Display in MB if file size is 1 MB or greater
            )
          ) : (
            "-" // Display dash if file size is 0 or null
          )}
        </td>



        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          {formatNumber(row.n_characters)}
        </td>
        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          {row.assignments} Chatbots
        </td>

        <td className='text-sm text-gray-500 px-1 py-5 text-center'>
          {row.status === 'processing' ? (
            <div className='flex items-center justify-center'>
              <div className='w-full bg-gray-200 rounded-full h-2.5 mr-2'>
                <div 
                  className='bg-yellow-400 h-2.5 rounded-full' 
                  style={{width: `${animatedProgressRef.current[row.id] || 0}%`}}
                ></div>
              </div>
              <span className='text-yellow-600 mr-2'>
                {Math.round(animatedProgressRef.current[row.id] || 0)}%
              </span>
              <span className='inline-flex items-center'>
                <svg
                  className="animate-spin h-4 w-4 text-yellow-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8z"
                  ></path>
                </svg>
              </span>
            </div>
          ) : row.status === 'failed' ? (
            <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
              Failed
            </span>
          ) : (
            <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
              Completed
            </span>
          )}
        </td>

        <td className='text-sm text-gray-500 px-1 py-5'>
          <div className='flex justify-end relative' data-headlessui-state='open'>
            <Menu as='div' className='relative'>
              <Menu.Button className='block text-gray-500'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                  aria-hidden='true'
                  className='w-5 h-5'
                >
                  <path d='M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z'></path>
                </svg>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-95'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute mt-2 right-0 z-10 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg border border-gray-300'>
                  <Menu.Item>
                  <a
                    onClick={() => handleEditDataSource(row.id)} // Pass row.id to handleEditDataSource
                    className='cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50'
                  >
                    Edit Data Source
                  </a>
                  </Menu.Item>
                  <Menu.Item>
                    <a
                      onClick={() => handleDelete(row.id)} // Pass docId to handleDelete
                      className='cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50'
                    >
                      Delete Data Source
                    </a>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </td>
      </tr>
    );
  };

  if (initialLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className='flex flex-col lg:flex-row justify-between items-center mb-4'>
        <h3 className='text-2xl leading-5 font-semibold text-gray-900 text-center lg:text-left mb-4 lg:mb-0'>
          Data Sets
        </h3>
        <div className='w-full lg:w-auto flex flex-col lg:flex-row'>
          <input
            type="text"
            placeholder='Search...'
            className="lg:mb-0 w-full lg:w-64 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-3"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            type='button'
            onClick={() => setOpenCreateChatbot(true)}
            className='w-full lg:w-auto lg:w-autoflex-shrink-0 inline-flex justify-center items-center rounded-md bg-indigo-600 px-3 py-2 text-sm leading-5 font-semibold text-white lg:ml-3'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
              className='-ml-0.5 mr-1.5 w-5 h-5'
            >
              <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z'></path>
            </svg>
            Add Data Sets
          </button>
          {selectedDocs.length > 0 && (
            <>
              <button
                onClick={handleSelectAll}
                className='w-full lg:w-auto flex-shrink-0 inline-flex justify-center items-center rounded-md bg-gray-600 px-3 py-2 text-sm leading-5 font-semibold text-white lg:ml-3'
              >
                {selectedDocs.length === filteredRows.length ? 'Deselect All' : 'Select All'}
              </button>
              <button
                onClick={handleMassDelete}
                className='w-full lg:w-auto flex-shrink-0 inline-flex justify-center items-center rounded-md bg-red-600 px-3 py-2 text-sm leading-5 font-semibold text-white lg:ml-3'
              >
                Delete Selected
              </button>
            </>
          )}
        </div>
      </div>
      <Card>
        <div className='overflow-x-auto'>
          <div className='min-w-[720px] mb-5'>
            <DataTable cols={cols} rows={currentRows} renderRow={renderRow} />
          </div>
          <p className='font-medium text-sm text-gray-700 text-center'>
            Data Sets: {filteredRows.length}, Characters: {totalCharacters}
            {processingCount > 0 && (
              <span className='ml-2 text-yellow-600'>
                ({processingCount} processing)
              </span>
            )}
          </p>
        </div>
        
        {/* Pagination */}
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastRow >= filteredRows.length}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{indexOfFirstRow + 1}</span> to <span className="font-medium">{Math.min(indexOfLastRow, filteredRows.length)}</span> of <span className="font-medium">{filteredRows.length}</span> results
              </p>
            </div>
            <div>
              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                  </svg>
                </button>
                {Array.from({ length: Math.ceil(filteredRows.length / rowsPerPage) }, (_, i) => i + 1).map(number => (
                  <button
                    key={number}
                    onClick={() => paginate(number)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === number ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}`}
                  >
                    {number}
                  </button>
                ))}
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={indexOfLastRow >= filteredRows.length}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </Card>
      <DataSourceDrawer open={open} setOpen={setOpen} selectedRowId={selectedRowId} />
      <CreateChatbotDrawer open={openCreateChatbot} setOpen={setOpenCreateChatbot} />
      <Modal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={selectedDocs.length > 1 ? confirmMassDelete : confirmDelete}
        title={selectedDocs.length > 1 ? "Delete Selected Data Sources" : "Delete Data Source"}
        message={selectedDocs.length > 1 
          ? `Are you sure you want to delete ${selectedDocs.length} selected data sources? This action cannot be undone.`
          : "Are you sure you want to delete this data source? This action cannot be undone."}
        confirmText="Delete"
        cancelText="Cancel"
        type="delete"
      />
    </Fragment>
  );
};

export default DataSourceManager;