import React, { useState } from 'react';

const Search = () => {
  const [key, setKey] = useState("");

  return <div className="overflow-y-auto lg:p-20">
    <div className="mx-auto max-w-2xl rounded-xl bg-white shadow" id="headlessui-dialog-panel-2" data-headlessui-state="open">
      <div className="relative">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="pointer-events-none absolute left-4 top-3.5 w-5 h-5 text-gray-400">
          <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd"></path>
        </svg>
        <input className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 text-sm" placeholder="Search..." id="headlessui-combobox-input-3" role="combobox" type="text" aria-expanded="false" aria-autocomplete="list" data-headlessui-state="" value={key} onChange={e => setKey(e.target.value)} aria-controls="headlessui-combobox-options-4" />
      </div>
      <ul className="max-h-80 overflow-y-auto" role="listbox" id="headlessui-combobox-options-4" data-headlessui-state="">
        <li className="p-2 border-t border-t-gray-100" role="none">
          <h2 className="mb-2 mt-4 px-3 font-semibold text-xs text-gray-500" role="none">Recent searches</h2>
          <ul className="text-sm leading-5 text-gray-700" role="none">
            <li className="flex cursor-default select-none items-center rounded-md px-3 py-2" id="headlessui-combobox-option-5" role="option" tabIndex="-1" aria-selected="false" data-headlessui-state="">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6 flex-none text-gray-400">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"></path>
              </svg>
              <span className="grow ml-3 truncate">Workflow Inc. / Website Redesign</span>
            </li>
          </ul>
        </li>
        <li className="p-2 border-t border-t-gray-100" role="none">
          <h2 className="mb-2 mt-4 px-3 font-semibold text-xs text-gray-500" role="none">Quick actions</h2>
          <ul className="text-sm leading-5 text-gray-700" role="none">
            <li className="flex cursor-default select-none items-center rounded-md px-3 py-2" id="headlessui-combobox-option-5" role="option" tabIndex="-1" aria-selected="false" data-headlessui-state="">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6 flex-none text-gray-400">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>
              </svg>
              <span className="grow ml-3 truncate">Add new file...</span>
              <span className="ml-3 flex-none text-xs leading-4 font0semibold text-gray-400">
                <kbd>⌘</kbd>
                <kbd>N</kbd>
              </span>
            </li>
            <li className="flex cursor-default select-none items-center rounded-md px-3 py-2" id="headlessui-combobox-option-5" role="option" tabIndex="-1" aria-selected="false" data-headlessui-state="">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6 flex-none text-gray-400">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"></path>
              </svg>
              <span className="grow ml-3 truncate">Add new folder...</span>
              <span className="ml-3 flex-none text-xs leading-4 font0semibold text-gray-400">
                <kbd>⌘</kbd>
                <kbd>F</kbd>
              </span>
            </li>
            <li className="flex cursor-default select-none items-center rounded-md px-3 py-2" id="headlessui-combobox-option-5" role="option" tabIndex="-1" aria-selected="false" data-headlessui-state="">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6 flex-none text-gray-400">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5"></path>
              </svg>
              <span className="grow ml-3 truncate">Add hashtag...</span>
              <span className="ml-3 flex-none text-xs leading-4 font0semibold text-gray-400">
                <kbd>⌘</kbd>
                <kbd>H</kbd>
              </span>
            </li>
            <li className="flex cursor-default select-none items-center rounded-md px-3 py-2" id="headlessui-combobox-option-5" role="option" tabIndex="-1" aria-selected="false" data-headlessui-state="">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-6 h-6 flex-none text-gray-400">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"></path>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z"></path>
              </svg>
              <span className="grow ml-3 truncate">Add label...</span>
              <span className="ml-3 flex-none text-xs leading-4 font0semibold text-gray-400">
                <kbd>⌘</kbd>
                <kbd>L</kbd>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
}

export default Search;